//Librerias
import React from 'react'
import {Route, useHistory} from 'react-router-dom'

//Componentes
import {ProfitsUIProvider} from './ProfitsUIContext'
import {ProfitsCard} from './ProfitsCard'

export const ProfitsPage = () => {
  const profitsUIEvents = {}

  return (
    <ProfitsUIProvider profitsUIEvents={profitsUIEvents}>
      <ProfitsCard />
    </ProfitsUIProvider>
  )
}
