import React, {createContext, useContext, useState, useCallback, useEffect} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter, initialValues} from "./LoansUIHelpers";
import axios from "axios";
import { LoanModel } from "./models";

interface dataDataResultTableLoans {
  entities: any[],
  totalCount: number,
  errorMessage: string,
}
export interface resultTableLoans {
  data: dataDataResultTableLoans
  totalCount: number,
}
export interface LoansUIContextModel {
  queryParams:any,
  setQueryParamsBase: (queryParams:any) => void,
  ids: number[],
  setIds: (ids: number[]) => void,
  setQueryParams: (queryParams:any) => void,
  initLoan:Partial<LoanModel>,
  newLoanButtonClick: any,
  openEditLoanDialog: any,
  openDeleteLoanDialog: any,
  openDeleteLoansDialog: any,
  openFetchLoansDialog: any,
  openUpdateLoansStatusDialog: any,
  valuesTable: resultTableLoans,
  tableLoading: boolean,
  // setTableLoading: (tableLoading: boolean) => void,
  fetchLoan: (id: number) => Promise<void>
  loadLoans: () => Promise<void>
  loadLoan: boolean,
  selectedLoan:LoanModel | undefined
  setSelectedLoan:  React.Dispatch<React.SetStateAction<LoanModel | undefined>>
  isLoading: boolean;
  setIsLoading: (isLoading:boolean) => void;
}
const LoansUIContext = createContext<LoansUIContextModel | null>(null);

export function useLoansUIContext() {
  return useContext(LoansUIContext);
}

export const LoansUIConsumer = LoansUIContext.Consumer;

export type LoansUIProviderProps = {
  loansUIEvents: any,
}

export const LoansUIProvider:React.FC<LoansUIProviderProps> =  ({loansUIEvents, children}) => {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [valuesTable, setValuesTable] = useState<resultTableLoans>(initialValues);
  const [tableLoading, setTableLoading] = useState(true);
  const [selectedLoan, setSelectedLoan] = useState<LoanModel>();
  const [loadLoan, setLoadLoan] = useState(false);
  const [ids, setIds] = useState<number[]>([]);
  const [isLoading,setIsLoading] = useState(false);


  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {

      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initLoan: Partial<LoanModel> = {
    id: undefined,
    name: "",
    debtor: 0,
    value: 0,
    lender: 3,
  };

  const loadLoans = async () => {
    setSelectedLoan(undefined);
    setTableLoading(true);
    try {
      const loanResult = await axios.post(`${process.env.REACT_APP_API_URL}/loan/findAll`,{
        queryParams,
      });
      setValuesTable(loanResult.data);
    } catch (error) {
      console.log('error', error)
    } finally {
      setTableLoading(false);
    }
  }
  useEffect(()=> {
    loadLoans();
  },[queryParams])

  const fetchLoan = useCallback(async (id:number)=> {
    try {
      setLoadLoan(true)
      const result = await axios.get(`${process.env.REACT_APP_API_URL}/loan/${id}`);
      setSelectedLoan(result.data);
    } catch (error) {
      console.log('error loading loan', error)
    } finally {
      setTimeout(()=>{
        setLoadLoan(false);
      },1500)
    }
  },[])

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initLoan,
    newLoanButtonClick: ()=>{
      setSelectedLoan(undefined);
      loansUIEvents.newLoanButtonClick();
    },
    openEditLoanDialog: loansUIEvents.openEditLoanDialog,
    openDeleteLoanDialog: loansUIEvents.openDeleteLoanDialog,
    openDeleteLoansDialog: loansUIEvents.openDeleteLoansDialog,
    openFetchLoansDialog: loansUIEvents.openFetchLoansDialog,
    openUpdateLoansStatusDialog: loansUIEvents.openUpdateLoansStatusDialog,
    valuesTable,
    tableLoading,
    loadLoans,
    fetchLoan,
    selectedLoan,
    setSelectedLoan,
    loadLoan,
    isLoading,
    setIsLoading
  };

  return <LoansUIContext.Provider value={value}>{children}</LoansUIContext.Provider>;
}


