import React from 'react'
import { TwoFactorAuthentication } from './TwoFactorAuthentication'
import { Switch, Route, Redirect } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/helpers/AssetHelpers';

const TwoFactorPage = () => {
  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      }}
    >
        <div className='container mt-10'>
            <div className='row d-block d-sm-none'>
                {/* begin::Logo */}
                <div className='col d-flex justify-content-center'>
                    <div className='mb-12 text-center'>
                        <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-1.svg')} className='h-65px' />
                    </div>
                </div>
                {/* end::Logo */}
            </div>
            {/* begin::Content */}
            <div className='row pt-10 pb-10 pb-lg-20'>
                {/* begin::Wrapper */}
                <div className='col-sm-6 bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
                <Switch>
                    <Route path='/auth/two-factor' component={TwoFactorAuthentication} />
                    <Redirect to='/auth/login' />
                </Switch>
                </div>
                {/* end::Wrapper */}
                <div className='col-sm-6 row mt-10 mt-sm-0 ml-sm-20'>
                        {/* begin::Logo */}
                        <div className='col d-none d-sm-flex justify-content-center'>
                            <div className='mb-12 text-center'>
                            <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-1.svg')} className='h-65px' />
                            </div>
                        </div>
                        {/* end::Logo */}
                        <div className='col'>
                            <div className='mb-12'>
                            <img alt='Logo' src={toAbsoluteUrl('/media/illustrations/cell_2auth.png')} />
                            </div>
                        </div>
                </div>
            </div>
            {/* end::Content */}
            {/* begin::Footer */}
            <div className='d-flex flex-center flex-column-auto p-10'>
                <div className='d-flex align-items-center fw-bold fs-6'>
                <div className='text-muted text-hover-primary px-2'>
                    About
                </div>

                <div className='text-muted text-hover-primary px-2'>
                    Contact
                </div>

                <div className='text-muted text-hover-primary px-2'>
                    Contact Us
                </div>
                </div>
            </div>
            {/* end::Footer */}
        </div>
    </div>
  )
}

export default TwoFactorPage