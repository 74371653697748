// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
// import * as actions from "../../../_redux/orders/ordersActions";
import {
  // getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/helpers";
import * as uiHelpers from "../OrdersUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/partials/control";
import { useOrdersUIContext, OrdersUIContextModel } from '../OrdersUIContext';
import { useIntl } from "react-intl";
import ReactTooltip from 'react-tooltip';
import Swal from 'sweetalert2';
import axios from 'axios';
import DeleteZeros from "../../../../../../utils/functions/DeleteZerosHelper";
import useIntlCurrencyFormat from "../../../../../../utils/customHooks/useIntlCurrencyFormat";
import { Col, Row } from "react-bootstrap";
import moment from 'moment';

export function OrdersTable() {
  // const { company } = useSelector((state) => state.auth);
  // Orders UI Context
  const ordersUIContext = (useOrdersUIContext() as OrdersUIContextModel);
  const ordersUIProps = useMemo(() => {
    return {
      ids: ordersUIContext.ids,
      setIds: ordersUIContext.setIds,
      queryParams: ordersUIContext.queryParams,
      setQueryParams: ordersUIContext.setQueryParams,
      openEditOrderDialog: ordersUIContext.openEditOrderDialog,
      openDeleteOrderDialog: ordersUIContext.openDeleteOrderDialog,
      tableLoading: ordersUIContext.tableLoading,
      valuesTable: ordersUIContext.valuesTable,
      loadOrders: ordersUIContext.loadOrders,
      setIsLoading: ordersUIContext.setIsLoading,
      openOrderPaymentDialog: ordersUIContext.openOrderPaymentDialog,
    };
  }, [ordersUIContext]);

  const {cop,thousand} = useIntlCurrencyFormat()
  const { totalCount, data:{entities} } = ordersUIProps.valuesTable;
  // Orders Redux state
  // Table columns
  const intl = useIntl();

  const updateStatusRequest = async (id:number, status:number) => {
    try {
      ordersUIProps.setIsLoading(true);
      await axios.put(`${process.env.REACT_APP_API_URL}/order/status/${id}`,{
        status
      })
      Swal.fire({
        title:"Orden de producción actualizada correctamente",
        icon: "success",
      }).then(()=>{
        ordersUIProps.loadOrders()
      })

    } catch (error) {
      Swal.fire({
        title:"Hubo un error al actualizar la orden de producción",
        icon: "error"
      })
    } finally {
      ordersUIProps.setIsLoading(false);
    }

  }

  const changeStatusOrder = (orderId: number) => {
    Swal.fire({
      icon:'warning',
      title:'¿Estas seguro que desea recibir esta orden de producción?',
      showCancelButton: true
    }).then(r => {
      if(r.isConfirmed){
        updateStatusRequest(orderId,2)
      }
    })
  }

  const columns = [
    {
      dataField: "reference",
      text: intl.formatMessage({ id: "GENERAL.REFERENCE"}),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "fabricCutNumber",
      text: "Número de corte",
      sort: false,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "quantity",
      text: "Cantidad",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent: any, row: any, rowIndex:any) => thousand?.format(cellContent)
    },
    {
      dataField: "factory",
      text: intl.formatMessage({ id: "FACTORY.FACTORY"}),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "costManufact",
      text: "Costo Manufactura",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent: any, row: any, rowIndex: any) => DeleteZeros(cop?.format(cellContent))
    },
    // {
    //   dataField: "paidManufacture",
    //   text: "Abonado Manufactura",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    //   formatter: (cellContent: any, row: any, rowIndex: any) => DeleteZeros(cop?.format(cellContent))
    // },
    {
      dataField: "balanceManufacture",
      text: "Saldo Manufactura",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent: any, row: any, rowIndex: any) => DeleteZeros(cop?.format(cellContent))
    },
    {
      dataField: "costProfit",
      text: "Ganancias",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent: any, row: any, rowIndex: any) => DeleteZeros(cop?.format(cellContent))
    },
    // {
    //   dataField: "paidProfit",
    //   text: "Abonado Ganancias",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    //   formatter: (cellContent: any, row: any, rowIndex: any) => DeleteZeros(cop?.format(cellContent))
    // },
    {
      dataField: "balanceProfit",
      text: "Saldo Ganancias",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent: any, row: any, rowIndex: any) => DeleteZeros(cop?.format(cellContent))
    },
    {
      dataField: "orderDate",
      text: "Fecha",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent: any, row: any, rowIndex: any,)=> moment(row.orderDate).format('LL')
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "GENERAL.STATUS"}),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter:(cellContent: any, row: any, rowIndex: any,)=> <span className={`badge bg-${row.status === 1 ? 'warning':'success' }`}>{ row.status === 1 ? 'Pendiente' : 'Aprobado' }</span>
    },
    {
      dataField: "action",
      // text: intl.formatMessage({ id: "FORMULARIES.ACTIONS"}),
      text: intl.formatMessage({ id: "GENERAL.ACTIONS"}),
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditOrderDialog: ordersUIProps.openEditOrderDialog,
        openDeleteOrderDialog: ordersUIProps.openDeleteOrderDialog,
        changeStatusOrder: changeStatusOrder,
        openOrderPaymentDialog: ordersUIProps.openOrderPaymentDialog,
        intl,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: [...uiHelpers.sizePerPageList, {text: `${totalCount}`, value: totalCount}],
    sizePerPage: ordersUIProps.queryParams.pageSize,
    page: ordersUIProps.queryParams.pageNumber,
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  })

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={ordersUIProps.tableLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                {...(paginationTableProps)}
                
                keyField="id"
                data={entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  ordersUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: ordersUIProps.ids,
                //   setIds: ordersUIProps.setIds,
                // })}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
      <hr style={{background: '#bcc3d7', marginInline: '-2.25rem', height: '1px', marginBottom: '2.25rem'}} />
      <h3>Sub Totales:</h3>
      <Row>
        <Col md='12'>
          <strong>Cantidad: </strong>
          {thousand?.format(entities.reduce((p, c) => p + c.quantity, 0))}
        </Col>
        <Col md='4'>
          <strong>Costo Manufactura: </strong>
          {DeleteZeros(cop?.format(entities.reduce((p, c) => p + Number(c.costManufact), 0)))}
        </Col>
        <Col md='4'>
          <strong>Abonado Manufactura: </strong>
          {DeleteZeros(cop?.format(entities.reduce((p, c) => p + Number(c.paidManufacture), 0)))}
        </Col>
        <Col md='4'>
          <strong>Saldo Manufactura: </strong>
          {DeleteZeros(cop?.format(entities.reduce((p, c) => p + Number(c.balanceManufacture), 0)))}
        </Col>
        <Col md='4'>
          <strong>Ganancias: </strong>
          {DeleteZeros(cop?.format(entities.reduce((p, c) => p + Number(c.costProfit), 0)))}
        </Col>
        <Col md='4'>
          <strong>Abonado Ganancias: </strong>
          {DeleteZeros(cop?.format(entities.reduce((p, c) => p + Number(c.paidProfit), 0)))}
        </Col>
        <Col md='4'>
          <strong>Saldo Ganancias: </strong>
          {DeleteZeros(cop?.format(entities.reduce((p, c) => p + Number(c.balanceProfit), 0)))}
        </Col>
      </Row>
    </>
  );
}



