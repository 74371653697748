import React from 'react'
import { SupplyModel } from '../../supplies/models/index';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers/AssetHelpers';
import DeleteZerosHelper from '../../../../../../utils/functions/DeleteZerosHelper';
import useIntlCurrencyFormat from '../../../../../../utils/customHooks/useIntlCurrencyFormat';
import { Inventory } from '../../../../processes/pages/orders/order-edit-dialog/OrderEditForm';

const NumberFormat = Intl.NumberFormat("es-CO", { maximumFractionDigits: 2 })
const fix2 = (num: number) => NumberFormat.format(num);
export interface SupplyTableProps {
    addSupply:SupplyModel[]
    setAddSupply:  React.Dispatch<React.SetStateAction<SupplyModel[]>>
    supplyTypeAll: SupplyModel[]
    supplyType: SupplyModel[]
    setSupplyType: React.Dispatch<React.SetStateAction<SupplyModel[]>>
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
    setSelectedSupply: React.Dispatch<React.SetStateAction<SupplyModel | null>>
    noProduct?: number
    id?:number
    status?: number
    avaliableSupplies: Inventory[]
    setAvaliableSupplies: React.Dispatch<React.SetStateAction<Inventory[]>>
}
const SupplyTable: React.FC<SupplyTableProps> = ({
    addSupply,
    setAddSupply,
    supplyTypeAll,
    supplyType,
    setSupplyType,
    setFieldValue,
    setSelectedSupply,
    noProduct,
    id,
    status,
    avaliableSupplies,
    setAvaliableSupplies
}) => {
    const {cop} = useIntlCurrencyFormat();
    const deleteSupply = (index:number, supplyId:number, quantity: number) => {
        const aux = addSupply.filter((s, i)=> i !== index);
        if(aux.length === 0){
            setFieldValue('supplies', "");
        } else {
            setFieldValue('supplies', JSON.stringify(aux));
        }
        const supplyAux = supplyType;
        supplyAux.push(supplyTypeAll.find((s) => s.id === supplyId) as SupplyModel);
        setSupplyType(supplyAux);
        setAddSupply(aux);
        setSelectedSupply(null);
        setAvaliableSupplies((prev) =>
            prev.map((a) => ({
            ...a,
            currentQuantity:
                a.supplyId === supplyId ? a.currentQuantity + quantity : a.currentQuantity,
            }))
        )
    }
    return (
        <>
            {
                addSupply.length > 0 &&
                <div className="form-group row my-7" style={{overflowX: 'scroll'}}>
                    <div>
                        <table className="styled-table-success" >
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{"Insumo"}</th>
                                    <th>{"Referencia"}</th>
                                    <th>{"Unidad"}</th>
                                    <th>{"Costo por unidad"}</th>
                                    <th>{"Cantidad por producto"}</th>
                                    <th>{"Costo por producto"}</th>
                                    <th>{"Cantidad"}</th>
                                    <th>{"SubTotal"}</th>
                                    {
                                        ((id && status === 1) || id === undefined) &&
                                            <th>{"Acción"}</th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    addSupply.map((item, index)=>(
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.name}</td>
                                        <td>{item.reference}</td>
                                        <td>{item.unit?.name}</td>
                                        <td>{DeleteZerosHelper(cop?.format(item?.lastPurchasePrice ? item?.lastPurchasePrice : 0 ))}</td>
                                        <td>{fix2(item.productSupply.quantityPerProduct ?? item.productSupply.quantity / ( noProduct as number ))}</td>
                                        <td>{DeleteZerosHelper(cop?.format(item?.lastPurchasePrice ? item?.lastPurchasePrice * (item.productSupply.quantity / ( noProduct as number )) : 0 ))}</td>
                                        <td>{fix2(item.productSupply.quantity)}</td>
                                        <td>{DeleteZerosHelper(cop?.format(Number(item?.lastPurchasePrice ? item?.lastPurchasePrice : 0)*Number(item.productSupply.quantity)))}</td>
                                        {
                                            ((id && status === 1) || id === undefined) &&
                                                <td>
                                                    <a
                                                        className="btn btn-icon btn-danger btn-hover-danger btn-sm mx-1 "
                                                        onClick={() => deleteSupply(
                                                            index,
                                                            item.id as number,
                                                            item.productSupply.quantity as number,
                                                        )}
                                                    >
                                                        <span className="svg-icon svg-icon-md svg-icon-danger">
                                                        <SVG
                                                            src={toAbsoluteUrl("/media/svg/icons/Home/Trash.svg")}
                                                        />
                                                        </span>
                                                    </a>
                                                </td>
                                        }
                                    </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </>
    )
}

export default SupplyTable
