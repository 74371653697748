import React, {createContext, useContext, useState, useCallback, useEffect} from 'react'
import {isEqual, isFunction} from 'lodash'
import {initialFilter, initialValues} from './ProfitsUIHelpers'
import axios from 'axios'
import useIntlCurrencyFormat from '../../../../../utils/customHooks/useIntlCurrencyFormat'
import DeleteZerosHelper from '../../../../../utils/functions/DeleteZerosHelper'
import moment from 'moment'

//Modelos
import { ClientModel } from '../../../admin/pages/client/models/index';
import { FactoryModel } from "../../../admin/pages/factories/models";

//* Interfaces
interface dataDataResultTableWaller {
  entities: any[]
  totalCount: number
  errorMessage: string
}

export interface resultTableProfits {
  data: dataDataResultTableWaller
  totalCount: number
}

export interface ProfitsSumary {
  quantity: number
  price: string
  profit: string
}

export interface BillingProfits {
  quantity: number
  price: string
  profit: string
  products: string
  createdAt: string
  reference: string
  clientName: string
  clientId: number
  factoryId: number
  factory: string
  id: number
}

export interface QueryParams {
  clientId: number | null
  factoryId: number | null
  product: string
  startDate: string | null
  endDate: string | null
}

export interface ProfitsUIContextModel {
  queryParams: QueryParams
  setQueryParams: (queryParams: any) => void
  clients: ClientModel[],
  factories: FactoryModel[]
}

const ProfitsUIContext = createContext<ProfitsUIContextModel | null>(null)

export function useProfitsUIContext() {
  return useContext(ProfitsUIContext)
}

export const ProfitsUIConsumer = ProfitsUIContext.Consumer

export type ProfitsUIProviderProps = {
  profitsUIEvents: any
}

export const ProfitsUIProvider: React.FC<ProfitsUIProviderProps> = ({
  profitsUIEvents,
  children,
}) => {
  const [queryParams, setQueryParams] = useState<QueryParams>(initialFilter)
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [factories, setFactories] = useState<FactoryModel[]>([])

  const loadFactories = async ()=>{
    try {
      const response = await axios.get<FactoryModel[]>(`${process.env.REACT_APP_API_URL}/factory`);
      setFactories(response.data);
    } catch (error) {
    } finally {

    }
  }

  const loadClients = async ()=>{
    try {
        const result = await axios.get<ClientModel[]>(`${process.env.REACT_APP_API_URL}/client`);
        setClients(result.data);
    } catch (error) {
        console.log('error', error)
    } finally {}
  }

  useEffect(() => {
    loadFactories();
    loadClients();
  }, [])


  const value = {
    queryParams,
    setQueryParams,
    clients,
    factories,
  }

  return <ProfitsUIContext.Provider value={value}>{children}</ProfitsUIContext.Provider>
}
