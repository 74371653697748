import jsPDF from 'jspdf'
import moment from 'moment'
import 'moment/locale/es';
import autoTable, { CellInput, RowInput } from 'jspdf-autotable'
// import { toAbsoluteUrl } from '../../_metronic/helpers/AssetHelpers';
import DeleteZerosHelper from '../functions/DeleteZerosHelper';
import { PurchaseModel } from '../../app/modules/processes/pages/purchases/models';
import { SupplyRealData } from '../../app/modules/admin/pages/supplies/models';
moment.locale('es')

export interface totalsTable{
  totalSupplies: number;
  totalToPay: number;
}

const prepareData =  (supplies: Partial<SupplyRealData>[],format:(value: number | bigint) => string) => {
  let data: RowInput[] = []
  let aux: CellInput[]  = []

  supplies.forEach((supply,i: number) => {
    aux[0] = i+1;
    aux[1] = supply?.supplyName ? supply.supplyName : '';
    aux[2] = DeleteZerosHelper(format(supply?.price as number));
    aux[3] = supply?.quantity as number;
    aux[4] = DeleteZerosHelper(format(Number(supply?.price) * Number(supply?.quantity)));
    data.push(aux);
    aux = [];
  })
  return data;
}

const calcTotals = (supplies:Partial<SupplyRealData>[]) => {
  let result: totalsTable= {totalSupplies:0,totalToPay:0};
  let totalSupplies=0;
  let totalToPay=0;
  supplies.forEach(supply => {
    totalSupplies += Number(supply.quantity);
    totalToPay += Number(supply.price) * Number(supply.quantity);
  })
  result.totalSupplies = totalSupplies;
  result.totalToPay = totalToPay;
  return result;
}


export async function generatePurchaseOrder(purchase:Partial<PurchaseModel>,format:(value: number | bigint) => string): Promise<void>{
  const head = [["#","Nombre del insumo","Precio","Cantidad","Total"]]
  const dataFixed = prepareData(purchase?.supplies as SupplyRealData[],format)
  const totales = calcTotals(purchase?.supplies as SupplyRealData[])

  dataFixed.push( ["","","Total",`${totales.totalSupplies}`,`${DeleteZerosHelper(format(totales.totalToPay))}`])

  dataFixed.push( ["","","","Descuento",`${DeleteZerosHelper(format(purchase.discount ? purchase.discount: 0 ))}`])
  // dataFixed.push(  ["","","","Envío","0"])
  // dataFixed.push(  ["","","","Devoluciones","0"])
  dataFixed.push(  ["","","","Cantidad Total",`${DeleteZerosHelper(format(totales.totalToPay - (purchase.discount ? purchase.discount: 0 )))}`])
  // dataFixed.push(  ["","","","Pagado","0"])
  // dataFixed.push( ["","","","Balance","$ 1.085.000,00"])


  
  const doc = new jsPDF();
  //*Titulo
	doc.setFont('helvetica', 'bold');
  doc.setFontSize(24);
  doc.text("Orden de compra",10,20);

  //*Subtitulo
	doc.setFont('helvetica', 'normal');
  doc.setFontSize(12);
  //Fecha de venta
  doc.text(`Fecha de Compra: ${moment(purchase.purchaseDate).format('ll')}`,10,30);

  //No. Referencia
  doc.text(`Referencia No : ${purchase.reference}`,10,35);
  
  //Clientes
  doc.text("",10,40);
  doc.text(`Proveedor : ${purchase.providerName}`,10,40);

  //Notas
  const notas = doc.splitTextToSize(`Notas : ${purchase.description}`, 180);
  doc.text(notas, 10, 45);
  const notasY = (notas.length - 1) * 5

  //Lista de productos
  doc.setFontSize(14)
	doc.setFont('helvetica', 'bold');
  doc.text("Lista de insumos",10,55 + notasY);
	doc.setFont('helvetica', 'normal');

  //*Tabla
  autoTable(doc, {
    head: head,
    body: dataFixed,
    startY: 60 + notasY,
    margin: {
      left: 10
    }
  });

  let finalY = (doc as any).lastAutoTable.finalY
  //*Nombre Fabrica
  doc.setFontSize(18)
  doc.text(` ${purchase.factoryName}`,15,finalY+20)

  
  
  doc.save(`${purchase.reference}.pdf`)
}
