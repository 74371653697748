import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios';
import { useWalletUIContext, WalletUIContextModel } from '../WalletUIContext';

export interface creditorsInterface{
    id: number;
    name: string;
}

const useLoadCreditors = (id:number | undefined) => {
    const [creditors,setCreditors] = useState<creditorsInterface[]>();

    //Contexto
    const walletUIContext = (useWalletUIContext() as WalletUIContextModel);
    const walletUIProps = useMemo(() => {
      return{
        selectedIdWallet: walletUIContext.selectedIdWallet,
      }
    },[walletUIContext.selectedIdWallet])
    useEffect(() => {
        const loadCreditors = async () => {
            if(id){
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/client/getCreditors/${id}`)
                setCreditors(response.data);
            }
        }
        loadCreditors()
    },[walletUIProps.selectedIdWallet])

    return {
        creditors
    }
}

export default useLoadCreditors
