import React,{ useState,useMemo, useEffect } from 'react';
import { Field } from 'formik';
import { AutoCompleteInput } from '../../../../../../_metronic/partials/control/forms/AutoComplete';
import { creditorsInterface } from '../hooks/useLoadCreditors';
import { allClientBillings } from  '../hooks/useLoadAllClientBillings';
import { useWalletUIContext, WalletUIContextModel } from '../WalletUIContext';
import { Table } from 'antd';
import DeleteZerosHelper from '../../../../../../utils/functions/DeleteZerosHelper';
import useIntlCurrencyFormat from '../../../../../../utils/customHooks/useIntlCurrencyFormat';
import ReportByFactory from '../components/ReportByFactory';
import TotalDebts from '../components/TotalDebts';
import { Modal } from "react-bootstrap";
import axios from 'axios'

interface DebtsProps{
    creditors: creditorsInterface[] | undefined
    allBillings: allClientBillings | undefined
}
const Debts: React.FC<DebtsProps> = ({
    creditors,
    allBillings
}) => {
    const {cop} = useIntlCurrencyFormat()
    //Contexto
    //Configuracion Contexto
    const walletUIContext = (useWalletUIContext() as WalletUIContextModel);
    const walletUIProps = useMemo(() => {
        return{
            setSelectedCreditor: walletUIContext.setSelectedCreditor,
            selectedCreditor: walletUIContext.selectedCreditor,
            clientBillingTable: walletUIContext.clientBillingTable,
            selectedIdWallet: walletUIContext.selectedIdWallet
        }
    },[walletUIContext])
    const [auxiliar,setAuxiliar] = useState("")
    const [showGeneral,setShowGeneral] = useState<number | null>(null);

    const columns =  [
        {
          title: 'Fecha',
          dataIndex: 'billingDate',
          key: 'date',
        },
        {
            title: 'Referencia',
            dataIndex: 'reference',
            key: 'reference'
        },
        {
            title: 'Fabrica',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Valor',
            dataIndex: 'value',
            key: 'value'
        },
    ];


    return(
        <>
            <div className="form-group row my-4">
                <div className="col-lg-4">
                    <small>
                        <strong>
                            Escoja para buscar las deudas
                        </strong>
                    </small>
                    <Field
                        name="creditor"
                        className="mt-3"
                        component={AutoCompleteInput}
                        label="Acreedor"
                        options={creditors || []}
                        onChange={(e:any, value:creditorsInterface | null ) => {
                            walletUIProps.setSelectedCreditor(value !== null ? value.id : 0)
                            setShowGeneral(value !== null ? value.id : null)
                        }}
                        getOptionLabel={(option: creditorsInterface) => `${option.name}`}
                        onInputChange={(e:any,value:string) => setAuxiliar(value)}
                    />
                </div>
            </div>

            {(walletUIProps.selectedCreditor !== 0) && (walletUIProps.clientBillingTable) &&
                <div className="form-group row my-4">
                    <h2>
                        Total deuda: {DeleteZerosHelper(cop?.format(walletUIProps.clientBillingTable.total))}
                    </h2>
                    {showGeneral !== null &&
                        <Table
                            columns={columns}
                            dataSource={walletUIProps.clientBillingTable.billings}
                            scroll={{x:'calc(700px + 50%)'}}
                            pagination={{
                                pageSizeOptions: [10, 50,100,500,1000, walletUIProps.clientBillingTable.billings?.length ?? 0],
                                showSizeChanger: true,
                                defaultPageSize: 50
                            }}
                        />
                    }

                </div>
            }

                {
                    (showGeneral === null && allBillings) &&
                    <>
                        <h2>Total deuda: {DeleteZerosHelper(cop?.format(allBillings.total))}</h2>
                        <Table
                            columns = {allBillings.data.columns}
                            dataSource={allBillings.data.dataSource}
                            scroll={{x:'calc(700px + 50%)'}}
                            pagination={{
                                pageSizeOptions: [10, 50,100,500,1000, allBillings.data.dataSource?.length ?? 0],
                                showSizeChanger: true,
                                defaultPageSize: 50,
                            }}
                        />
                    </>
                }

            <Modal.Footer>
                {
                    (walletUIProps.clientBillingTable && auxiliar !== "") &&
                    <ReportByFactory
                        data={walletUIProps.clientBillingTable?.billings}
                        total={walletUIProps.clientBillingTable?.total}
                    />
                }
                {
                    allBillings &&
                    <>
                        <TotalDebts
                            //@ts-ignore
                            data={allBillings}
                        />
                    </>
                }
            </Modal.Footer>
        </>
    )
}

export default Debts
