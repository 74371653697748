// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useRef, useMemo, useEffect} from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Input, Select,
} from "../../../../../../_metronic/partials/control";
import axios from 'axios';
import { useIntl } from "react-intl";
import { shallowEqual, useSelector } from 'react-redux';
import { OrderModel } from "../models";
import { UserComplete } from "../../../../auth/models/UserModel";
import { RootState } from '../../../../../../setup/redux/RootReducer';
import { OrdersLoadingDialog } from '../orders-loading-dialog/OrdersLoadingDialog';
import TextAreaInput from '../../../../../../_metronic/partials/control/forms/TextAreaInput';
import useLoadForms from '../components/hooks/useLoadForms';
import { ProductOrderInterfaceForm, ProductModel } from '../../../../admin/pages/products/models/index';
import useIntlCurrencyFormat from '../../../../../../utils/customHooks/useIntlCurrencyFormat';
import DeleteZerosHelper from '../../../../../../utils/functions/DeleteZerosHelper';
import Swal from 'sweetalert2';
// import { getDifference } from '../../../../../../utils/differenceObjArr';
import { useOrdersUIContext, OrdersUIContextModel} from '../OrdersUIContext';
import { AutoCompleteInput } from "../../../../../../_metronic/partials/control/forms/AutoComplete";
import { SupplyModel } from '../../../../admin/pages/supplies/models/index';
import SupplyTable from "../../../../admin/pages/products/components/SupplyTable";
import SelectReferences from "../components/SelectReferences";
import { generateOrder } from "../../../../../../utils/PDF/order";

export interface OrderEditFormProps {
  saveOrder: (order: OrderModel) => void;
  order?: Partial<OrderModel>
  actionsLoading?: boolean;
  onHide?: () => void;
}

export interface Inventory {
  factoryId: number,
  supplyId: number,
  supplyName: string,
  currentQuantity: number,
  purchasedSupp: string,
  usedSupp: number,
  key: number
}

const NumberFormat = Intl.NumberFormat("en-US", { maximumFractionDigits: 2, useGrouping: false })
const fix2 = (num: number) => NumberFormat.format(num);

// Validation schema
const OrderEditSchema = Yup.object().shape({
  reference: Yup.string().required('requerido'),
  factory: Yup.number().required('requerido'),
  productQuantity: Yup.number().min(1, 'requerido').required('requerido'),
  costManufactPerProduct: Yup.number().min(1, 'requerido').required('requerido')
});

export const OrderEditForm: React.FC<OrderEditFormProps> = ({
  saveOrder,
  order,
  actionsLoading,
  onHide,
}) => {
  const userComplete: UserComplete = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserComplete
  const [addProduct, setAddProduct] = useState<ProductOrderInterfaceForm[]>([]);
  const [supplyType, setSupplyType] = useState<SupplyModel[]>([]);
  const [supplyTypeAll, setSupplyTypeAll] = useState<SupplyModel[]>([]);
  const [selectedSupply, setSelectedSupply] = useState<SupplyModel | null>(null);
  const [addSupply, setAddSupply] = useState<SupplyModel[]>([]);
  const [unidad,setUnidad] = useState<number | null>(null);
  const [costSupplies,setCostSupplies] = useState(0);
  const [costManufact,setCostManufact] = useState(0);
  const [profitPercentage, setProfitPercentage] = useState(10);
  const [totalCost,setTotalCost] = useState(0);
  const [profit, setProfit] = useState(0)
	const [invProfit, setinvProfit] = useState(0);
  const [availableQuantity, setAvailableQuantity] = useState(0);
  const [loadingValues, setLoadingValues] = useState<boolean>(true);
  const [auxFactory,setAuxFactory] = useState(0);
  const [avaliableSupplies,setAvaliableSupplies] = useState<Inventory[]>([]); //Inventario de insumo por fabrica
  const [loadedAvaliableSupplies, setLoadedAvaliableSupplies] = useState(false); //Inventario de insumo por fabrica
  const [maxProducts, setMaxProducts] = useState(Infinity)

  const formRef = useRef<FormikProps<OrderModel>>(null);
  const {
    factories,
  } = useLoadForms({
    order,
    setAddProduct,
    formRef,
  });
  const {cop} = useIntlCurrencyFormat();
  const intl = useIntl();

  //Contexto
  const ordersUIContext = (useOrdersUIContext() as OrdersUIContextModel);
  const ordersUIProps = useMemo(() => {
    return {
     factoryId: ordersUIContext.factoryId,
     setFactoryId: ordersUIContext.setFactoryId
    };
  }, [ordersUIContext]);


  const loadAvaliableSupplies = async (id: number) => {
    try{
      if(id === 0) return [];
      setLoadedAvaliableSupplies(false);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/factory/getSupplyInventory/${id}`);
      setAvaliableSupplies(response.data);
      setLoadedAvaliableSupplies(true);
      return response.data
    }catch(error){
      console.log('error getting inventory',error)
    }
  }

  //Traer inventario disponible de la fabrica
  useEffect(() => {
    loadAvaliableSupplies(auxFactory);
  },[auxFactory])

  //Cargar todos los insumos
  const loadDocumentTypes = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/supply`)
      const allDAta = (response.data as SupplyModel[]).map((s) => {
        // @ts-ignore
        s.productSupply = {}
        return s
      })
      if (order && order.id) {
        //todo revisar que siga funcionando
        // @ts-ignore
        const suppliesObj = JSON.parse(order.supplies)
        const aux = allDAta.filter((obj) => {
          return !suppliesObj.some((obj2: any) => {
            return obj.id === obj2.id
          })
        })
        setSupplyType(aux)
      } else {
        setSupplyType(allDAta)
      }
      setSupplyTypeAll(allDAta)
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoadingValues(false)
    }
  }

  const handleCostSupplies = () => {
    if(addSupply){
      const totalTemp = addSupply.reduce((a,b:SupplyModel) => a + ((b?.lastPurchasePrice ? b.lastPurchasePrice : 0) * b.productSupply.quantity) ,0)
      setCostSupplies(totalTemp)
      const maxProducts = addSupply.map(s => {
       return ((s.productSupply.avaliableQuantity ?? 0) + s.productSupply.quantity )/ (s.productSupply.quantityPerProduct ?? 1)
      })
      if (maxProducts.length !== 0) {
        setMaxProducts(Math.floor(Math.min(...maxProducts)))
      } else {
        setMaxProducts(Infinity)
      }
    }
  }

  const handleAddSupply = (values:OrderModel, setFieldValue:(field: string, value: any, shouldValidate?: boolean | undefined) => void) => {
    const aux = [...addSupply];
    let totalQuantity:number = 0;
    // if(!values.productQuantity){
    //   return Swal.fire({
    //     title: 'Por favor digite la cantidad de producto a elaborar',
    //     icon: 'warning',
    //   })
    // }
    if(!selectedSupply){
      return Swal.fire({
        title: 'Por favor escoja un insumo',
        icon: 'warning',
      })
    }
    if(!values.quantity){
      return Swal.fire({
        title: 'Por favor ingrese una cantidad',
        icon: 'warning',
      })
    }
    const avaliableQ = avaliableSupplies.find(a => Number(a.supplyId) === selectedSupply.id)
    if(avaliableQ === undefined){
      return Swal.fire({
        title: 'No hay suficiente cantidad de este insumo',
        icon: 'warning',
      })
    }

    totalQuantity = values.quantity * (values.productQuantity ?? 0);
    if(avaliableQ.currentQuantity < totalQuantity){
      return Swal.fire({
        title: `No hay suficiente cantidad de este insumo`,
        icon: 'warning',
      })
    }

    selectedSupply.productSupply.quantity = totalQuantity;
    selectedSupply.productSupply.quantityPerProduct = values.quantity;
    selectedSupply.productSupply.avaliableQuantity = avaliableQ.currentQuantity - totalQuantity;
    setAvaliableSupplies((prev) =>
      prev.map((a) => ({
        ...a,
        currentQuantity:
          avaliableQ.supplyId === a.supplyId
            ? a.currentQuantity - totalQuantity
            : a.currentQuantity,
      }))
    )
    setSelectedSupply(null)
    aux.push(selectedSupply as SupplyModel);
    setFieldValue('supplies', JSON.stringify(aux));
    setFieldValue('quantity', 0);
    setAddSupply(aux);
    const aux2 = supplyType.filter(supply => supply.id !== (selectedSupply as SupplyModel).id);
    setSupplyType(aux2);
  }

  const handleUpdateSupply = (nextQuantity: number) => {
    const supplies = addSupply.map((s) => {
      const nextTotalQuantity = (s.productSupply.quantityPerProduct ?? 0) * nextQuantity
      const available = (s.productSupply.avaliableQuantity ?? 0) + s.productSupply.quantity

      // if (available < nextTotalQuantity) {
      //   throw new Error('unavailable')
      // }

      setAvaliableSupplies((prev) =>
        prev.map((a) => ({
          ...a,
          currentQuantity: s.id === a.supplyId ? available - nextTotalQuantity : a.currentQuantity,
        }))
      )

      return {
        ...s,
        productSupply: {
          ...s.productSupply,
          quantity: nextTotalQuantity,
          avaliableQuantity: available - nextTotalQuantity,
        }
      }
    })

    setAddSupply(supplies)
    return supplies;
  }

  const loadProductSupplies = async (id: number, idFactory: number) => {
    const {data: product} = await axios.get(`${process.env.REACT_APP_API_URL}/product/${id}`)
    const productQuantity = product.totalCost / product.productPrice;
    const supplies = product.supplies as SupplyModel[]
    const available = await loadAvaliableSupplies(idFactory)

    return supplies.map((sid:any) => ({
      ...supplyTypeAll.find(s => s.id === sid.id),
      productSupply: {
        quantity: 0,
        quantityPerProduct: sid.productSupply.quantity / productQuantity,
        avaliableQuantity: available.find((s: any) => Number(s.supplyId) === sid.id)?.currentQuantity ?? 0
      },
    })) as SupplyModel[]
  }

  //Si se cambia la empresa se borran los productos seleccionados
  useEffect(() => {
    setAddProduct([])
  },[ordersUIProps.factoryId])

  useEffect(() => {
    loadDocumentTypes()
    if(order && order.id){
      setAuxFactory(order.factory ? order.factory : 0);
    }

    return () => {
      setLoadingValues(true)
    }
  }, [])

  useEffect(() => {
    handleCostSupplies()
  },[addSupply])

  useEffect(() => {
    const total = costSupplies + costManufact;
    const ganancia = total * (profitPercentage / 100);
    setProfit(ganancia);
    setTotalCost(total + ganancia + invProfit);
  },[costSupplies,costManufact, profitPercentage, invProfit]);

  useEffect(()=>{
    const aux = avaliableSupplies.find(s => Number(s.supplyId) === selectedSupply?.id);
    if(!aux){
      setAvailableQuantity(0);
    } else {
      setAvailableQuantity(aux.currentQuantity);
    }
  },[selectedSupply, avaliableSupplies])

  useEffect(()=>{
    if(order && order.id && loadedAvaliableSupplies && !loadingValues){
      setCostSupplies(Number(order.costSupplies??0))
      setCostManufact(Number(order.costManufact??0))
      setProfitPercentage(Number(order.profitPercentage ?? 0)*100)
			setinvProfit(Number(order.perProductProfit ?? 0) * Number(order.productQuantity ?? 0))
      // @ts-ignore
      const suppliesObj = JSON.parse(order.supplies)
      const aux = suppliesObj.map((sid:any) => ({
        ...supplyTypeAll.find(s => s.id === sid.id),
        productSupply: {
          quantity: sid.quantity,
          quantityPerProduct: sid.quantity / (order.productQuantity ?? 1),
          avaliableQuantity: avaliableSupplies.find(s => Number(s.supplyId) === sid.id)?.currentQuantity ?? 0
        },
      }))
      setAddSupply(aux)
    }
  },[order, supplyTypeAll, loadedAvaliableSupplies, loadingValues])

	const setFabricCutNumber = async (factoryId: number | undefined, callbac: (fcn: number) => void) => {
		if (factoryId === undefined) return;

		const res = await axios.get(`${process.env.REACT_APP_API_URL}/sequences/${factoryId}/fabricCutNumber`);
		callbac(res.data.value + 1)
	}


  return (
    <>
      {
        actionsLoading ? <OrdersLoadingDialog color={"primary"}/> :
          <Formik
            enableReinitialize={true}
            initialValues={(order as OrderModel)}
            validationSchema={OrderEditSchema}
            onSubmit={(values) => {
              if (Number(values.productQuantity ?? 0) > maxProducts) {
                console.log(addSupply)
                const missingSupplies = addSupply
                  .filter((s) => (s.productSupply?.avaliableQuantity ?? 0) < 0)
                  .map((s) => ({
                    name: s.name,
                    quantity: -(s.productSupply?.avaliableQuantity ?? 0), 
                    stock: (s.productSupply?.quantity ?? 0) + (s.productSupply?.avaliableQuantity ?? 0), 
                    unit: s.unit!.name,
                  }))
                return Swal.fire({
                  title: 'Faltan insumos',
                  html: `<style>
                          .swal-wide {
                            min-width: 560px !important;
                          }
                        </style>
                        <ul style="text-align: left">
                          ${missingSupplies.map(s => 
                            `<li>Falta${s.quantity === 1 ? '' : 'n'} ${fix2(s.quantity)} ${s.unit} de <b>${s.name}</b> ( ${fix2(s.stock)} en stock )</li>`
                          ).join('\n')}
                        </ul>`,
                  icon: 'warning',
                  customClass: {
                    popup: 'swal-wide'
                  },
                })
              }
              const vall = {
                ...values,
                companyId : userComplete.company.id,
                costSupplies,
                costManufact,
                totalCost,
                profitPercentage: profitPercentage / 100,
                suggestedPrice: totalCost / ( values.productQuantity ?? 0 ),
                productPrice: totalCost / ( values.productQuantity ?? 0 )
              }
              // if(addProduct.length === 0) return Swal.fire({
              //   title: 'Por favor ingrese al menos un producto',
              //   icon: 'warning',
              // })
              saveOrder(vall);
            }}
          >
            {({ handleSubmit, values, setFieldValue }) => (
              <>
                <Modal.Body className="cursor-default overlay overlay-block ">
                  {actionsLoading && (
                    <div className="overlay-layer bg-transparent">
                      <div className="spinner spinner-lg spinner-success" />
                    </div>
                  )}
                  <Form className="form form-label-right" onSubmit={handleSubmit}>

                    {/* Cliente y Fábrica */}
                    <div className="form-group row mb-7">
                      <div className="col-lg-6">
                        {order?.id ? (
                          <Field
                            name="reference"
                            component={Input}
                            placeholder={"Referencia"}
                            type="text"
                            disabled
                            label={intl.formatMessage({id: "ORDER.REFERENCE"})}
                          />
                        ) : (
                          <div className="mt-lg-3">
                            <SelectReferences onChange={async (opt) => {
                              if(opt === null) {
                                setFieldValue("reference", "")
                                return
                              }

                              if (opt.id === undefined) {
                                setFieldValue("reference", opt.name)
                                return
                              }

                              addSupply.forEach((s) => {
                                setAvaliableSupplies((prev) =>
                                  prev.map((a) => ({
                                    ...a,
                                    currentQuantity:
                                      s.id === a.supplyId
                                        ? a.currentQuantity + s.productSupply.quantity
                                        : a.currentQuantity,
                                  }))
                                )
                              })

                              const supp = await loadProductSupplies(opt.id, opt.factoryId!)
                              const suppIds = supp.map(s => s.id)
                              const aux2 = supplyType.filter(supply => !suppIds.includes(supply.id));
                              setSupplyType(aux2);
                              setAddSupply(supp)
                              
                              setFieldValue("supplies",JSON.stringify(supp))

                              setFieldValue("reference", opt.name)
                              ordersUIProps.setFactoryId(opt.factoryId!)
                              setFieldValue("factory",opt.factoryId)
                              setAuxFactory(opt.factoryId!)
                              setFieldValue("costManufactPerProduct", fix2(opt.costManufactPerProduct ?? 0))
                              setFieldValue("costManufact", fix2(opt.costManufact ?? 0))
                              setCostManufact(Number(fix2(opt.costManufact! * (values.productQuantity ?? 0))));
                              setFieldValue("description", opt.description)
                              setFieldValue("productQuantity", 0)

															const factory = factories.find(f => f.id === opt.factoryId);
															setFieldValue("perProductProfit", factory?.perProductProfit ?? 0);
															setinvProfit((factory?.perProductProfit ?? 0) * (values.productQuantity ?? 0));
															setFabricCutNumber(opt.factoryId, fcn => setFieldValue("fabricCutNumber", fcn));
                            }} />
                          </div>
                        )}
                        <ErrorMessage name="reference" className="text-danger" component={'span'}/>
                      </div>
                      <div className="col-lg-6 mt-lg-0 mt-3">
                          <Field
                            name="fabricCutNumber"
                            component={Input}
                            placeholder={"Número de corte"}
                            type="text"
                            disabled
                            label="Número de corte"
                          />
                      </div>
                      <div className="col-lg-6 mt-lg-0 mt-3">
                        <Field 
                          name="factory"
                          label="Fabrica"
                          disabled={order?.id ? true : false}
                          component={Select}
                          onChange={(e:any) =>{
                            ordersUIProps.setFactoryId(Number(e.target.value))
                            setFieldValue("factory",e.target.value)
                            setAuxFactory(Number(e.target.value))
														const factory = factories.find(f => f.id?.toString() === e.target.value);
														setFieldValue("perProductProfit", factory?.perProductProfit ?? 0);
														setinvProfit((factory?.perProductProfit ?? 0) * (values.productQuantity ?? 0));
														setFabricCutNumber(Number(e.target.value), fcn => setFieldValue("fabricCutNumber", fcn));
                          }}
                        >
                          <option value={0}>{"Escoja una fabrica"}</option>
                          {factories.map((f,index) => (
                            <option value={f.id} key={index}>{f.name}</option>
                          ))}
                        </Field>
                      </div>
                    </div>
                    <div className="form-group row mb-7">
                      <div className="col-lg-6">
                        <Field
                          name="productQuantity"
                          component={Input}
                          placeholder={"0"}
                          type="number"
                          min={0}
                          disabled={((order?.id && order?.status === 2)) ? true : false}
                          label={"Cantidad de producto"}
                          onInput={(e:any)=>{
                            const supplies = handleUpdateSupply(e.target.value ?? 0)
                            setFieldValue("supplies",JSON.stringify(supplies))
                            
                            setFieldValue("productQuantity",e.target.value)
                            setFieldValue("costManufact",Number((e.target.value as number) * (values.costManufactPerProduct as number)))
                            setCostManufact(Number(( e.target.value as number ) * (values.costManufactPerProduct as number)))
														setinvProfit((values?.perProductProfit ?? 0) * (e.target.value ?? 0));
                          }}
                        />
                      </div>
                      <div className="col-lg-6">
                        <Field
                          name="costManufactPerProduct"
                          component={Input}
                          placeholder={"0"}
                          // type={order?.id ? "text" : "number"}
                          type="number"
                          disabled={order?.id ? order?.status === 2 : false}
                          label={"Manufactura por producto"}
                          value={order?.id ?
                            // @ts-ignore
                            parseInt(values.costManufactPerProduct as number) :
                            values.costManufactPerProduct}
                          onChange={(e:any)=>{
                            console.log(e.target.value)
                            setFieldValue("costManufactPerProduct",e.target.value)
                            setFieldValue("costManufact",Number(e.target.value * (values.productQuantity as number)))
                            setCostManufact(Number(e.target.value * (values.productQuantity as number)))
                          }}
                        />
                      </div>
                    </div>

                    {/* Descripción del producto */}
                    <div className="form-group row my-7">
                      {/* Descripcion */}
                      <div className="col-lg-12">
                        <Field
                          name="description"
                          disabled={order?.id ? order?.status === 2 : false}
                          component={TextAreaInput}
                          placeholder={intl.formatMessage({ id: "GENERAL.DESCRIPTION"})}
                          label={intl.formatMessage({ id: "GENERAL.DESCRIPTION"})}
                        />
                      </div>
                    </div>
                      {
                        ((order?.id && order?.status === 1) || order?.id === undefined) && <>
                          <div className='separator mx-1 my-4'></div>
                          <div className="form-group row mt-4">
                            <h4>{"Agregar Insumos"}</h4>
                          </div>
                          {/*  INSUMOS Y CANTIDAD */}
                          <div className="form-group row my-2">
                            {/* INSUMOS */}
                            <div className="col-lg-4 mt-6">
                              <Field
                                name="document"
                                component={AutoCompleteInput}
                                label={"Insumos"}
                                options={supplyType}
                                value={selectedSupply}
                                onChange={(e:any, value: SupplyModel | null)=> {
                                  setSelectedSupply(value)
                                  setUnidad(value !== null ? Number(value.unitId) : null)
                                }}
                                getOptionLabel={(option: SupplyModel) => `${option.name}-${option.reference} `}
                              />
                            </div>
                            <div className="col-lg-4">
                              <Field
                                name="quantity"
                                component={Input}
                                type="number"
                                label={"Cantidad por producto"}
                              />
                              {/* @ts-ignore */}
                              <small>
                                {`Disponible: ${availableQuantity} `}
                              </small>
                              {(unidad === 1) &&
                                <small>
                                  Unidades
                                </small>
                              }
                              {(unidad === 2) &&
                                <small>
                                  Metros
                                </small>
                              }
                              {(unidad === 3) &&
                                <small>
                                  Kilogramos
                                </small>
                              }
                            </div>
                            <div className="col-lg-4 mt-6">
                                  {/* @ts-ignore */}
                                  <button className="btn btn-primary btn-elevate btn-block" onClick={()=> handleAddSupply(values, setFieldValue)}
                                    type="button"
                                  >
                                    <i className="fas fa-plus"></i>{"Agregar"}
                                  </button>
                            </div>
                          </div>
                        </>
                      }
                        <SupplyTable
                          // @ts-ignore
                          addSupply={addSupply}
                          setAddSupply={setAddSupply}
                          supplyType={supplyType}
                          setSupplyType={setSupplyType}
                          supplyTypeAll={supplyTypeAll}
                          setFieldValue={setFieldValue}
                          setSelectedSupply={setSelectedSupply}
                          noProduct={values.productQuantity}
                          id={order?.id}
                          status={order?.status}
                          avaliableSupplies={avaliableSupplies}
                          setAvaliableSupplies={setAvaliableSupplies}
                        />
                        <div className="form-group row my-7">
                          <div className="col-lg-3">
                            <Field
                              name="profitPercentage"
                              component={Input}
                              type="number"
                              label={"Porcentaje de ganancia"}
                              value={profitPercentage}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setProfitPercentage(parseFloat(e.target.value))}
                              disabled={order?.id ? order?.status === 2 : false}
                            />
                          </div>
                          <div className="col-lg-3">
                            <Field
                              name="costProfit"
                              component={Input}
                              type="text"
                              label={"Ganancias"}
                              value={DeleteZerosHelper(cop?.format(profit))}
                              disabled
                            />
                          </div>
                          <div className="col-lg-3">
                            <Field
                              name="perProductProfit"
                              component={Input}
                              type="text"
                              label={"Ganancias Inversionistas"}
                              value={DeleteZerosHelper(cop?.format(invProfit ?? 0))}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="form-group row my-7">
                          <div className="col-lg-3">
                            <Field
                              name="costSupplies"
                              disabled
                              component={Input}
                              type="text"
                              label={"Costo de Insumos"}
                              value={DeleteZerosHelper(cop?.format(costSupplies))}
                            />
                          </div>
                          <div className="col-lg-3">
                            <Field
                              name="costManufact"
                              component={Input}
                              type="text"
                              label={"Costo de Manufactura"}
                              disabled
                              value={DeleteZerosHelper(cop?.format(Number(costManufact)))}
                            />
                          </div>
                          <div className="col-lg-3">
                            <Field
                              name="totalCost"
                              disabled
                              component={Input}
                              type="text"
                              label={"Costo Total"}
                              value={DeleteZerosHelper(cop?.format(totalCost))}
                            />
                          </div>
                          <div className="col-lg-3">
                            <Field
                              name="totalCostUnit"
                              disabled
                              component={Input}
                              type="text"
                              label={"Costo Total unitario"}
                              value={DeleteZerosHelper(cop?.format(totalCost/(values.productQuantity as number)))}
                            />
                          </div>
                        </div>
                        {/* <div className='separator mx-1 my-4'></div>
                        <div className="form-group row mt-4">
                          <h4>{"Precios"}</h4>
                        </div> */}

                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    onClick={onHide}
                    className="btn btn-light btn-elevate"
                  >
                    {intl.formatMessage({ id: "BUTTON.CANCEL"})}
                  </button>
                  <> </>
                  {(!order?.id || (order?.id && order?.status === 1)) &&
                      <button
                        type="submit"
                        onClick={() => handleSubmit()}
                        className="btn btn-primary btn-elevate"
                      >
                        {intl.formatMessage({ id: "BUTTON.SAVE"})}
                      </button>
                  }
                  <></>
                  {order?.id &&
                      <button
                        type="submit"
                        onClick={() => {
                          // @ts-ignore
                          generateOrder(order, addSupply)
                          //generateSalesOrder(purchase)
                        }}
                        className = "btn btn-success btn-elevate"
                      >
                        {intl.formatMessage({id:"ORDER.GENERATE.ORDER"})}
                      </button>
                  }
                </Modal.Footer>
              </>
            )}
          </Formik>
      }
    </>
  );
}
