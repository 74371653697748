import React from 'react'
//@ts-ignore
import ReactExport from 'react-data-export';
import { Download } from "@mui/icons-material";
import { paymentsResult } from '../hooks/useLoadPayments';
import DeleteZerosHelper from '../../../../../../utils/functions/DeleteZerosHelper';
import useIntlCurrencyFormat from '../../../../../../utils/customHooks/useIntlCurrencyFormat';
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelFile = ReactExport.ExcelFile;


export interface ReporByFactory{
    data: paymentsResult | undefined
}

const ReportPaymentsByClient:React.FC<ReporByFactory> = ({
    data
}) => {
    const {cop} = useIntlCurrencyFormat();

    const aux1 = data?.payments.map((d)=>{
        return [
            {value: ''},
            {value: d.paymentDate}, //emp.fecha
            {value: d.reference},     //emp.factura
            {value: d.description === null ? " " : d.description},
            {value: d.name},  //emp.fabrica
            {value: d.value}, //emp.deuda
        ]
    })

    const multiDataSet = [
        {
            columns: [
                {title: "", style: {font: {sz: "12", bold: true},fill: {patternType: "solid", fgColor: {rgb: "FFFFFF"}}}},//pixels width 
                {title: "TOTAL ABONOS", style: {font: {sz: "12", bold: true},fill: {patternType: "solid", fgColor: {rgb: "DDDDDD"}}}}, 
                {title: `${DeleteZerosHelper(cop?.format(data?.total ? data?.total : 0 ))}`, style: {font: {sz: "12", bold: true},fill: {patternType: "solid", fgColor: {rgb: "DDDDDD"}}}},
                {title: "", style: {font: {sz: "12", bold: true},fill: {patternType: "solid", fgColor: {rgb: "FFFFFF"}}}},
                {title: "", style: {font: {sz: "12", bold: true},fill: {patternType: "solid", fgColor: {rgb: "FFFFFF"}}}},
                {title: "", style: {font: {sz: "12", bold: true},fill: {patternType: "solid", fgColor: {rgb: "FFFFFF"}}}},
            ],
            data: aux1
        }
    ];


    return(
        <>
            <ExcelFile 
                element={
                    <button
                        type="button"
                        className="btn btn-success btn-sm mx-1"
                    >
                        Reporte total abonos <Download />
                    </button>
                }
                filename={`Abonos`}
            >
                <ExcelSheet dataSet={multiDataSet} name={'Abonos'} />
            </ExcelFile>
        </>
    )
}


export default ReportPaymentsByClient;