//Librerias
import React from 'react'
import {Route, useHistory} from 'react-router-dom'

//Componentes
import {ProfitsInvestorsUIProvider} from './ProfitsInvestorsUIContext'
import {ProfitsInvestorsCard} from './ProfitsInvestorsCard'

export const ProfitsInvestorsPage = () => {
  const profitsUIEvents = {}

  return (
    <ProfitsInvestorsUIProvider profitsUIEvents={profitsUIEvents}>
      <ProfitsInvestorsCard />
    </ProfitsInvestorsUIProvider>
  )
}
