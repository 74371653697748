import React, {useState, useEffect} from 'react'
import {ProductModel} from '../../../../admin/pages/products/models/index'
import {AvailableProduct} from '../models/index'
import useIntlCurrencyFormat from '../../../../../../utils/customHooks/useIntlCurrencyFormat'
import DeleteZerosHelper from '../../../../../../utils/functions/DeleteZerosHelper'
import SVG from 'react-inlinesvg'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers/AssetHelpers'
import CurrencyInput from 'react-currency-input-field'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

interface Product extends AvailableProduct {
  quantity?: string
}

interface ProductRefundTableProps {
  id: number
  products: Product[]
  setProductProperty: (id: number, property: keyof Product, value?: number | string) => void
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  onRestoreAll: (id: number) => void
  onRemoveAll: (id: number) => void
}
const ProductRefundTable: React.FC<ProductRefundTableProps> = ({
  id,
  products,
  setProductProperty,
  setFieldValue,
  onRestoreAll,
  onRemoveAll,
}) => {
  const {cop} = useIntlCurrencyFormat()
  const groupedByReferenceProducts: Product[] = products.reduce(
    (prev: Product[], curr: Product) => {
      const product = prev.find((p) => p.productName === curr.productName);
      if (product === undefined) return [...prev, {...curr}];
      product.availableQuantity += curr.availableQuantity;
      product.requestedQuantity += curr.requestedQuantity;
      product.restQuantity += curr.restQuantity;
      if (product.quantity === undefined || curr.quantity === undefined) {
        product.quantity = undefined;
      } else {
        product.quantity = (Number(product.quantity) + Number(curr.quantity)).toString();
      }

      return prev
    },
    []
  )
  return (
    <>
      <h1>Productos </h1>
      <div className='table-responsive'>
        <table className='styled-table-info'>
          <thead>
            <tr>
              <th className='text-center'>Producto</th>
              <th className='text-center'>Precio</th>
              <th className='text-center'>Cantidad disponible</th>
              <th className='text-center'>Cantidad requerida</th>
              <th className='text-center'>Cantidad restante</th>
              <th className='text-center'>Total</th>
              <th className='text-center' colSpan={2}>
                Acción
              </th>
            </tr>
          </thead>
          <tbody>
            {groupedByReferenceProducts.map((item) => (
              <tr key={item.id}>
                {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
                <td align='center'>{item.productName}</td>
                <td align='center'>{DeleteZerosHelper(cop?.format(Number(item.price)))}</td>
                <td align='center'>{item.availableQuantity}</td>
                <td align='center'>
                  {/* <input
                    className='form-control'
                    type='number'
                    name={`requested_quantity_${item.id}`}
                    value={item.requestedQuantity}
                    id={`requested_quantity_${item.id}`}
                    max={item.requestedQuantity}
                    onChange={(e) =>
                      setProductProperty(item.id, 'requestedQuantity', e.target.value)
                    }
                    min={0}
                  /> */}
                  <CurrencyInput
                    disableGroupSeparators
                    className='form-control'
                    id={`requested_quantity_${item.id}`}
                    name={`requested_quantity_${item.id}`}
                    allowDecimals
                    allowNegativeValue={false}
                    value={item.quantity}
                    onValueChange={(val, _name, values) => {
                      let totalRequestedQuantity = values?.float ?? 0;
                      products
                        .filter((p) => p.productName === item.productName)
                        .sort((a, b) => Number(a.id) - Number(b.id))
                        .forEach((p) => {
                          const quantity = totalRequestedQuantity > p.availableQuantity
                            ? p.availableQuantity
                            : totalRequestedQuantity;

                          totalRequestedQuantity -= quantity;

                          setProductProperty(
                            p.id,
                            'quantity',
                            val && quantity.toString()
                          )
    
                          setProductProperty(
                            p.id,
                            'requestedQuantity',
                            quantity
                          )
                        })
                    }}
                  />
                </td>
                <td align='center'>{item.availableQuantity - item.requestedQuantity}</td>
                <td align='center'>
                  {DeleteZerosHelper(cop?.format(Number(item.requestedQuantity * item.price)))}
                </td>
                <td align='center'>
                  <OverlayTrigger
                    placement='top'
                    delay={{
                      show: 500,
                      hide: 200,
                    }}
                    overlay={<Tooltip id='tooltip'>Eliminar</Tooltip>}
                  >
                    <button
                      type='button'
                      className='btn btn-sm btn-danger'
                      onClick={() => products.filter(p => p.productName === item.productName).forEach(p => onRemoveAll(p.id))}
                    >
                      <span className='svg-icon svg-icon-md svg-icon-primary'>
                        <SVG src={toAbsoluteUrl('/media/icons/duotune/arrows/arr018.svg')} />
                      </span>
                    </button>
                  </OverlayTrigger>
                </td>
                <td align='center'>
                  <OverlayTrigger
                    placement='top'
                    delay={{
                      show: 500,
                      hide: 200,
                    }}
                    overlay={<Tooltip id='tooltip'>Restaurar</Tooltip>}
                  >
                    <button
                      type='button'
                      className='btn btn-sm btn-success'
                      onClick={() => products.filter(p => p.productName === item.productName).forEach(p => onRestoreAll(p.id))}
                    >
                      <span className='svg-icon svg-icon-md svg-icon-primary'>
                        <SVG src={toAbsoluteUrl('/media/icons/duotune/arrows/arr017.svg')} />
                      </span>
                    </button>
                  </OverlayTrigger>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={6}></td>
              <td>
                <OverlayTrigger
                  placement='top'
                  delay={{
                    show: 500,
                    hide: 200,
                  }}
                  overlay={<Tooltip id='tooltip'>Eliminar todos</Tooltip>}
                >
                  <button
                    type='button'
                    className='btn btn-sm btn-danger'
                    onClick={() => products.forEach((item) => onRemoveAll(item.id))}
                  >
                    <span className='svg-icon svg-icon-md svg-icon-primary'>
                      <SVG src={toAbsoluteUrl('/media/icons/duotune/arrows/arr018.svg')} />
                    </span>
                  </button>
                </OverlayTrigger>
              </td>
              <td>
                <OverlayTrigger
                  placement='top'
                  delay={{
                    show: 500,
                    hide: 200,
                  }}
                  overlay={<Tooltip id='tooltip'>Restaurar todos</Tooltip>}
                >
                  <button
                    type='button'
                    className='btn btn-sm btn-success'
                    onClick={() => products.forEach((item) => onRestoreAll(item.id))}
                  >
                    <span className='svg-icon svg-icon-md svg-icon-primary'>
                      <SVG src={toAbsoluteUrl('/media/icons/duotune/arrows/arr017.svg')} />
                    </span>
                  </button>
                </OverlayTrigger>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  )
}
export default ProductRefundTable
