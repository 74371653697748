import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import axios from 'axios'
import useDebounceFnc from '../../../../../../utils/customHooks/useDebounceFnc'
import {CircularProgress} from '@mui/material'

interface Factory {
  id?: number
  name: string
  description?: string
  costManufact?: number
  costManufactPerProduct?: number
  factoryId?: number
  createdAt?: string
}

interface SelectReferenceProps {
  onChange: (opt: Factory | null) => void
}

const SelectReferences: React.FC<SelectReferenceProps> = ({onChange}) => {
  const [value, setValue] = React.useState<Factory | null>(null)
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState<readonly Factory[]>([])
  const [loading, setLoading] = React.useState(false)

  const fetch = useDebounceFnc(
    React.useCallback(async (input: string) => {
      try {
        if (input === '') {
          setOptions([])
          return
        }
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/product/findByReference/${input}`
        )
        const newOpt = {
          id: 0,
          name: input,
          costManufact: undefined,
          costManufactPerProduct: undefined,
          description: undefined,
          createdAt: undefined,
          factoryId: undefined,
        }
        if (res.data.find((opt: Factory) => opt.name === input) !== undefined) {
          setOptions(res.data)
          return
        }
        setOptions([...res.data, newOpt])
      } catch (error) {
        console.log(error)
        setOptions([])
      } finally {
        setLoading(false)
      }
    }, []),
    500
  )

  React.useEffect(() => {
    fetch(inputValue)
    return () => {
      setLoading(true)
    }
  }, [inputValue])

  return (
    <Autocomplete
      // sx={{ width: 300 }}
      getOptionLabel={(option) =>
        option.id === 0 ? `Crear referencia: ${option.name}` : option.name
      }
      isOptionEqualToValue={(opt, val) => opt.id === val.id}
      loading={loading}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText='No hay referencias'
      onChange={(event: any, val: Factory | null) => {
        // setOptions(newValue ? [newValue, ...options] : options);
        const newValue = val
        if (val !== null && val.id === 0) {
          //@ts-ignore
          newValue.id = undefined
        }
        setValue(newValue)
        onChange(newValue)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Referencia'
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export default SelectReferences
