import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
    // getSelectRow,
    getHandlerTableChange,
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    sortCaret,
    headerSortingClasses,
} from "../../../../../../_metronic/helpers";
import * as uiHelpers from '../WalletUIHelpers';
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/partials/control";
import { useWalletUIContext, WalletUIContextModel } from "../WalletUIContext";
import { useIntl } from 'react-intl';
import ReactTooltip from "react-tooltip";



export function WalletTable(){
    const walletUIContext = (useWalletUIContext() as WalletUIContextModel);
    const walletUIProps = useMemo(() => {
        return {
            tableLoading: walletUIContext.tableLoading,
            valuesTable: walletUIContext.valuesTable,
            openEditWalletDialog: walletUIContext.openEditWalletDialog,
            queryParams: walletUIContext.queryParams,
            setQueryParams: walletUIContext.setQueryParams,
        }
    },[walletUIContext]);

    const { totalCount, data:{entities} } = walletUIProps.valuesTable;
    const intl = useIntl();
    const columns = [
        {
            dataField: "name",
            text: "Cliente/Fábrica",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "totalDebts",
            // text: intl.formatMessage({ id: "GENERAL.NAME"}),
            text: "Total Deudas",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "totalPayments",
            // text: intl.formatMessage({ id: "GENERAL.NAME"}),
            text: "Total Abonos",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "balance",
            // text: intl.formatMessage({ id: "GENERAL.NAME"}),
            text: "Saldo pendiente",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "action",
            // text: intl.formatMessage({ id: "FORMULARIES.ACTIONS"}),
            text: 'Acciones',
            formatter: columnFormatters.ActionsColumnFormatter,
            formatExtraData: {
              openEditWalletDialog: walletUIProps.openEditWalletDialog,
              intl,
            },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
              minWidth: "100px",
            },
        },
    ]

    // Table pagination properties
    const paginationOptions = {
        custom: true,
        totalSize: totalCount,
        sizePerPageList: [...uiHelpers.sizePerPageList, {text: `${totalCount}`, value: totalCount}],
        sizePerPage: walletUIProps.queryParams.pageSize,
        page: walletUIProps.queryParams.pageNumber,
    };

    useEffect(() => {
        ReactTooltip.rebuild();
    })
    return(
        <>
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => {
                return (
                    <Pagination
                    isLoading={walletUIProps.tableLoading}
                    paginationProps={paginationProps}
                    >
                    <BootstrapTable
                        wrapperClasses="table-responsive"
                        bordered={false}
                        classes="table table-head-custom table-vertical-center overflow-hidden"
                        bootstrap4
                        remote
                        //@ts-ignore
                        keyField="id"
                        //@ts-ignore
                        data={entities}
                        //@ts-ignore
                        columns={columns}
                        //@ts-ignore
                        defaultSorted={uiHelpers.defaultSorted}
                        onTableChange={getHandlerTableChange(
                            walletUIProps.setQueryParams
                        )}
                        {...(paginationTableProps)}
                    >
                        <PleaseWaitMessage entities={entities} />
                        <NoRecordsFoundMessage entities={entities} />
                    </BootstrapTable>
                    </Pagination>
                );
                }}
            </PaginationProvider>
        </>
    )
}


