import React from 'react'
//@ts-ignore
import ReactExport from 'react-data-export';
import { Download } from "@mui/icons-material";
import {  clientBillings } from '../WalletUIContext';
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelFile = ReactExport.ExcelFile;

export interface ReporByFactory{
    data: clientBillings[],
    total: number
}

const ReportByFactory:React.FC<ReporByFactory> = ({
    data,
    total
}) => {

    const datos = [];
    const aux1 = data.map((d)=>{
        return [
            {value: ''},
            {value: d.billingDate}, //emp.fecha
            {value: d.reference},     //emp.factura
            {value: d.name},  //emp.fabrica
            {value: d.value}, //emp.deuda
        ]
    })

    for(let i=0;i<aux1.length;i++){
        let tempArray1;
        tempArray1 = aux1[i]
        datos.push(tempArray1);
    }

    const multiDataSet = [
        {
            columns: [
                {title: "", style: {font: {sz: "12", bold: true},fill: {patternType: "solid", fgColor: {rgb: "FFFFFF"}}}},//pixels width
                {title: "TOTAL DEUDA", style: {font: {sz: "12", bold: true},fill: {patternType: "solid", fgColor: {rgb: "DDDDDD"}}}},//char width
                {title: `${total}`, style: {font: {sz: "12", bold: true},fill: {patternType: "solid", fgColor: {rgb: "DDDDDD"}}}},
                {title: "", style: {font: {sz: "12", bold: true},fill: {patternType: "solid", fgColor: {rgb: "FFFFFF"}}}},
                {title: "", style: {font: {sz: "12", bold: true},fill: {patternType: "solid", fgColor: {rgb: "FFFFFF"}}}},
            ],
            data: datos
        }
    ];


    return(
        <>
            <ExcelFile
                element={
                    <button
                        type="button"
                        className="btn btn-success btn-sm mx-1"
                    >
                        Reporte de la Fábrica <Download />
                    </button>
                }
                filename={`Deudas${data[0]?.name}`}
            >
                <ExcelSheet dataSet={multiDataSet} name={data[0]?.name} />
            </ExcelFile>
        </>
    )
}


export default ReportByFactory;
