import React from 'react'
import ExelJS, {TableColumnProperties} from 'exceljs'
import {BillingProfits} from '../ProfitsInvestorsUIContext'

const SHEET_NAME = 'Ganancias Inversionistas'
const COLUMNS: TableColumnProperties[] = [
  {name: 'Fecha', filterButton: true, totalsRowLabel: 'Totales:'},
  {name: 'Referencia'},
  {name: 'Fábrica', filterButton: true},
  {name: 'Cliente', filterButton: true},
  {name: 'Productos', filterButton: true},
  {name: 'Cantidad', totalsRowFunction: 'sum'},
  {name: 'Valor Facturado', totalsRowFunction: 'sum'},
  {name: 'Ganancias', totalsRowFunction: 'sum'},
]

const useGenerateExel = (getData: () => Promise<BillingProfits[]>) => {
  const createExel = async () => {
    const wb = new ExelJS.Workbook()
    const sheet = wb.addWorksheet(SHEET_NAME, {
      views: [{state: 'frozen', ySplit: 1}],
    })

    const data = await getData()

    sheet.getColumn('A').width = 11
    sheet.getColumn('B').width = 12
    sheet.getColumn('C').width = 15.5
    sheet.getColumn('D').width = 15.5
    sheet.getColumn('E').width = 30
    sheet.getColumn('G').width = 20
    sheet.getColumn('H').width = 20
    sheet.getColumn('G').style.numFmt = '$#,##0.00;[Red]-$#,##0.00'
    sheet.getColumn('H').style.numFmt = '$#,##0.00;[Red]-$#,##0.00'

    sheet.addTable({
      name: 'Ganancias',
      ref: 'A1',
      headerRow: true,
      totalsRow: true,
      columns: COLUMNS,
      style: {
        theme: 'TableStyleLight1',
        showRowStripes: true,
      },
      rows: data.map((d) => [
        new Date(d.createdAt),
        d.reference,
        d.factory,
        d.clientName,
        d.products,
        Number(d.quantity),
        Number(d.price),
        Number(Number(d.profit).toFixed(2)),
      ]),
    })

    return wb.xlsx.writeBuffer()
  }

  const download = async () => {
    try {
      const buffer = await createExel()
      const content = new Blob([buffer])

      const uri = window.URL.createObjectURL(content)
      const link = document.createElement('a')

      link.setAttribute('href', uri)
      link.setAttribute('download', 'Ganancias_Inversionistas.xlsx')

      link.click()
    } catch (error) {
      console.log(error)
    }
  }

  return download
}

export default useGenerateExel
