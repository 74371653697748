import React from "react";
import { Route, useHistory } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
// import { LoansLoadingDialog } from "./loans-loading-dialog/LoansLoadingDialog";
import { LoanEditDialog } from "./loan-edit-dialog/LoanEditDialog";
import { LoanDeleteDialog } from "./loan-delete-dialog/LoanDeleteDialog";
// import { LoansDeleteDialog } from "./loans-delete-dialog/LoansDeleteDialog";
// import { LoansFetchDialog } from "./loans-fetch-dialog/LoansFetchDialog";
// import { LoansUpdateStateDialog } from "./loans-update-status-dialog/LoansUpdateStateDialog";
import { LoansUIProvider } from "./LoansUIContext";
import { LoansCard } from "./LoansCard";


export const LoansPage = () =>  {
  const history = useHistory();
  const loansUIEvents = {
    newLoanButtonClick: () => {
      history.push("/processes/loans/new");
    },
    openEditLoanDialog: (id:number) => {
      history.push(`/processes/loans/${id}/edit`);
    },
    openDeleteLoanDialog: (id:number, fullDelete: number) => {
      history.push(`/processes/loans/${id}/${fullDelete}/delete`);
    },
  }

  return (
    <LoansUIProvider loansUIEvents={loansUIEvents}>
      {/* <LoansLoadingDialog /> */}
      <Route path="/processes/loans/new">
        {({ history, match }) => (
          <LoanEditDialog
            show={match !== null}
            onHide={() => {
              history.push("/processes/loans");
            }}
          />
        )}
      </Route>
      <Route path="/processes/loans/:id/edit">
        {({ history, match }) => {
          return (
            <LoanEditDialog
              show={match !== null}
              id={match && (Number(match.params.id) as number)}
              onHide={() => {
                history.push("/processes/loans");
              }}
            />
          )
        }}
      </Route>
      <Route path="/processes/loans/:id/:fullDelete/delete">
        {({ history, match }) => (
          <LoanDeleteDialog
            show={match !== null}
            id={match && Number(match.params.id)}
            fullDelete={match && Number(match.params.fullDelete)}
            onHide={() => {
              history.push("/processes/loans");
            }}
          />
        )}
      </Route>
      <LoansCard />
    </LoansUIProvider>
  );
}




