import React, { useMemo, useState } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useBillingsUIContext, BillingsUIContextModel } from '../BillingsUIContext';
import { useIntl } from "react-intl";
import useDebounceFnc from "../../../../../../utils/customHooks/useDebounceFnc";
import DatePicker from 'react-datepicker'
import moment from 'moment'

interface filterInterface {
  status: number | undefined;
  type: number | undefined;
  clientId: number | undefined;
  billingReference?: string;
  orderReference?: string;
  products?: string;
  factoryId?: number;
	end?: string;
	start?: string;
}
interface filterValues {
  clientId: string;
  status: string;
  type: string;
  factoryId: number;
  billingReference: string;
  products: string;
	end: string;
	start: string;
}
const prepareFilter = (queryParams: any, values : filterValues) => {
  const { status, clientId, billingReference, products, factoryId, end, start } = values;

  const newQueryParams = { ...queryParams };
  const filter : filterInterface = {
    clientId: undefined,
    status: undefined,
    type: undefined,
    factoryId: undefined,
    billingReference: undefined,
    orderReference: undefined,
		end: undefined,
		start: undefined,
  };
  // Filter by clientId
  filter.clientId = clientId !== "" ? +clientId : undefined;


	if (end) {
		filter.end = end;
	}

	if (start) {
		filter.start = start;
	}

  if (billingReference.trim() !== "") {
    filter.billingReference = billingReference.trim();
		filter.start = undefined;
		filter.end = undefined;
  }
  if (products) {
    filter.products = products;
  }

  if (factoryId) {
    filter.factoryId = factoryId
  }


  newQueryParams.filter = filter;
  return newQueryParams;
};

export interface BillingsFilterProp {
  listLoading: boolean
}
export const BillingsFilter: React.FC<{}>=() => {
  // Billings UI Context
  const intl = useIntl();
  const billingsUIContext = (useBillingsUIContext() as BillingsUIContextModel);
  const billingsUIProps = useMemo(() => {
    return {
      queryParams: billingsUIContext.queryParams,
      setQueryParams: billingsUIContext.setQueryParams,
      clients: billingsUIContext.clients,
      factories: billingsUIContext.factories
    };
  }, [billingsUIContext]);
  const [start, setStart] = useState<Date | null>(moment().add(-1, 'M').toDate())
  const [end, setEnd] = useState<Date | null>(moment().toDate())

  // queryParams, setQueryParams,
  const applyFilter = useDebounceFnc((values:any) => {
    const newQueryParams = prepareFilter(billingsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, billingsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      billingsUIProps.setQueryParams(newQueryParams);
    }
  }, 400);

  return (
    <>
      <Formik
        initialValues={{
          clientId: "",
          factoryId: "",
          status: "",
          type: "",
          billingReference: "",
          products: "",
					start: moment().add(-1, 'M').format("YYYY-MM-DD"),
					end: moment().format("YYYY-MM-DD"),
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              {/* FILTRAR POR FABRICA */}
              <div className="col-lg-3">
                <select
                  className="form-control"
                  name="factoryId"
                  placeholder={intl.formatMessage({id:"LABELS.FILTER.SEARCH_BY_FACTORY"})}
                  onChange={(e) => {
                    setFieldValue("factoryId", e.target.value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.factoryId}
                >
                  <option value={""}>{intl.formatMessage({id:"LABELS.FILTER.CHOOSE_FACTORY"})}</option>
                  {billingsUIProps.factories.map((f:any,index:number) => (
                    <option value={f.id} key={index}>{f.name}</option>
                  ))}
                </select>
                <small className="form-text text-muted">
                  {intl.formatMessage({id:"LABELS.FILTER.SEARCH_BY_FACTORY"})}
                </small>
              </div>
             {/* FILTRAR POR CLIENTE */}
             <div className="col-lg-3">
                <select
                  className="form-control"
                  name="clientId"
                  placeholder={intl.formatMessage({id:"LABELS.FILTER.SEARCH_BY_CLIENT"})}
                  onChange={(e) => {
                    setFieldValue("clientId", e.target.value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.clientId}
                >
                  <option value={""}>{intl.formatMessage({id:"LABELS.FILTER.CHOOSE_CLIENT"})}</option>
                  {billingsUIProps.clients.map((c:any,index:number) => (
                    <option value={c.id} key={index}>{c.name}</option>
                  ))}
                </select>
                <small className="form-text text-muted">
                  {intl.formatMessage({id:"LABELS.FILTER.SEARCH_BY_CLIENT"})}
                </small>
              </div>




              {/* FILTRAR POR REFERNCIA DE FACTURA */}
              <div className="col-lg-3">
                <input
                  type="text"
                  className="form-control"
                  name="billingReference"
                  placeholder={intl.formatMessage({id: "LABELS.FILTER.SEARCH"})}
                  onBlur={handleBlur}
                  value={values.billingReference}
                  onChange={(e) => {
                    setFieldValue("billingReference", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                Filtrar por referencia
                </small>
              </div>
              
              
              <div className="col-lg-3">
                <input
                  type="text"
                  className="form-control"
                  name="products"
                  placeholder={intl.formatMessage({id: "LABELS.FILTER.SEARCH"})}
                  onBlur={handleBlur}
                  value={values.products}
                  onChange={(e) => {
                    setFieldValue("products", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                Filtrar por productos
                </small>
              </div>

              {/* FILTRAR POR START_DATE */}
              <div className='col-lg-3 mb-2'>
                <DatePicker
                  className='form-control'
                  selected={start}
                  isClearable
									disabled={values.billingReference.trim() !== ""}
                  onChange={(date) => {
                    setStart(date)
                    if (date === null) {
                      setFieldValue('start', '')
                      handleSubmit()
                      return
                    }
                    setFieldValue(
                      'start',
                      moment(date).format('YYYY-MM-DD')
                    )
                    handleSubmit()
                  }}
                />
                <small className='form-text text-muted'>Filtrar por fecha de inicio</small>
              </div>

              {/* FILTRAR POR END_DATE */}
              <div className='col-lg-3 mb-2'>
                <DatePicker
                  className='form-control'
                  selected={end}
                  isClearable
									disabled={values.billingReference.trim() !== ""}
                  onChange={(date) => {
                    setEnd(date)
                    if (date === null) {
                      setFieldValue('end', '');
                      handleSubmit()
                      return
                    }
                    setFieldValue(
                      'end',
                      moment(date).format('YYYY-MM-DD')
                    )
                    handleSubmit()
                  }}
                />
                <small className='form-text text-muted'>Filtrar por fecha final</small>
              </div>

            </div>
          </form>
        )}
      </Formik>
    </>
  );
}



