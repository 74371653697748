import React, { useEffect, useMemo } from 'react';
import { Modal } from "react-bootstrap";
// import axios from "axios";

//Contexto
import { useWalletUIContext, WalletUIContextModel } from '../WalletUIContext';

//Modal
import { WalletEditDialogHeader } from './WalletEditDialogHeader';
import { WalletEditForm } from './WalletEditForm'; 


//Interfaces
export interface WalletEditDialogProps{
    id?: string | null;
    show?: boolean;
    onHide?: () => void;
}


export const WalletEditDialog: React.FC<WalletEditDialogProps> =({id,show,onHide}) => {

    const walletUIContext = (useWalletUIContext() as WalletUIContextModel);
    const walletUIProps = useMemo(() => {
        return{
            //loadBalance: walletUIContext.loadBalance,
            getBalanceByClient: walletUIContext.getBalanceByClient,
            getBalanceByFactory: walletUIContext.getBalanceByFactory,
            selectedClientBal: walletUIContext.selectedClientBal,
            setSelectedClientBal: walletUIContext.setSelectedClientBal,
            setSelectedIdWallet: walletUIContext.setSelectedIdWallet,
            setSelectedCreditor: walletUIContext.setSelectedCreditor,
            setClientBillingTable: walletUIContext.setClientBillingTable,

        }
    },[
        walletUIContext.getBalanceByClient,
        walletUIContext.getBalanceByFactory,
        walletUIContext.selectedClientBal,
        walletUIContext.setSelectedClientBal,
        walletUIContext.setSelectedIdWallet,
        walletUIContext.setSelectedCreditor,
        walletUIContext.setClientBillingTable,
    ]);

    useEffect(() => {
        if(id){
            if(id.charAt(id.length-1) === 'c'){
                walletUIProps.getBalanceByClient(Number(id.substring(0,id.length-1)))
            }else{
                walletUIProps.getBalanceByFactory(Number(id.substring(0,id.length-1)))
            }
        }
    },[id]);

    return (
        <Modal
            size="xl"
            show={show}
            onHide={()=>{
                walletUIProps.setSelectedClientBal(undefined);
                walletUIProps.setSelectedIdWallet(undefined);
                walletUIProps.setSelectedCreditor(0);
                walletUIProps.setClientBillingTable(undefined);
                (onHide as () => void)();
            }}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <WalletEditDialogHeader/>
            <WalletEditForm
                data={walletUIProps.selectedClientBal ?? []}
                tipo={id}
            />
        </Modal>
    )
} 