// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
// import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import * as actions from "../../../_redux/purchases/purchasesActions";
import {
  // getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/helpers";
import * as uiHelpers from "../PurchasesUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/partials/control";
import { usePurchasesUIContext, PurchasesUIContextModel } from '../PurchasesUIContext';
import { useIntl } from "react-intl";
import ReactTooltip from 'react-tooltip';
import { Col, Row } from "react-bootstrap";
import DeleteZeros from "../../../../../../utils/functions/DeleteZerosHelper";
import useIntlCurrencyFormat from "../../../../../../utils/customHooks/useIntlCurrencyFormat";
import moment from 'moment';

export function PurchasesTable() {
  // const { company } = useSelector((state) => state.auth);
  // Purchases UI Context
  const purchasesUIContext = (usePurchasesUIContext() as PurchasesUIContextModel);
  const purchasesUIProps = useMemo(() => {
    return {
      ids: purchasesUIContext.ids,
      setIds: purchasesUIContext.setIds,
      queryParams: purchasesUIContext.queryParams,
      setQueryParams: purchasesUIContext.setQueryParams,
      openEditPurchaseDialog: purchasesUIContext.openEditPurchaseDialog,
      openDeletePurchaseDialog: purchasesUIContext.openDeletePurchaseDialog,
      openUpdateStatusIsPaid: purchasesUIContext.openUpdateStatusIsPaid,
      setIsLoading: purchasesUIContext.setIsLoading,
      loadingPurchases: purchasesUIContext.loadPurchases,
      tableLoading: purchasesUIContext.tableLoading,
      valuesTable: purchasesUIContext.valuesTable,
      openPurchasePaymentDialog: purchasesUIContext.openPurchasePaymentDialog,
    };
  }, [purchasesUIContext]);

  // Getting curret state of purchases list from store (Redux)
  // const { currentState } = useSelector(
  //   (state) => ({ currentState: state.purchases }),
  //   shallowEqual
  // );
  const { totalCount, data:{entities} } = purchasesUIProps.valuesTable;
  const {cop} = useIntlCurrencyFormat()
  // Purchases Redux state
  // Table columns
  const intl = useIntl();
  const columns = [
    {
      dataField: "reference",
      text: "Referencia",
      // text: intl.formatMessage({ id: "GENERAL.NAME"}),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "supplies",
      text: "Insumos",
      // text: intl.formatMessage({ id: "GENERAL.NAME"}),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "factory",
      text: "Fabrica",
      // text: intl.formatMessage({ id: "GENERAL.NAME"}),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "subTotal",
      text: "Subtotal",
      // text: intl.formatMessage({ id: "GENERAL.DOCUMENT"}),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent: any, row: any, rowIndex: any) => DeleteZeros(cop?.format(cellContent))
    },
    {
      dataField: "discount",
      text: "Descuento",
      // text: intl.formatMessage({ id: "GENERAL.DOCUMENT"}),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent: any, row: any, rowIndex: any) => DeleteZeros(cop?.format(cellContent))
    },
    {
      dataField: "totalPurchase",
      text: "Total",
      // text: intl.formatMessage({ id: "GENERAL.DOCUMENT"}),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent: any, row: any, rowIndex: any) => DeleteZeros(cop?.format(cellContent))
    },
    {
      dataField: "purchaseDate",
      text: "Fecha",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cellContent: any, row: any, rowIndex: any,)=> moment(row.purchaseDate).format('LL')
    },
    {
      dataField: "isPaid",
      text: intl.formatMessage({ id: "GENERAL.STATUS"}),
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.StatusColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "action",
      // text: intl.formatMessage({ id: "FORMULARIES.ACTIONS"}),
      text: 'Acciones',
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditPurchaseDialog: purchasesUIProps.openEditPurchaseDialog,
        openDeletePurchaseDialog: purchasesUIProps.openDeletePurchaseDialog,
        openUpdateStatusIsPaid: purchasesUIProps.openUpdateStatusIsPaid,
        openPurchasePaymentDialog: purchasesUIProps.openPurchasePaymentDialog,
        setIsLoading: purchasesUIProps.setIsLoading,
        loadPurchases: purchasesUIProps.loadingPurchases,
        intl,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: [...uiHelpers.sizePerPageList, {text: `${totalCount}`, value: totalCount}],
    sizePerPage: purchasesUIProps.queryParams.pageSize,
    page: purchasesUIProps.queryParams.pageNumber,
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  })

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={purchasesUIProps.tableLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                //@ts-ignore
                keyField="id"
                //@ts-ignore
                data={entities}
                //@ts-ignore
                columns={columns}
                //@ts-ignore
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  purchasesUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: purchasesUIProps.ids,
                //   setIds: purchasesUIProps.setIds,
                // })}
                {...(paginationTableProps)}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
      <hr style={{background: '#bcc3d7', marginInline: '-2.25rem', height: '1px', marginBottom: '2.25rem'}} />
      <h3>Sub Totales:</h3>
      <Row>
        <Col md='6'>
          <strong>Sub Total: </strong>
          {DeleteZeros(cop?.format(entities.reduce((p, c) => p + Number(c.subTotal), 0)))}
        </Col>
        <Col md='6'>
          <strong>Descuento: </strong>
          {DeleteZeros(cop?.format(entities.reduce((p, c) => p + Number(c.discount), 0)))}
        </Col>
        <Col md='6'>
          <strong>Total: </strong>
          {DeleteZeros(cop?.format(entities.reduce((p, c) => p + Number(c.totalPurchase), 0)))}
        </Col>
      </Row>
    </>
  );
}



