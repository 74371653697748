import React from "react";
import { LoadingDialog } from "../../../../../../_metronic/partials/control";

export interface BillingsLoadingDialogProps {
  color?: string
}
export const BillingsLoadingDialog: React.FC<BillingsLoadingDialogProps> = ({color}) => {
  return <LoadingDialog color={color} text="Cargando ..." />;
}



