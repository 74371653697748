import React from "react";
import { Route, useHistory } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
// import { PurchasesLoadingDialog } from "./purchases-loading-dialog/PurchasesLoadingDialog";
import { PurchaseEditDialog } from "./purchase-edit-dialog/PurchaseEditDialog";
import { PurchaseDeleteDialog } from "./purchase-delete-dialog/PurchaseDeleteDialog";
import { PurchasePaymentDialog } from "./purchase-payment-dialog/PurchasePaymentDialog";
// import { PurchasesDeleteDialog } from "./purchases-delete-dialog/PurchasesDeleteDialog";
// import { PurchasesFetchDialog } from "./purchases-fetch-dialog/PurchasesFetchDialog";
// import { PurchasesUpdateStateDialog } from "./purchases-update-status-dialog/PurchasesUpdateStateDialog";
import { PurchasesUIProvider } from "./PurchasesUIContext";
import { PurchasesCard } from "./PurchasesCard";
import axios from 'axios';
import Swal from 'sweetalert2';


export const PurchasesPage = () =>  {
  const history = useHistory();
  const purchasesUIEvents = {
    newPurchaseButtonClick: () => {
      history.push("/processes/purchases/new");
    },
    openEditPurchaseDialog: (id:number) => {
      history.push(`/processes/purchases/${id}/edit`);
    },
    openDeletePurchaseDialog: async (
      id:number,
      fullDelete: number,
      totalPayment:number,
      factoryId: number,
    ) => {
      try {
        const { data: inventory } = await axios.get(`${process.env.REACT_APP_API_URL}/factory/getSupplyInventory/${factoryId}`);
        const { data: supplies } = await axios.get(`${process.env.REACT_APP_API_URL}/purchaseSupply/getBySupply/${id}`);
        let result = inventory.filter((inv: any) =>
          supplies.some((sup: any) =>
            inv.supplyId === sup.supplyId && inv.currentQuantity - sup.quantity < 0
          )
        );
        console.log('inventory', inventory);
        console.log('supplies', supplies);
        console.log('result', result);
        if (totalPayment > 0) {
          Swal.fire({
            icon: 'warning',
            title: `No se puede eliminar orden de compra porque existe al menos un abono asociado`,
          })
        } else if (result.length > 0) {
          Swal.fire({
            icon: 'warning',
            title: `No se puede eliminar orden de compra porque uno de los insumos ya fue utilizado`,
          })
        } else {
          history.push(`/processes/purchases/${id}/${fullDelete}/delete`);
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    openUpdateStatusIsPaid: (id:number, isPaid: false, setIsLoading: any, loadPurchases:any) => {
      Swal.fire({
        icon: 'warning',
        title: `¿Está seguro que desea Marcar como ${!isPaid ? 'No' : ''} Paga esta orden de compra?`,
        showCancelButton: true
      }).then( async (v) => {
        if(v.isConfirmed){
          try {
            setIsLoading(true);
            await axios.put(`${process.env.REACT_APP_API_URL}/purchase/updateStatus/${id}`,{
              isPaid
            })
            await loadPurchases()
          } catch (error) {
            console.log('error', error);
          } finally {
            setIsLoading(false);
          }
          // TODO Load update status.
        }
      })
    },
    openPurchasePaymentDialog: (id:number) => {
      history.push(`/processes/purchases/paymentProvider/${id}`)
    }
  }

  return (
    <PurchasesUIProvider purchasesUIEvents={purchasesUIEvents}>
      {/* <PurchasesLoadingDialog /> */}
      <Route path="/processes/purchases/new">
        {({ history, match }) => (
          <PurchaseEditDialog
            show={match !== null}
            onHide={() => {
              history.push("/processes/purchases");
            }}
          />
        )}
      </Route>
      <Route path="/processes/purchases/:id/edit">
        {({ history, match }) => {
          return (
            <PurchaseEditDialog
              show={match !== null}
              id={match && (Number(match.params.id) as number)}
              onHide={() => {
                history.push("/processes/purchases");
              }}
            />
          )
        }}
      </Route>
      <Route path="/processes/purchases/:id/:fullDelete/delete">
        {({ history, match }) => (
          <PurchaseDeleteDialog
            show={match !== null}
            id={match && Number(match.params.id)}
            fullDelete={match && Number(match.params.fullDelete)}
            onHide={() => {
              history.push("/processes/purchases");
            }}
          />
        )}
      </Route>
      <Route path="/processes/purchases/paymentProvider/:id">
        {({ history, match }) => (
          <PurchasePaymentDialog
            show={match !== null}
            id={match && Number(match.params.id)}
            onHide={() => {
              history.push("/processes/purchases");
            }}
          />
        )}
      </Route>
      <PurchasesCard />
    </PurchasesUIProvider>
  );
}




