import React, { useMemo } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../../../_metronic/partials/control";
import { WalletTable } from './wallet-table/WalletTable';

import { useWalletUIContext, WalletUIContextModel } from "./WalletUIContext";
import { useIntl } from 'react-intl';
import ButtonExcel from '../../../../jsxcomponents/ButtonExcel';
import { WalletFilter } from './wallet-filter/WalletFilter';

export function WalletCard(){
    const intl = useIntl();
    const walletUIContext = (useWalletUIContext() as WalletUIContextModel);
    const walletUIProps = useMemo(() => {
        return {
            valuesTable: walletUIContext.valuesTable
        }
    },[walletUIContext]);

  
    return(
        <Card>
            <CardHeader title="Cartera">
                <CardHeaderToolbar>
                    <ButtonExcel 
                        data={walletUIProps.valuesTable}
                    />
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <WalletFilter/>
                <WalletTable/>
            </CardBody>
        </Card>
    )
}
