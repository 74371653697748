// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";
import {
  LoanstatusCssClasses,
  LoanstatusTitles,
} from "../../LoansUIHelpers";

export function StatusColumnFormatter(cellContent:any, row: any) {
  const getLabelCssClasses = () => {
    return `badge badge-lg badge-light-${LoanstatusCssClasses[row.debtor - 1 ]} badge-inline`;
  };
  return (
    <span className={getLabelCssClasses()}>
      {row.debtorType}
    </span>
  );
}



