// import SVG from "react-inlinesvg";
// import { toAbsoluteUrl } from "../../../../../../../_metronic/helpers";
import {IntlShape} from 'react-intl'
import {Visibility} from '@mui/icons-material'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

interface ActionActionProps {
  openEditWalletDialog: any
  intl: IntlShape
}

export const ActionsColumnFormatter = (
  cellContent: any,
  row: any,
  rowInde: any,
  {openEditWalletDialog, intl}: ActionActionProps
) => {
  return (
    <>
      <OverlayTrigger
        placement='top'
        delay={{
          show: 500,
          hide: 200,
        }}
        overlay={<Tooltip id='tooltip'>{intl.formatMessage({id: 'GENERAL.DETAILS'})}</Tooltip>}
      >
        <button
          data-tip
          data-for='editTip'
          className='btn btn-icon btn-primary btn-sm mx-1'
          onClick={() => openEditWalletDialog(row.id)}
        >
          <span className='svg-icon svg-icon-md svg-icon-primary'>
            <Visibility />
          </span>
        </button>
      </OverlayTrigger>
    </>
  )
}
