import React from 'react'

function useDebounceFnc<T extends (...a: any) => any>(funcion: T, delay: number) {
  const [timer, setTimer] = React.useState(setTimeout(() => {}, 0))

  const debouncedFnc = React.useCallback(
    (...args: Parameters<T>) => {
      clearTimeout(timer)
      return new Promise<Awaited<ReturnType<T>>>((resolve, reject) => {
        const timeout = setTimeout(async () => {
          try {
            const res = await funcion(...args)
            resolve(res)
          } catch (error) {
            reject(error)
          }
        }, delay)
        setTimer(timeout)
      })
    },
    [funcion, delay, timer]
  )

  return debouncedFnc
}

export default useDebounceFnc
