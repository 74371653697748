import React, { useState, useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import {ModalProgressBar} from "../../../../../../_metronic/partials/control";
// import { useIntl } from "react-intl";
import { useWalletUIContext, WalletUIContextModel } from "../WalletUIContext";

export interface WalletEditDialogHeaderProps {
    id?: number;
}

export const WalletEditDialogHeader: React.FC<WalletEditDialogHeaderProps> = ({id}) => {
    
    const walletUIContext = (useWalletUIContext() as WalletUIContextModel);
    const walletUIProps = useMemo(() => {
        return{
            selectedClientBal: walletUIContext.selectedClientBal,
            clientName: walletUIContext.clientName,
            accountState: walletUIContext.accountState,
            loadClientBalance: walletUIContext.loadClientBalance
        };
    },[walletUIContext]);
    const [title,setTitle] = useState<string>("Cargando");
    const [value,setValue] = useState<string>("");

    useEffect(() => {
        let _title = id ? "Cargando" : walletUIProps.clientName;
        let _value = id ? "" : walletUIProps.accountState;

        if(!walletUIProps.loadClientBalance && id){
            _title = `Cliente ${walletUIProps.clientName}`;
            _value = `Saldo ${walletUIProps.accountState}`
        }

        if(walletUIProps.loadClientBalance){
            _title = "Cargando "
            _value = ""
        }
        
        setTitle(_title);
        setValue(_value);

    },[walletUIProps.loadClientBalance])

    return(
        <>
            {false && <ModalProgressBar />}
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    <div className="d-flex flex-column">
                        <div className="d-flex">
                            {title}{ walletUIProps.loadClientBalance && <div className = "dot-shuttle"/> }
                        </div>
                        <div className="d-flex">
                           {value}{ walletUIProps.loadClientBalance && <></>}
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>
        </>
    );
}