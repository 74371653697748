import React, {useMemo, useState} from 'react'
import {Formik} from 'formik'
import {isEqual} from 'lodash'
import {useProfitsInvestorsUIContext, ProfitsInvestorsUIContextModel, QueryParams} from '../ProfitsInvestorsUIContext'
import {useIntl} from 'react-intl'
import useDebounceFnc from '../../../../../../utils/customHooks/useDebounceFnc'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

interface filterValues {
  clientId: string
  factoryId: string
  product: string
  startDate: string
  endDate: string
}
const prepareFilter = (queryParams: QueryParams, values: filterValues) => {
  const {clientId, factoryId, product, startDate, endDate} = values

  const newQueryParams = {...queryParams}

  newQueryParams.clientId = clientId !== '' ? Number(clientId) : null
  newQueryParams.factoryId = factoryId !== '' ? Number(factoryId) : null
  newQueryParams.product = product
  newQueryParams.startDate = startDate !== '' ? startDate : null
  newQueryParams.endDate = endDate !== '' ? endDate : null

  return newQueryParams
}

export interface LoansFilterProp {
  listLoading: boolean
}
export const ProfitsFilter: React.FC<{}> = () => {
  // Loans UI Context
  const intl = useIntl()
  const profitsUIContext = useProfitsInvestorsUIContext() as ProfitsInvestorsUIContextModel
  const profitsUIProps = useMemo(() => {
    return {
      queryParams: profitsUIContext.queryParams,
      setQueryParams: profitsUIContext.setQueryParams,
      factories: profitsUIContext.factories,
      clients: profitsUIContext.clients,
    }
  }, [profitsUIContext])
  const [start, setStart] = useState<Date | null>(null)
  const [end, setEnd] = useState<Date | null>(null)

  // queryParams, setQueryParams,
  const applyFilter = useDebounceFnc((values: any) => {
    const newQueryParams = prepareFilter(profitsUIProps.queryParams, values)
    if (!isEqual(newQueryParams, profitsUIProps.queryParams)) {
      // update list by queryParams
      console.log(values, newQueryParams)
      profitsUIProps.setQueryParams(newQueryParams)
    }
  }, 400)

  return (
    <>
      <Formik
        initialValues={{
          clientId: '',
          factoryId: '',
          product: '',
          startDate: '',
          endDate: '',
        }}
        onSubmit={(values) => {
          applyFilter(values)
        }}
      >
        {({values, handleSubmit, handleBlur, handleChange, setFieldValue}) => (
          <form onSubmit={handleSubmit} className='form form-label-right'>
            <div className='form-group row'>
              {/* FILTRAR POR FABRICA */}
              <div className='col-lg-4 mb-2'>
                <select
                  className='form-control'
                  name='factoryId'
                  placeholder={intl.formatMessage({id: 'LABELS.FILTER.SEARCH_BY_FACTORY'})}
                  onChange={(e) => {
                    setFieldValue('factoryId', e.target.value)
                    handleSubmit()
                  }}
                  onBlur={handleBlur}
                  value={values.factoryId}
                >
                  <option value={''}>
                    {intl.formatMessage({id: 'LABELS.FILTER.CHOOSE_FACTORY'})}
                  </option>
                  {profitsUIProps.factories.map((f: any, index: number) => (
                    <option value={f.id} key={index}>
                      {f.name}
                    </option>
                  ))}
                </select>
                <small className='form-text text-muted'>
                  {intl.formatMessage({id: 'LABELS.FILTER.SEARCH_BY_FACTORY'})}
                </small>
              </div>
              {/* FILTRAR POR CLIENTE */}
              <div className='col-lg-4 mb-2'>
                <select
                  className='form-control'
                  name='clientId'
                  placeholder={intl.formatMessage({id: 'LABELS.FILTER.SEARCH_BY_CLIENT'})}
                  onChange={(e) => {
                    setFieldValue('clientId', e.target.value)
                    handleSubmit()
                  }}
                  onBlur={handleBlur}
                  value={values.clientId}
                >
                  <option value={''}>
                    {intl.formatMessage({id: 'LABELS.FILTER.CHOOSE_CLIENT'})}
                  </option>
                  {profitsUIProps.clients.map((c: any, index: number) => (
                    <option value={c.id} key={index}>
                      {c.name}
                    </option>
                  ))}
                </select>
                <small className='form-text text-muted'>
                  {intl.formatMessage({id: 'LABELS.FILTER.SEARCH_BY_CLIENT'})}
                </small>
              </div>

              {/* FILTRAR POR PRODUCTO */}
              <div className='col-lg-4 mb-2'>
                <input
                  type='text'
                  className='form-control'
                  name='billingReference'
                  placeholder={'Buscar por producto'}
                  onBlur={handleBlur}
                  value={values.product}
                  onChange={(e) => {
                    setFieldValue('product', e.target.value)
                    handleSubmit()
                  }}
                />
                <small className='form-text text-muted'>Filtrar por referencia</small>
              </div>

              {/* FILTRAR POR START_DATE */}
              <div className='col-lg-4 mb-2'>
                <DatePicker
                  className='form-control'
                  selected={start}
                  isClearable
                  onChange={(date) => {
                    setStart(date)
                    if (date === null) {
                      setFieldValue('startDate', '')
                      handleSubmit()
                      return
                    }
                    setFieldValue(
                      'startDate',
                      moment(date).format('YYYY-MM-DD 00:00:00Z')
                    )
                    handleSubmit()
                  }}
                />
                <small className='form-text text-muted'>Filtrar por fecha de inicio</small>
              </div>

              {/* FILTRAR POR END_DATE */}
              <div className='col-lg-4 mb-2'>
                <DatePicker
                  className='form-control'
                  selected={end}
                  isClearable
                  onChange={(date) => {
                    setEnd(date)
                    if (date === null) {
                      setFieldValue('endDate', '');
                      handleSubmit()
                      return
                    }
                    setFieldValue(
                      'endDate',
                      moment(date).format('YYYY-MM-DD 23:59:59Z')
                    )
                    handleSubmit()
                  }}
                />
                <small className='form-text text-muted'>Filtrar por fecha final</small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  )
}
