import React, {createContext, useContext, useState, useCallback, useEffect} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter, initialValues} from "./OrdersUIHelpers";
import axios from "axios";
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../../../setup/redux/RootReducer';
import { OrderModel } from "./models";
import { UserComplete } from '../../../../.././../../base_project_backend/src/api/interfaces/usuarios.interface';
import useIntlCurrencyFormat from '../../../../../utils/customHooks/useIntlCurrencyFormat';
import DeleteZerosHelper from '../../../../../utils/functions/DeleteZerosHelper';

//Modelos
import { FactoryModel } from '../../../admin/pages/factories/models';
import { ClientModel } from '../../../admin/pages/client/models/index';

interface dataDataResultTableOrders {
  entities: any[],
  totalCount: number,
  errorMessage: string,
}
export interface resultTableOrders {
  data: dataDataResultTableOrders
  totalCount: number,
}
export interface PaymentManufacture {
  id: number;
  value: string;
  description: string;
  orderId: string;
  paymentDate: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: any;
  balance?: number;
}
export interface PaymentProfit extends PaymentManufacture {};
export interface OrdersUIContextModel {
  queryParams:any,
  setQueryParamsBase: (queryParams:any) => void,
  ids: number[],
  setIds: (ids: number[]) => void,
  setQueryParams: (queryParams:any) => void,
  initOrder:Partial<OrderModel>,
  newOrderButtonClick: any,
  openEditOrderDialog: any,
  productStockButtonClick: any,
  openDeleteOrderDialog: any,
  openDeleteOrdersDialog: any,
  openFetchOrdersDialog: any,
  openUpdateOrdersStatusDialog: any,
  openOrderPaymentDialog: any,
  valuesTable: resultTableOrders,
  tableLoading: boolean,
  setTableLoading: (tableLoading: boolean) => void,
  fetchOrder: (id: number) => Promise<void>
  loadOrders: (isPayment?:number) => Promise<void>
  loadOrder: boolean,
  selectedOrder:OrderModel | undefined
  setSelectedOrder:  React.Dispatch<React.SetStateAction<OrderModel | undefined>>,
  factories: FactoryModel[],
  clients: ClientModel[]
  isLoading: boolean;
  setIsLoading: (isLoading:boolean) => void;
  factoryId: number;
  setFactoryId: (factoryId:number)=>void;
  loadPaymentManufactureByOrderId: (id:number) => Promise<void>;
  loadPaymentProfitByOrderId: (id:number) => Promise<void>;
  paymentManufactures: PaymentManufacture[];
  paymentProfits: PaymentProfit[];
  loadPaymentM: boolean;
  loadPaymentP: boolean;
}
const OrdersUIContext = createContext<OrdersUIContextModel | null>(null);

export function useOrdersUIContext() {
  return useContext(OrdersUIContext);
}

export const OrdersUIConsumer = OrdersUIContext.Consumer;

export type OrdersUIProviderProps = {
  ordersUIEvents: any,
}

export const OrdersUIProvider:React.FC<OrdersUIProviderProps> =  ({ordersUIEvents, children}) => {
  const user: UserComplete = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserComplete;
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [valuesTable, setValuesTable] = useState<resultTableOrders>(initialValues);
  const [tableLoading, setTableLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState<OrderModel>();
  const [loadPaymentM, setLoadPaymentM] = useState(false);
  const [loadPaymentP, setLoadPaymentP] = useState(false);
  const [paymentManufactures, setPaymentManufactures] = useState<PaymentManufacture[]>([]);
  const [paymentProfits, setPaymentProfits] = useState<PaymentProfit[]>([]);
  const [loadOrder, setLoadOrder] = useState(false);
  const [ids, setIds] = useState([]);
  const {cop} = useIntlCurrencyFormat();

  const [factories,setFactories] = useState<FactoryModel[]>([]);
  const [clients,setClients] = useState<ClientModel[]>([]);
  const [isLoading,setIsLoading] = useState(false);

  const [factoryId, setFactoryId] = useState(0);



  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {

      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initOrder: Partial<OrderModel> = {
    id: undefined,
    factoryId: 0,
    clientId: 0,
    suggestedPrice: 0,
    price: 0,
    quantity: 0,
    discount: 0,
    reference: '',
    productQuantity:0,
    costManufactPerProduct: 0,
    //@ts-ignore
    factory: '',
  };

  /**
   * @description Obtener las ordenes de venta de una empresa
   */
  const loadOrders = async (isPayment:number=1) => {
    if(isPayment === 1){
      setSelectedOrder(undefined);
    }
    setTableLoading(true);
    try {
      const orderResult = await axios.post(`${process.env.REACT_APP_API_URL}/order/findAll`,{
        queryParams,
        companyId: user.company.id,
      });
      (orderResult.data as unknown as resultTableOrders).data.entities.map((e:any) => {
        e.totalTotal = DeleteZerosHelper(cop?.format(e.total - e.discount));
        e.discount = DeleteZerosHelper(cop?.format(e.discount))
        e.total = DeleteZerosHelper(cop?.format(e.total));
      })
      setValuesTable(orderResult.data);
    } catch (error) {
      console.log('error', error)
    } finally {
      setTableLoading(false);
    }
  }


  const loadFactories = async ()=>{
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/factory`);
      setFactories(response.data);
    } catch (error) {
    } finally {

    }
  }

  const loadClients = async ()=>{
    try {
        const result = await axios.get(`${process.env.REACT_APP_API_URL}/client`);
        setClients(result.data);
    } catch (error) {
        console.log('error', error)
    } finally {}
  }


  useEffect(()=> {
    loadOrders();
    loadFactories();
    loadClients();
  },[queryParams])

  const loadPaymentManufactureByOrderId = useCallback(async (id:number) => {
    try {
      setLoadPaymentM(true)
      const result = await axios.get<PaymentManufacture[]>(`${process.env.REACT_APP_API_URL}/paymentManufacture/findByOrderId/${id}`);
      setPaymentManufactures(result.data)
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoadPaymentM(false)
    }
  }, [])

  const loadPaymentProfitByOrderId = useCallback(async (id:number) => {
    try {
      setLoadPaymentP(true)
      const result = await axios.get<PaymentProfit[]>(`${process.env.REACT_APP_API_URL}/paymentProfit/findByOrderId/${id}`);
      setPaymentProfits(result.data)
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoadPaymentP(false)
    }
  }, [])

  const fetchOrder = useCallback(async (id:number)=> {
    try {
      setLoadOrder(true)
      const result = await axios.get(`${process.env.REACT_APP_API_URL}/order/${id}`);
      console.log('result', result);
      setSelectedOrder(result.data);
      setFactoryId(result.data.factory);
    } catch (error) {
      console.log('error loading order', error)
    } finally {
      setLoadOrder(false);
      // setTimeout(()=>{
      // },500)
    }
  },[])

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initOrder,
    newOrderButtonClick: ()=>{
      setSelectedOrder(undefined);
      ordersUIEvents.newOrderButtonClick();
    },
    openEditOrderDialog: ordersUIEvents.openEditOrderDialog,
    openDeleteOrderDialog: ordersUIEvents.openDeleteOrderDialog,
    productStockButtonClick: ordersUIEvents.productStockButtonClick,
    openDeleteOrdersDialog: ordersUIEvents.openDeleteOrdersDialog,
    openFetchOrdersDialog: ordersUIEvents.openFetchOrdersDialog,
    openUpdateOrdersStatusDialog: ordersUIEvents.openUpdateOrdersStatusDialog,
    openOrderPaymentDialog: ordersUIEvents.openOrderPaymentDialog,
    valuesTable,
    tableLoading,
    loadOrders,
    fetchOrder,
    selectedOrder,
    setSelectedOrder,
    loadOrder,
    factories,
    clients,
    isLoading,
    setIsLoading,
    factoryId,
    setFactoryId,
    loadPaymentM,
    loadPaymentP,
    paymentManufactures,
    paymentProfits,
    loadPaymentManufactureByOrderId,
    loadPaymentProfitByOrderId,
  };
  //@ts-ignore
  return <OrdersUIContext.Provider value={value}>{children}</OrdersUIContext.Provider>;
}


