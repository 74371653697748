import React from 'react'
import {inventorySupply} from '../InventoryUIContext';
import axios from "axios";

const useInventory = (factoryId: number) => {
	const getInventory = async () => {
		try {
			const response = await axios.get<inventorySupply[]>(
				`${process.env.REACT_APP_API_URL}/factory/getSupplyInventory/${factoryId}`,
			)
			return response.data
		} catch (error) {
			console.log(error)
			return [];
		}
	}
	return getInventory;
}

export default useInventory;
