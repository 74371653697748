// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, {useEffect, useState, useRef} from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, FormikProps } from "formik";
import * as Yup from "yup";
import {
    Checkbox,
  Input, Select,
} from "../../../../../../_metronic/partials/control";
import { useIntl } from "react-intl";
import axios from 'axios';
import { shallowEqual, useSelector } from 'react-redux';
import { PurchaseModel } from "../models";
import { UserComplete } from "../../../../auth/models/UserModel";
import { RootState } from '../../../../../../setup/redux/RootReducer';
import { PurchasesLoadingDialog } from '../purchases-loading-dialog/PurchasesLoadingDialog';
import useIntlCurrencyFormat from '../../../../../../utils/customHooks/useIntlCurrencyFormat';
import DeleteZerosHelper from '../../../../../../utils/functions/DeleteZerosHelper';
import Swal from 'sweetalert2';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers/AssetHelpers';
import TextAreaInput from '../../../../../../_metronic/partials/control/forms/TextAreaInput';
import useLoadForms from '../components/hooks/useLoadForms';
import { InputNumber } from '../../../../../../_metronic/partials/control/forms/InputNumber';
import ProviderInfor from "../components/ProviderInfor";
import { generatePurchaseOrder } from '../../../../../../utils/PDF/PurchaseOrder';
import { PaymentProviders } from "../PurchasesUIContext";
import { avaliableSupplies } from "../../productStock/components/SupplyTable";

export interface PurchaseEditFormProps {
  savePurchase: (purchase: PurchaseModel) => void;
  purchase?: Partial<PurchaseModel>
  actionsLoading?: boolean;
  onHide?: () => void;
  paymentProviders?: Partial<PaymentProviders[]>;
}
export interface PurchaseRealData {
  supplyTypeId: number;
  quantity: number;
  price: number;
  supplyDescription?: string;
  unity: string;
  id: number;
	isSelected: boolean;
}

export interface UnitSupplyInterface {
  id: number
  name: string
  translation: string
  createdAt: Date
  updatedAt: Date
  deletedAt: Date
}
export interface SupplyInterface {
  companyId: string
  createdAt: Date
  deleteAt?: Date
  description: string
  id: number
  name: string
  reference: string
  status: number
  unitId: number
  updatedAt: Date
  unit: UnitSupplyInterface
  lastPurchasePrice?: number
}

export const PurchaseEditForm: React.FC<PurchaseEditFormProps> = ({
  savePurchase,
  purchase,
  actionsLoading,
  onHide,
  paymentProviders,
}) => {
  const userComplete: UserComplete = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserComplete
  // const [supplyType, setSupplyType] = useState<SupplyInterface[]>([]);
  const [selectedSupplyType, setSelectedSupplyType] = useState<SupplyInterface | null>(null);
  // const [supplyTypeAux, setSupplyTypeAux] = useState<SupplyInterface[]>([]);
  const [addSupply, setAddSupply] = useState<PurchaseRealData[]>([]);
  const formRef = useRef<FormikProps<PurchaseModel>>(null);
  const [unity,setUnity] = useState("");

  const [subTotal, setSubTotal] = useState(0);
  const [discount,setDiscount] = useState(0);
  const [totalPurchase,setTotalPurchase] = useState(0);

  const [avaliableSupplies,setAvaliableSupplies] = useState<avaliableSupplies[]>([]);

  // console.log('purchase', purchase);
  // console.log('paymentProviders', paymentProviders?.length)

  const {
    supplyTypeAux,
    factories,
    providers,
    supplyType,
    setSupplyType
  } = useLoadForms({
    purchase,
    setAddSupply,
    formRef,
  });
  const intl = useIntl();
  const {cop} = useIntlCurrencyFormat();
  const idPurchase = purchase?.id;
  // Validation schema
  const PurchaseEditSchema = Yup.object().shape({
    reference: Yup.string()
      .required("La referencia de compra es requerida"),
  });
  const handleAddSupply = () => {
    const aux = addSupply;
    const values = formRef.current?.values
    if(values?.price === 0 || values?.quantity === 0 || values?.supplyTypeId === 0){
      return Swal.fire({
        title: 'Por favor ingrese todos los valores',
        icon: 'warning',
      })
    }
    aux.push({
      supplyTypeId: (values?.supplyTypeId as number),
      quantity: (values?.quantity as number),
      price: (values?.price as number),
      supplyDescription: (values?.supplyDescription as string),
      unity: unity,
      id: (values?.id as number),
			isSelected: values?.isSelected ?? false,
    })
    formRef.current?.setFieldValue('values', JSON.stringify(aux));
    setAddSupply(aux);
    formRef.current?.setFieldValue('quantity',0)
    formRef.current?.setFieldValue('price',0)
    formRef.current?.setFieldValue('supplyTypeId',0)
    formRef.current?.setFieldValue('supplyDescription','')
    formRef.current?.setFieldValue('isSelected', false)
    const aux2 = supplyType.filter(supply => Number(supply.id) !== Number(`${values?.supplyTypeId}`.slice(0,-1)));
    setSupplyType(aux2);
  }

  const deleteSupply = (index: number, supplyTypeId: number, quantity: number, id: number) => {
    let diff = 0;
    const supply = avaliableSupplies.filter(supply => Number(supply.supplyId) === Number(id));
    if (supply.length > 0) diff = supply[0].currentQuantity - quantity;
    if (diff < 0) {
      return Swal.fire({
        title: 'No se puede eliminar el insumo porque ya fue utilizado',
        icon: 'warning',
      })
    }
    const aux = addSupply.filter((s, i)=> i !== index);
    if(aux.length === 0){
      formRef.current?.setFieldValue('values', "");
    } else {
      formRef.current?.setFieldValue('values', JSON.stringify(aux));
    }
    const supplyAux = supplyType;
    supplyAux.push(supplyTypeAux.find((s) => Number(s.id) === Number(`${supplyTypeId}`.slice(0,-1))) as SupplyInterface)
    setSupplyType(supplyAux);
    setAddSupply(aux);
  }

  const handleCostSupplies = () => {
    if(addSupply){
      const totalTemp = addSupply.reduce((a,b:PurchaseRealData) => a + ((b?.price ? b.price : 0) * (b?.quantity ? b.quantity : 1)) ,0)
      setSubTotal(totalTemp)
    }
  }


  useEffect(() => {
    if(purchase && purchase.id){
      setTotalPurchase(purchase.totalPurchase ? purchase.totalPurchase : 0);
      setSubTotal(purchase.subTotal ? purchase.subTotal : 0);
      setDiscount(purchase.discount ? purchase.discount : 0);
    }
  },[])

  useEffect(() => {
    handleCostSupplies();
  },[addSupply.length])

  useEffect(() => {
    setTotalPurchase(subTotal - discount)
  },[discount,subTotal])

  //Inventario
  useEffect(() => {
    const loadAvaliableSupplies = async () => {
      try{
        if(idPurchase && paymentProviders?.length === 0){
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/factory/getSupplyInventory/${purchase?.factory}`);
          setAvaliableSupplies(response.data);
        }
      }catch(error){
        console.log('error getting inventory',error)
      }
    }

    loadAvaliableSupplies();
  },[idPurchase, paymentProviders])


  useEffect(()=>{

  },[purchase?.suppliesId])

  return (
    <>
      {
        actionsLoading ? <PurchasesLoadingDialog color={"primary"}/> :
          <Formik
            enableReinitialize={true}
            initialValues={(purchase as PurchaseModel)}
            validationSchema={PurchaseEditSchema}
            innerRef={formRef}
            onSubmit={(values) => {
              if(addSupply.length === 0) return Swal.fire({
                title: 'Por favor ingrese al menos un insumo',
                icon: 'warning',
              })
              const vall = {
                ...values,
                companyId : userComplete.company.id,
              }
              savePurchase(vall);
            }}
          >
            {({ handleSubmit, values, setFieldValue }) => (
              <>
                <Modal.Body className="cursor-default overlay overlay-block ">
                  {actionsLoading && (
                    <div className="overlay-layer bg-transparent">
                      <div className="spinner spinner-lg spinner-success" />
                    </div>
                  )}
                  <Form className="form form-label-right" onSubmit={handleSubmit}>
                    <div className="form-group row mb-7">
                      <div className="col-lg-4">
                        <Field
                          name="reference"
                          component={Input}
                          placeholder={intl.formatMessage({ id: "PURCHASE.REFERENCE"})}
                          label={intl.formatMessage({ id: "PURCHASE.REFERENCE"})}
                          disabled={paymentProviders?.length !== 0}
                        />
                      </div>
                      <div className="col-lg-4">
                        <Field 
                          name="provider"
                          label="Proveedor"
                          disabled={purchase?.provider}
                          component={Select}
                        >
                          <option value={0}>Escoja un proveedor</option>
                          {providers.map((provider,i) => (
                            <option value={provider.id} key={i}>{provider.name}</option>
                          ))
                          }
                        </Field>
                      </div>
                      <div className="col-lg-4">
                        <Field 
                          name="factory"
                          label="Fabrica"
                          disabled={purchase?.factory}
                          component={Select}
                        >
                          <option value={0}>{"Escoja una fabrica"}</option>
                          {factories.map((f,index) => (
                            <option value={f.id} key={index}>{f.name}</option>
                          ))}
                        </Field>
                      </div>
                    </div>
                      {
                        <ProviderInfor
                          provider={providers.find(p=> Number(p.id) === Number(values.provider))}
                        />
                      }
                    {/* Proveedor y descripcion */}
                    <div className="form-group row my-7">
                      {/* Descripcion */}
                      <div className="col-lg-12">
                        <Field
                          name="description"
                          component={TextAreaInput}
                          placeholder={intl.formatMessage({ id: "PURCHASE.DESCRIPTION"})}
                          label={intl.formatMessage({ id: "PURCHASE.DESCRIPTION"})}
                          disabled={paymentProviders?.length !== 0}
                        />
                      </div>
                    </div>
                    {paymentProviders?.length === 0 && (
                      <>
                        <div className="form-group row my-7">
                          {/* Tipo de insumo */}
                          <div className="col-lg-4 mt-7 mt-lg-0">
                            <Field 
                              name="supplyTypeId"
                              label={intl.formatMessage({id: "PURCHASE.SUPPLY_TYPE"})}
                              onChange={(e:any)=>{
                                if(e.target.value.substr(e.target.value.length - 1) === '1'){
                                  setUnity("unidades")
                                }else if(e.target.value.substr(e.target.value.length - 1) === '2'){
                                  setUnity("metros")
                                }else{
                                  setUnity("kilogramos")
                                }
                                setFieldValue('supplyTypeId', e.target.value);
                                if(e.target.value > 0){
                                  const aux = supplyTypeAux.find(s => Number(s.id) === Number(`${e.target.value}`.slice(0,-1)))
                                  setSelectedSupplyType(aux as SupplyInterface)
                                } else {
                                  setSelectedSupplyType(null)
                                }
                              }}
                              component={Select}
                            >
                            
                              <option value="0">{"Por favor escoja un insumo"}</option>
                              {supplyType.map((item: any, index:number) => (

                                <option key={index} value={`${item.id}${item.unitId}`}>{item.name}</option>
                              ))}
                            </Field>
                          </div>
                          {/* Precio de compra */}
                          <div className="col-lg-4 mt-7 mt-lg-0">
                            <Field
                              name="price"
                              component={Input}
                              placeholder={"Precio de compra"}
                              type="number"
                              // placeholder={intl.formatMessage({ id: "GENERAL.DOCUMENT"})}
                              // label={intl.formatMessage({ id: "GENERAL.DOCUMENT"})}
                              label={"Precio"}
                            />
                            {
                              selectedSupplyType &&
                                <small>{`Último precio de compra: ${DeleteZerosHelper(cop?.format((selectedSupplyType?.lastPurchasePrice as number)))}`}</small>
                            }
                          </div>
                          {/* Cantidad comprada */}
                          <div className="col-lg-4 mt-7 mt-lg-0">
                            <Field
                              type="number"
                              name="quantity"
                              component={InputNumber}
                              placeholder={intl.formatMessage({ id: "PURCHASE.SUPPLY_QUANTITY"})}
                              label={intl.formatMessage({ id: "PURCHASE.SUPPLY_QUANTITY"})}
                            />
                            {
                              selectedSupplyType && <small>
                                {intl.formatMessage({id: selectedSupplyType.unit.translation})}
                              </small>
                            }
                          </div>
                          {/* Cantidad comprada */}
                          <div className="col-lg-4 mt-7">
                            <Field
                              name="isSelected"
                              component={Checkbox}
															onChange={(e: any) => {
																setFieldValue("isSelected", e.target.checked)
															}}
															isSelected={values.isSelected}
                            >
															{" "}Actualizar costo del insumo
														</Field>
                          </div>
                        </div>
                        <div className="form-group row mb-2">
                          {/* Descripcion insumo */}
                          <div className="col-lg-12 mt-7 mt-lg-0">
                            <Field
                              type="text"
                              name="supplyDescription"
                              component={TextAreaInput}
                              placeholder={intl.formatMessage({ id: "PURCHASE.SUPPLY_DESCRIPTION"})}
                              label={intl.formatMessage({ id: "PURCHASE.SUPPLY_DESCRIPTION"})}
                            />
                          </div>
                        </div>
                        {/*  Boton agregar */}
                        <div className="form-group row my-0 d-flex justify-content-end gap-2">
                          <div className="col-lg-3">
                                <button className="btn btn-primary btn-elevate btn-block" onClick={handleAddSupply}
                                  type="button"
                                >
                                  <i className="fas fa-plus"></i>{"Agregar"}
                                </button>
                          </div>
                        </div>
                      </>
                    )}
                    {
                      addSupply.length > 0 &&
                        <div className="form-group row my-7">
                          <div>
                                <table className="styled-table-success">
                                  <thead>
                                    <tr>
                                      <th>#</th>
                                      <th>{"Insumo"}</th>
                                      <th>{"Precio"}</th>
                                      <th>{"Cantidad"}</th>
                                      <th>{"Unidad"}</th>
                                      <th>{"Total"}</th>
                                      {paymentProviders?.length === 0 && (
                                        <th>{"Acción"}</th>
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      addSupply.map((item, index)=>(
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>{supplyTypeAux.find(s => Number(s.id) === Number(`${item.supplyTypeId}`.slice(0,-1)))?.name}</td>
                                          <td>{DeleteZerosHelper(cop?.format(item.price))}</td>
                                          <td>{item.quantity}</td>
                                          <td>{item.unity}</td>
                                          <td>{DeleteZerosHelper(cop?.format(item.quantity * item.price))}</td>
                                          {paymentProviders?.length === 0 && (
                                            <td>
                                              {/* eslint-disable-next-line */}
                                                <a
                                                  className="btn btn-icon btn-danger btn-hover-danger btn-sm mx-1 "
                                                  onClick={() => deleteSupply(index, item.supplyTypeId, item.quantity, item.id)}
                                                >
                                                        <span className="svg-icon svg-icon-md svg-icon-danger">
                                                        <SVG
                                                            src={toAbsoluteUrl("/media/svg/icons/Home/Trash.svg")}
                                                        />
                                                        </span>
                                                    </a>
                                            </td>
                                          )}
                                        </tr>
                                      ))
                                    }
                                  </tbody>
                                </table>
                          </div>
                        </div>
                    }

                    { addSupply.length > 0 &&
                      <div className="form-group row my-7">

                         {/* SUBTOTAL */}
                        <div className="col-lg-4 mt-7 mt-lg-0">
                          <Field
                            name="subTotal"
                            disabled
                            component={Input}
                            type="text"
                            label={"Subtotal"}
                            value={DeleteZerosHelper(cop?.format(subTotal))}
                          />
                        </div>

                        {/* Descuento */}
                        <div className="col-lg-4 mt-7 mt-lg-0">
                          <Field
                            type="number"
                            name="discount"
                            component={InputNumber}
                            placeholder={intl.formatMessage({ id: "ORDER.DISCOUNT"})}
                            label={intl.formatMessage({ id: "ORDER.DISCOUNT"})}
                            onChange={(e:any) => {
                              setFieldValue('discount',Number(e.target.value))
                              setDiscount(Number(e.target.value))
                            }}
                            disabled={paymentProviders?.length !== 0}
                          />
                        </div>

                        {/* TOTAL  */}
                        <div className="col-lg-4 mt-7 mt-lg-0">
                          <Field
                            name="totalPurchase"
                            disabled
                            component={Input}
                            type="text"
                            label={"Total"}
                            value={DeleteZerosHelper(cop?.format(totalPurchase))}
                          />
                        </div>
                      </div>
                    }

                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    onClick={onHide}
                    className="btn btn-light btn-elevate"
                  >
                    {intl.formatMessage({ id: "BUTTON.CANCEL"})}
                  </button>
                  <> </>
                  {(!idPurchase || (paymentProviders?.length === 0 && idPurchase)) && (
                    <button
                      type="submit"
                      onClick={() => handleSubmit()}
                      className="btn btn-primary btn-elevate"
                    >
                      {intl.formatMessage({ id: "BUTTON.SAVE"})}
                    </button>
                  )}
                  <></>
                  {idPurchase && (
                    <button
                      type="button"
                      onClick={() =>
                        // @ts-ignore
                        generatePurchaseOrder(purchase,cop.format)
                        //generateSalesOrder(purchase)
                      }
                      className = "btn btn-success"
                    >
                      {intl.formatMessage({id:"ORDER.GENERATE.ORDER"})}
                    </button>
                  )}
                </Modal.Footer>
              </>
            )}
          </Formik>
      }
    </>
  );
}



