import React, {useEffect, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {ModalProgressBar} from '../../../../../../_metronic/partials/control'
// import * as actions from "../../../_redux/orders/ordersActions";
import {
  useOrdersUIContext,
  OrdersUIContextModel,
  PaymentManufacture,
  PaymentProfit,
} from '../OrdersUIContext'
import axios from 'axios'
import Swal from 'sweetalert2'
import {OrdersLoadingDialog} from '../orders-loading-dialog/OrdersLoadingDialog'
import {Field, Form, Formik} from 'formik'
import TextAreaInput from '../../../../../../_metronic/partials/control/forms/TextAreaInput'
import {useIntl} from 'react-intl'
import {Input, Select} from '../../../../../../_metronic/partials/control'
import {DatePicker} from 'antd'
import PaymentDetail from './PaymentDetail'
import useIntlCurrencyFormat from '../../../../../../utils/customHooks/useIntlCurrencyFormat'
import DeleteZerosHelper from '../../../../../../utils/functions/DeleteZerosHelper'
import moment from 'moment'

export interface OrderPaymentDialogProps {
  id?: number | null
  show: boolean
  onHide: () => void
}

type Payment = PaymentManufacture | PaymentProfit;
type PaymentType = 'manufacture' | 'profit';
interface PaymentValues {
  paid: number;
  balance: number;
  name: string;
  save: (data: Partial<Payment>, resetForm: any) => Promise<void>
  total: any;
}

export const OrderPaymentDialog: React.FC<OrderPaymentDialogProps> = ({id, show, onHide}) => {
  // Orders UI Context
  const ordersUIContext = useOrdersUIContext() as OrdersUIContextModel
  const ordersUIProps = useMemo(() => {
    return {
      setIds: ordersUIContext.setIds,
      queryParams: ordersUIContext.queryParams,
      selectedOrder: ordersUIContext.selectedOrder,
      fetchOrder: ordersUIContext.fetchOrder,
      loadOrder: ordersUIContext.loadOrder,
      loadOrders: ordersUIContext.loadOrders,
      paymentManufactures: ordersUIContext.paymentManufactures,
      paymentProfits: ordersUIContext.paymentProfits,
      // loadPaymentM: ordersUIContext.loadPaymentM,
      // loadPaymentP: ordersUIContext.loadPaymentP,
      loadPaymentManufactureByOrderId: ordersUIContext.loadPaymentManufactureByOrderId,
      loadPaymentProfitByOrderId: ordersUIContext.loadPaymentProfitByOrderId,
      setIsLoading: ordersUIContext.setIsLoading,
    }
  }, [ordersUIContext])
  // const status = ordersUIProps.selectedOrder?.status;
  const {cop} = useIntlCurrencyFormat();
  const [paid, setPaid] = useState(0);
  const [paidProfit, setPaidProfit] = useState(0);
  const [balance, setBalance] = useState(0);
  const [balanceProfit, setBalanceProfit] = useState(0);

  //Save btn
  const [disable, setDisable] = useState(false);

  const payments = useMemo(() => {
    const profits = ordersUIProps.paymentProfits.map(p => ({...p, type: "profit"}));
    const manufacture = ordersUIProps.paymentManufactures.map(p => ({...p, type: "manufacture"}));

    const combined = [...profits, ...manufacture];

    return combined.sort((a,b) => moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf());
  }, [ordersUIProps.paymentManufactures, ordersUIProps.paymentProfits])

  const savePaymentManufacture = async (data: Partial<PaymentManufacture>, resetForm: any) => {
    try {
      setDisable(true)
      const r = await axios.post(`${process.env.REACT_APP_API_URL}/paymentManufacture`, data)
      await Swal.fire({
        title: 'Abono realizado correctamente',
        icon: 'success',
      })
      ordersUIProps.loadPaymentManufactureByOrderId(id as number)
      ordersUIProps.loadOrders(0)
      resetForm()
    } catch (error) {
      console.log('error', error)
    } finally {
      setDisable(false)
    }
  }

  const savePaymentProfit = async (data: Partial<PaymentProfit>, resetForm: any) => {
    try {
      const r = await axios.post(`${process.env.REACT_APP_API_URL}/paymentProfit`, data)
      await Swal.fire({
        title: 'Abono realizado correctamente',
        icon: 'success',
      })
      ordersUIProps.loadPaymentProfitByOrderId(id as number)
      ordersUIProps.loadOrders(0)
      resetForm()
    } catch (error) {
      console.log('error', error)
    }
  }

  const paymentType = (type: PaymentType): PaymentValues => {
    switch (type) {
      case 'manufacture':
        return {
          paid: paid,
          balance: balance,
          name: 'Manufactura',
          save: savePaymentManufacture,
          total: ordersUIProps.selectedOrder?.costManufact ?? 0,
        }    
      case 'profit':
        return {
          paid: paidProfit,
          balance: balanceProfit,
          name: 'Ganancias',
          save: savePaymentProfit,
          total: ordersUIProps.selectedOrder?.costProfit ?? 0,
        }    
      default:
        return {
          paid: 0,
          balance: 0,
          name: '',
          save: async () => {},
          total: 0,
        } 
    }
  }
  // const { isLoading } = useSelector(
  //   (state) => ({ isLoading: state.orders.actionsLoading }),
  //   shallowEqual
  // );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide()
    } else {
      ordersUIProps.fetchOrder(id as number)
      ordersUIProps.loadPaymentManufactureByOrderId(id as number)
      ordersUIProps.loadPaymentProfitByOrderId(id as number)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (ordersUIProps.paymentManufactures.length === 0) {
      setPaid(0)
      setBalance((ordersUIProps.selectedOrder?.costManufact as number) || 0)
    } else {
      const paidValue = ordersUIProps.paymentManufactures
        .map((i) => +i.value)
        .reduce((a, b) => a + b, 0)
      setPaid(paidValue)
      setBalance((ordersUIProps.selectedOrder?.costManufact as number) - paidValue || 0)
    }
    if (ordersUIProps.paymentProfits.length === 0) {
      setPaidProfit(0)
      setBalanceProfit((ordersUIProps.selectedOrder?.costProfit as number) || 0)
    } else {
      const paidValue = ordersUIProps.paymentProfits.map((i) => +i.value).reduce((a, b) => a + b, 0)
      setPaidProfit(paidValue)
      setBalanceProfit((ordersUIProps.selectedOrder?.costProfit as number) - paidValue || 0)
    }
  }, [ordersUIProps.selectedOrder, ordersUIProps.paymentManufactures, ordersUIProps.paymentProfits])

  // * Validator
  const validator = (values: Partial<PaymentManufacture>) => {
    if (!values.value) {
      Swal.fire({
        icon: 'warning',
        title: 'Por farvor ingrese un valor a abonar',
      })
      return false
    }
    if (isNaN(+(values.value as string))) {
      Swal.fire({
        icon: 'warning',
        title: 'Por farvor ingrese un valor valido en monto a pagar',
      })
      return false
    }
    if (+values.value > +(values.balance as number)) {
      Swal.fire({
        icon: 'warning',
        title: 'El valor a abonar es mayor que el permitido',
      })
      return false
    }
    if (!values.paymentDate) {
      Swal.fire({
        icon: 'warning',
        title: 'Por favor escoja una fecha de pago',
      })
      return false
    }
    return true
  }
  // looking for loading/dispatch
  // useEffect(() => {}, [ dispatch]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      style={{zIndex: 1050}}
      aria-labelledby='example-modal-sizes-title-lg'
      size='xl'
    >
      {/*begin::Loading*/}
      {false && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title id='example-modal-sizes-title-lg'>
          {ordersUIProps.loadOrder
            ? 'Cargando...'
            : `Abonar a compra ${ordersUIProps.selectedOrder?.reference}`}
					<br/>
					<span style={{fontWeight: 400}}>Nro. Corte: {ordersUIProps.selectedOrder?.fabricCutNumber}</span>
        </Modal.Title>
      </Modal.Header>
      {false ? (
        <OrdersLoadingDialog color={'primary'} />
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={{
            description: '',
            paymentDate: moment().format('YYYY-MM-DD'),
            value: '',
            type: 'manufacture' as PaymentType,
          }}
          // validationSchema={PaymentEditSchema}
          onSubmit={(values, {resetForm}) => {
            // TODO VERFICACION DE DATOS
            const val = validator({
              ...values,
              balance: paymentType(values.type).balance,
            })
            if (val) {
              Swal.fire({
                icon: 'warning',
                title: `¿Está seguro que desea realizar este abono por ${DeleteZerosHelper(
                  cop.format(Number(values.value))
                )}?`,
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
              }).then((data) => {
                if (data.isConfirmed) {
                  const data = {
                    balance,
                    orderId: (id as number).toString(),
                    ...values,
                  }
                  paymentType(values.type).save(data, resetForm)
                }
              })
            }
          }}
        >
          {({handleSubmit, setFieldValue, values}) => (
            <>
              <Modal.Body className='cursor-default overlay  row'>
                <div
                  className='col-lg-4 border-end mb-md-4'
                  style={{height: 360, overflowY: 'scroll', scrollbarColor: 'dark'}}
                >
                  <h3>Abonos realizados</h3>
                  {payments.length === 0 ? (
                    <p>No se han realizado abonos</p>
                  ) : (
                    payments.map((payment) => (
                      <PaymentDetail key={`${payment.id}-${payment.type}`} type={payment.type} payment={payment} cop={cop} />
                    ))
                  )}
                </div>
                <div className='col-lg-8'>
                  <Form className='form form-label-border-rightr' onSubmit={handleSubmit}>
                    <h2>Información abono</h2>
                    <div className='d-flex justify-content-around'>
                      <span>
                        <b>Total {paymentType(values.type).name} </b>{' '}
                        {DeleteZerosHelper(
                          cop.format(Number(paymentType(values.type).total))
                        )}
                      </span>
                      <span>
                        <b>Abonado</b>{' '}
                        {DeleteZerosHelper(
                          cop.format(Number(paymentType(values.type).paid))
                        )}
                      </span>
                      <span className={`text-${paymentType(values.type).balance === 0 ? 'success' : 'danger'}`}>
                        <b>Saldo</b>{' '}
                        {DeleteZerosHelper(
                          cop.format(
                            Number(paymentType(values.type).balance)
                          )
                        )}
                      </span>
                    </div>
                    <hr />
                    <h2>Realizar nuevo abono</h2> <br />
                    {/* Pagante */}
                    <div className='form-group row mb-7'>
                      <div className='col-lg-6'>
                        <Field name='type' id='type' label='Abono a' component={Select}>
                          <option value='manufacture'>Manufactura</option>
                          <option value='profit'>Ganancias</option>
                        </Field>
                      </div>
                    </div>
                    <div className='form-group row mb-7'>
                      {/* Tipo de deudor */}
                      {/* @ts-ignore */}
                      <div className='col-lg-6'>
                        <Field
                          name='value'
                          type='number'
                          component={Input}
                          disabled={false}
                          // value = { payment?.id && DeleteZerosHelper(cop?.format(Number(payment.value))) }
                          label={'Monto a pagar'}
                        />
                        <small>max: {DeleteZerosHelper(cop.format(Number(paymentType(values.type).balance)))}</small>
                      </div>
                      <div className='col-lg-6'>
                        <label>
                          <b>{'Día del abono'}</b>
                        </label>
                        <DatePicker
                          name='paymentDate'
                          disabled={false}
                          size={'large'}
                          disabledDate={(d) => !d || d.isAfter(moment().format('YYYY-MM-DD'))}
                          //@ts-ignore
                          // value={payment?.id ? moment(payment.paymentDate) : paymentDate}
                          value={moment(values.paymentDate)}
                          placeholder={'Escoja una fecha'}
                          style={{width: '100%'}}
                          onChange={(date: any | null, dateString: string): void => {
                            if (date) {
                              setFieldValue('paymentDate', date.format('YYYY-MM-DD'))
                            } else {
                              setFieldValue('paymentDate', moment().format('YYYY-MM-DD'))
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className='form-group row my-7'>
                      <div className='col-lg-12'>
                        <Field
                          name='description'
                          component={TextAreaInput}
                          disabled={false}
                          label={'Descripción'}
                        />
                      </div>
                    </div>
                  </Form>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {!ordersUIProps.loadOrder && (
                  <div>
                    <button type='button' onClick={onHide} className='btn btn-light btn-elevate'>
                      Cancelar
                    </button>
                    <> </>
                    <button
                      type='button'
                      onClick={handleSubmit as any}
                      className={`btn btn-success btn-elevate${
                        disable ? ' spinner spinner-white spinner-right' : ''
                      }`}
                      disabled={paymentType(values.type).balance <= 0 || disable}
                    >
                      {'Abonar'}
                    </button>
                  </div>
                )}
              </Modal.Footer>
            </>
          )}
        </Formik>
      )}
    </Modal>
  )
}
