import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../../_metronic/partials/control";
// import * as actions from "../../../_redux/purchases/purchasesActions";
import { usePurchasesUIContext, PurchasesUIContextModel, PaymentProviders } from '../PurchasesUIContext';
import axios from 'axios';
import Swal from 'sweetalert2';
import { PurchasesLoadingDialog } from '../purchases-loading-dialog/PurchasesLoadingDialog';
import { Field, Form, Formik } from "formik";
import TextAreaInput from "../../../../../../_metronic/partials/control/forms/TextAreaInput";
import { useIntl } from "react-intl";
import {
  Input, Select,
} from "../../../../../../_metronic/partials/control";
import { DatePicker } from "antd";
import PaymentDetail from "./PaymentDetail";
import useIntlCurrencyFormat from '../../../../../../utils/customHooks/useIntlCurrencyFormat';
import DeleteZerosHelper from '../../../../../../utils/functions/DeleteZerosHelper';
import moment from "moment";

export interface PurchasePaymentDialogProps {
  id?: number | null;
  show: boolean
  onHide: () => void
}
export const PurchasePaymentDialog: React.FC<PurchasePaymentDialogProps> = ({ id, show, onHide }) => {
  // Purchases UI Context
  const purchasesUIContext = (usePurchasesUIContext() as PurchasesUIContextModel);
  const purchasesUIProps = useMemo(() => {
    return {
      setIds: purchasesUIContext.setIds,
      queryParams: purchasesUIContext.queryParams,
      selectedPurchase: purchasesUIContext.selectedPurchase,
      fetchPurchase: purchasesUIContext.fetchPurchase,
      loadPurchase: purchasesUIContext.loadPurchase,
      loadPurchases: purchasesUIContext.loadPurchases,
      paymentProviders: purchasesUIContext.paymentProviders,
      loadPaymentP: purchasesUIContext.loadPaymentP,
      loadPaymentProviderByPurchaseId: purchasesUIContext.loadPaymentProviderByPurchaseId,
      setIsLoading: purchasesUIContext.setIsLoading
    };
  }, [purchasesUIContext]);
  const status = purchasesUIProps.selectedPurchase?.status;
  const {cop} = useIntlCurrencyFormat()
  const [paid, setPaid] = useState(0)
  const [balance, setBalance] = useState(0)
  // const { isLoading } = useSelector(
  //   (state) => ({ isLoading: state.purchases.actionsLoading }),
  //   shallowEqual
  // );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    } else {
      purchasesUIProps.fetchPurchase((id as number));
      purchasesUIProps.loadPaymentProviderByPurchaseId(id as number)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(()=>{
    if(purchasesUIProps.paymentProviders.length === 0){
      setPaid(0);
      setBalance(purchasesUIProps.selectedPurchase?.totalPurchase as number || 0)
    } else {
      const paidValue = purchasesUIProps.paymentProviders.map(i => +i.value).reduce((a,b) =>  a+b, 0)
      setPaid(paidValue)
      setBalance(purchasesUIProps.selectedPurchase?.totalPurchase as number - paidValue || 0)
    }
  },[purchasesUIProps.selectedPurchase, purchasesUIProps.paymentProviders])

  // * Validator
  const validator = (values:Partial<PaymentProviders>) => {
    if(!values.value){
      Swal.fire({
        icon: "warning",
        title: "Por farvor ingrese un valor a abonar"
      })
      return false
    }
    if(isNaN(+( values.value as string ))){
      Swal.fire({
        icon: "warning",
        title: "Por farvor ingrese un valor valido en monto a pagar"
      })
      return false
    }
    if(+values.value > +(values.balance as number)){
      Swal.fire({
        icon: "warning",
        title: "El valor a abonar es mayor que el permitido"
      })
      return false
    }
    if(!values.paymentDate){
      Swal.fire({
        icon: "warning",
        title: "Por favor escoja una fecha de pago"
      })
      return false
    }
    return true
  }
  // looking for loading/dispatch
  // useEffect(() => {}, [ dispatch]);
  const savePaymentProvider = async(data:Partial<PaymentProviders>, resetForm: any) => {
    try {
      const r = await axios.post(`${process.env.REACT_APP_API_URL}/paymentProvider`,data);
      await Swal.fire({
        title: "Abono realizado correctamente",
        icon: "success",
      })
      purchasesUIProps.loadPaymentProviderByPurchaseId(id as number)
      resetForm()
    } catch (error) {
      console.log('error', error)
    }
  }
  return (
    <Modal
      show={show}
      onHide={onHide}
      style={{zIndex: 1050}}
      aria-labelledby="example-modal-sizes-title-lg"
      size="xl"
    >
      {/*begin::Loading*/}
      {false && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          { purchasesUIProps.loadPurchase ? 'Cargando...' : `Abonar a compra ${purchasesUIProps.selectedPurchase?.reference}`}
        </Modal.Title>
      </Modal.Header>
      {
          false ? <PurchasesLoadingDialog color={"primary"}/> :
          <Formik
            enableReinitialize={true}
            initialValues={{
              description: "",
              paymentDate: "",
              value: ""
            }}
            // validationSchema={PaymentEditSchema}
            onSubmit={(values, {resetForm}) => {
              // TODO VERFICACION DE DATOS
              const val = validator({ ...values, balance });
              if(val){
                Swal.fire({
                  icon: "warning",
                  title: `¿Está seguro que desea realizar este abono por ${DeleteZerosHelper(cop.format(Number(values.value)))}?`,
                  showCancelButton: true,
                  cancelButtonText: "Cancelar"
                }).then((data)=>{
                  if(data.isConfirmed){
                    savePaymentProvider({
                      balance,
                      purchaseId: ( id as number ).toString(),
                      ...values
                    }, resetForm)
                  }
                })
              }
            }}
          >
            {({ handleSubmit, setFieldValue, values }) => (
              <>
                <Modal.Body className="cursor-default overlay  row">
                  <div className="col-lg-4 border-end mb-md-4" style={{height: 360, overflowY: 'scroll', scrollbarColor:"dark"}}>
                    <h3>Abonos realizados</h3>
                    {purchasesUIProps.paymentProviders.length === 0 ? <p>No se han realizado abonos</p> :
                      purchasesUIProps.paymentProviders.map(payment => (
                        <PaymentDetail key={payment.id} payment={payment} cop={cop} />
                      ))
                    }
                  </div>
                  <div className="col-lg-8">
                    <Form className="form form-label-border-rightr" onSubmit={handleSubmit}>
                      <h2>Información abono</h2>
                        <div className="d-flex justify-content-around">
                          <span><b>Total</b> {DeleteZerosHelper(cop.format(Number(purchasesUIProps.selectedPurchase?.totalPurchase)))}</span>
                          <span><b>Abonado</b> {DeleteZerosHelper(cop.format(Number(paid)))}</span>
                          <span className={`text-${balance === 0 ? 'success':'danger'}`}><b>Saldo</b> {DeleteZerosHelper(cop.format(Number(balance)))}</span>
                        </div>
                      <hr />
                      <h2>Realizar nuevo abono</h2> <br />
                      {/* Pagante */}

                      <div className="form-group row mb-7">
                        {/* Tipo de deudor */}
                        {/* @ts-ignore */}
                        <div className="col-lg-6">
                          <Field
                            name="value"
                            type="number"
                            component={Input}
                            disabled={false}
                            // value = { payment?.id && DeleteZerosHelper(cop?.format(Number(payment.value))) }
                            label={"Monto a pagar"}
                          />
                          <small>max: {DeleteZerosHelper(cop.format(Number(balance)))}</small>
                        </div>
                        <div className="col-lg-6">
                          <label>
                            <b>
                              {"Día del abono"}
                            </b>
                          </label>
                          <DatePicker
                            name="paymentDate"
                            disabled={false}
                            size={"large"}
                            disabledDate={d => !d || d.isAfter(moment().add(1,'d').format("YYYY-MM-DD"))}
                            //@ts-ignore
                            // value={payment?.id ? moment(payment.paymentDate) : paymentDate}
                            placeholder={"Escoja una fecha"}
                            style={{width:"100%"}}
                            onChange={(date: any | null,dateString:string):void => {
                              setFieldValue("paymentDate",date.format("YYYY-MM-DD"))
                            }}
                          />
                        </div>

                      </div>
                      <div className="form-group row my-7">
                        <div className="col-lg-12">
                          <Field
                            name="description"
                            component={TextAreaInput}
                            disabled={false}
                            label={"Descripción"}
                          />
                        </div>
                      </div>

                    </Form>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  {
                    !purchasesUIProps.loadPurchase &&
                      <div>
                        <button
                          type="button"
                          onClick={onHide}
                          className="btn btn-light btn-elevate"
                        >
                          Cancelar
                        </button>
                        <> </>
                        <button
                          type="button"
                          onClick={handleSubmit as any}
                          className={`btn btn-success btn-elevate`}
                          disabled={balance <= 0}
                        >
                          {"Abonar"}
                        </button>
                      </div>
                  }
                </Modal.Footer>
              </>
            )}
          </Formik>
      }
    </Modal>
  );
}


