import React, { useMemo, useState } from "react";
import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderToolbar,
} from "../../../../../_metronic/partials/control";
import TextField from '@mui/material/TextField';
import AutoComplete from '@mui/material/Autocomplete';
import { useInventoryUIContext, InventoryUIContextModel } from "./InventoryUIContext"
import { FactoryModel } from '../../../admin/pages/factories/models/index';
import { useIntl } from 'react-intl';
import { Table } from 'antd';
import {Download} from '@mui/icons-material'
import useGenerateExcel from './hooks/useGenerateExcel'
import useInventory from "./hooks/useInventory";

export function InventoryCard() {
	const intl = useIntl();
	const inventoryUIContext = (useInventoryUIContext() as InventoryUIContextModel);
	const inventoryUIProps = useMemo(() => {
		return {
			factories: inventoryUIContext.factories,
			selectedFactory: inventoryUIContext.selectedFactory,
			setSelectedFactory: inventoryUIContext.setSelectedFactory,
			fetchInventory: inventoryUIContext.fetchInventory,
			inventory: inventoryUIContext.inventory
		}
	}, [inventoryUIContext])

	const [factoryName, setFactoryName] = useState("");

	const columns = [
		{
			title: 'Insumo',
			dataIndex: 'supplyName',
			key: 'supplyName',
		},
		{
			title: 'Cantidad actual',
			dataIndex: 'currentQuantity',
			key: 'currentQuantity',
		},
		{
			title: 'Cantidad comprada',
			dataIndex: 'purchasedSupp',
			key: 'purchasedSupp',
		},
		{
			title: 'Cantidad utilizada',
			dataIndex: 'usedSupp',
			key: 'usedSupp',
		},
		{
			title: 'Unidad',
			dataIndex: 'unidad',
			key: 'unidad',
		}
	];
	
	const getInventory = useInventory(inventoryUIProps.selectedFactory);
  const download = useGenerateExcel(getInventory, factoryName)

	return (
		<Card>
			<CardHeader title="Inventario">
				<CardHeaderToolbar>
					<button
						type='button'
						className='btn btn-success btn-sm mx-1'
						onClick={() => {
							download()
						}}
						disabled={inventoryUIProps.selectedFactory === 0}
					>
						Descargar Excel <Download />
					</button>
				</CardHeaderToolbar>
			</CardHeader>
			<CardBody>
				<div className="form-group row mb-7">
					<div className="col-lg-10">
						<b>
							Escoja una fábrica
						</b>
						<AutoComplete
							disablePortal
							id="combo-box-demo"
							className="mt-5"
							options={inventoryUIProps.factories || []}
							sx={{ width: 500 }}
							onChange={(e: any, value: FactoryModel | null) => {
								inventoryUIProps.setSelectedFactory(Number(value !== null ? value.id : 0))
								inventoryUIProps.fetchInventory(Number(value !== null ? value.id : 0))
								setFactoryName(value?.name || "");
							}}
							getOptionLabel={(option: FactoryModel) => `${option.name}`}
							renderInput={(params) => <TextField {...params} label={"Fabricas"} />}
						/>
					</div>
				</div>

				{inventoryUIProps.selectedFactory !== 0 &&
					<div className="form-group row mb-7">
						<div className="col-lg-12">
							<Table
								columns={columns} dataSource={inventoryUIProps.inventory} pagination={{
									pageSizeOptions: [10, 50, 100, 500, 1000, inventoryUIProps.inventory?.length ?? 0],
									showSizeChanger: true,
									defaultPageSize: 50,
								}}
							/>
						</div>
					</div>
				}
			</CardBody>
		</Card>
	)
}
