import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { useIntl } from 'react-intl';
import {BillingsPage} from './pages/billing/BillingsPage';
import {LoansPage} from './pages/loans/LoansPage';
import {OrdersPage} from './pages/orders/OrdersPage';
import {PaymentsPage} from './pages/payments/PaymentsPage';
import {PurchasesPage} from './pages/purchases/PurchasesPage';
import { ProductStocksPage } from './pages/productStock/ProductStocksPage';

const ProcessesPage: React.FC = () => {
  const intl = useIntl()
  const processBreadCrumbs: Array<PageLink> = [
      {
        title: 'Procesos',
        path: '/processes/billing',
        isSeparator: false,
        isActive: false,
      },
      {
        title: 'Procesos',
        path: '/processes/factories',
        isSeparator: true,
        isActive: false,
      },
      {
        title: 'Procesos',
        path: '/processes/providers',
        isSeparator: true,
        isActive: false,
      },
      {
        title: 'Procesos',
        path: '/processes/supplies',
        isSeparator: true,
        isActive: false,
      },
      {
        title: 'Procesos',
        path: '/processes/users',
        isSeparator: true,
        isActive: false,
      }
    ]
  return (
    <>
      <Switch>
        <Route path='/processes/billing' >
          <PageTitle breadcrumbs={processBreadCrumbs}>{"Facturación"}</PageTitle>
          {/* <TablesWidget11 className='mb-5 mb-xl-8' /> */}
          <BillingsPage />
        </Route>

        <Route path='/processes/loans'>
          <PageTitle breadcrumbs={processBreadCrumbs}>{"Prestamos"}</PageTitle>
          <LoansPage />
        </Route>

        <Route path='/processes/orders'>
          <PageTitle breadcrumbs={processBreadCrumbs}>{"Orden de producción"}</PageTitle>
          <OrdersPage />
        </Route>

        <Route path='/processes/product-stock'>
          <PageTitle breadcrumbs={processBreadCrumbs}>{"Stock de Productos"}</PageTitle>
          <ProductStocksPage />
        </Route>

        {/* <Route path='/processes/payments'>
          <PageTitle breadcrumbs={processBreadCrumbs}>{"Pagos"}</PageTitle>
          <PaymentsPage />
        </Route> */}

        <Route path='/processes/purchases'>
          <PageTitle breadcrumbs={processBreadCrumbs}>{"Compra"}</PageTitle>
          <PurchasesPage />
        </Route>

        <Redirect from='/processes' exact={true} to='/processes/billing' />
        <Redirect to='/processes/billing' />

      </Switch>
    </>
  )
}

export default ProcessesPage
