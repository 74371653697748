import React from 'react'
import { ProviderModel } from '../../../../admin/pages/providers/models/index';

export interface ProviderInforProps {
    provider?: ProviderModel
}

const ProviderInfor:React.FC<ProviderInforProps> = ({
    provider
}) => {
    return (
        <>
            {
                provider?.id && 
                <>
                    <div className="form-group row my-3">
                        <div className='col-lg-6'> <b>Nombre: </b> {provider?.name}</div>
                        <div className='col-lg-6'> <b>Documento: </b> {provider?.document}</div>
                    </div>
                    <div className="form-group row my-3">
                        <br />{(provider?.address1 || provider?.email1 || provider?.name1 || provider?.phone1) && <div><b>Contacto principal</b></div>} <br />
                        {provider.name1 && <div className='col-lg-4'> <b>Nombre: </b> {provider?.name1}</div>}
                        {provider.email1 && <div className='col-lg-4'> <b>email: </b> {provider?.email1}</div>}
                        {provider.phone1 && <div className='col-lg-4'> <b>Telefono: </b> {provider?.phone1}</div>}
                    </div>
                </>
            }
        </>
    )
}

export default ProviderInfor