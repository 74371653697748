import React, {createContext, useContext, useState, useCallback, useEffect} from "react";
import {isEqual, isFunction} from "lodash";
import { initialFilter, initialValues } from "./InventoryUIHelpers";
import axios from 'axios';
import { FactoryModel } from '../../../admin/pages/factories/models/index';
import useIntlCurrencyFormat from '../../../../../utils/customHooks/useIntlCurrencyFormat';
import DeleteZerosHelper from '../../../../../utils/functions/DeleteZerosHelper';
import moment from 'moment';
//Modelos


//* Interfaces



export interface InventoryUIContextModel{
  queryParams: any;
  setQueryParamsBase: (queryParams:any) => void;
  setQueryParams: (queryParams:any) => void;
  factories: FactoryModel[];
  setSelectedFactory: React.Dispatch<React.SetStateAction<number | null>>;
  selectedFactory: number;
  fetchInventory: (factoryId: number) => inventorySupply[];
  inventory: inventorySupply[]
}

const InventoryUIContext = createContext<InventoryUIContextModel | null>(null);

export function useInventoryUIContext(){
  return useContext(InventoryUIContext);
}

export const InventoryUIConsumer = InventoryUIContext.Consumer;

export type InventoryUIProviderProps = {
  inventoryUIEvents: any;
}

export interface inventorySupply{
  currentQuantity: number | string ;
  factoryId: string;
  key: number;
  purchasedSupp: string;
  supplyId: string;
  supplyName: string;
  unidad: string;
  usedSupp: number | string;
}

export const InventoryUIProvider:React.FC<InventoryUIProviderProps> = ({inventoryUIEvents, children}) => {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const {thousand} = useIntlCurrencyFormat();
  const [factories,setFactories] = useState<FactoryModel[]>([])
  const [selectedFactory, setSelectedFactory] = useState(0);
  const [inventory, setInventory] = useState<inventorySupply[]>([]);

  const setQueryParams = useCallback(nextQueryParams => {
      setQueryParamsBase(prevQueryParams => {

        if (isFunction(nextQueryParams)) {
          nextQueryParams = nextQueryParams(prevQueryParams);
        }

        if (isEqual(prevQueryParams, nextQueryParams)) {
          return prevQueryParams;
        }

        return nextQueryParams;
      });
  }, []);

  const loadFactories = async () => {
    try {
      const factoriesResult =  await axios.get(`${process.env.REACT_APP_API_URL}/factory`);
      setFactories(factoriesResult.data);
    } catch (error) {
      console.log('error', error)
    }
  }

  const fetchInventory = async (factoryId: number) => {
    try{
      const { data } = await axios.get<inventorySupply[]>(`${process.env.REACT_APP_API_URL}/factory/getSupplyInventory/${factoryId}`)
      data.forEach( inventory => {
        inventory.currentQuantity= thousand.format(Number(inventory.currentQuantity))
        inventory.purchasedSupp= thousand.format(Number(inventory.purchasedSupp))
        inventory.usedSupp= thousand.format(Number(inventory.usedSupp))
      })
      setInventory(data)
    }catch(error){
      console.log('error',error)
    }
  }

  useEffect(() => {
    loadFactories()
  },[])


  const value = {
    queryParams,
    setQueryParams,
    factories,
    setSelectedFactory,
    selectedFactory,
    fetchInventory,
    inventory
  }

  //@ts-ignore
  return <InventoryUIContext.Provider value={value}>{children}</InventoryUIContext.Provider>
}
