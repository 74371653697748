import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../../_metronic/partials/control";
// import * as actions from "../../../_redux/productStocks/productStocksActions";
import { useProductStocksUIContext, ProductStocksUIContextModel } from '../ProductStocksUIContext';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ProductStocksLoadingDialog } from '../productStocks-loading-dialog/ProductStocksLoadingDialog';

export interface ProductStockDeleteDialogProps {
  id?: number | null;
  fullDelete?: number | null;
  show: boolean
  onHide: () => void
}
export const ProductStockDeleteDialog: React.FC<ProductStockDeleteDialogProps> = ({ id, fullDelete, show, onHide }) => {
  // ProductStocks UI Context
  const productStocksUIContext = (useProductStocksUIContext() as ProductStocksUIContextModel);
  const productStocksUIProps = useMemo(() => {
    return {
      setIds: productStocksUIContext.setIds,
      queryParams: productStocksUIContext.queryParams,
      selectedProductStock: productStocksUIContext.selectedProductStock,
      fetchProductStock: productStocksUIContext.fetchProductStock,
      loadProductStock: productStocksUIContext.loadProductStock,
      loadProductStocks: productStocksUIContext.loadProductStocks,
      setIsLoading: productStocksUIContext.setIsLoading
    };
  }, [productStocksUIContext]);
  // ProductStocks Redux state
  // const { isLoading } = useSelector(
  //   (state) => ({ isLoading: state.productStocks.actionsLoading }),
  //   shallowEqual
  // );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    } else {
      productStocksUIProps.fetchProductStock((id as number));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);


  const deleteProductStock = async() => {
    onHide();
    productStocksUIProps.setIsLoading(true);
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/productStock/${id}`
      )
      productStocksUIProps.setIsLoading(false);
      Swal.fire({
        title: 'Pedido de venta eliminado correctamente',
        icon: 'success'
      }).then(()=>{
        productStocksUIProps.loadProductStocks()
      })
    } catch (error) {
      console.log('error', error)
      productStocksUIProps.setIsLoading(false);
      Swal.fire({
        title: 'Error al eliminar pedido de venta',
        icon: 'error'
      }).then(()=>{
        productStocksUIProps.loadProductStocks()
      })
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}
      {false && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          { productStocksUIProps.loadProductStock ? 'Cargando...' : `
           "Eliminar"
          pedido de venta ${productStocksUIProps.selectedProductStock?.reference}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {productStocksUIProps.loadProductStock && <ProductStocksLoadingDialog color={"primary"}/>}
        {!productStocksUIProps.loadProductStock && (
          <span>
            {"¿Está seguro que desea eliminar este pedido de venta?"}
          </span>
        )}
      </Modal.Body>
      <Modal.Footer>
        {
          !productStocksUIProps.loadProductStock &&
            <div>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="button"
                onClick={deleteProductStock}
                className={`btn btn-danger btn-elevate`}
              >
                {"Eliminar"}
              </button>
            </div>
        }
      </Modal.Footer>
    </Modal>
  );
}


