import { useState, useEffect, useMemo } from 'react'
import axios from 'axios';
import { useWalletUIContext, WalletUIContextModel } from '../WalletUIContext';
import moment from 'moment';
import DeleteZerosHelper from '../../../../../../utils/functions/DeleteZerosHelper';
import useIntlCurrencyFormat from '../../../../../../utils/customHooks/useIntlCurrencyFormat';



export interface paymentsClient{
    paymentDate: Date;
    reference: string;
    description: string;
    name: string;
    value: number;
    key?: number;
}

export interface paymentsResult{
    payments: paymentsClient[];
    total: number;
}

const useLoadPayments = (id:number | undefined) => {
    const [payments,setPayments] = useState<paymentsResult>();
    const walletUIContext = (useWalletUIContext() as WalletUIContextModel);
    const {cop} = useIntlCurrencyFormat();
    const walletUIProps = useMemo(() => {
      return{
        selectedIdWallet: walletUIContext.selectedIdWallet,
      }
    },[walletUIContext.selectedIdWallet])
    useEffect(() => {
        const loadPayments = async () => {
            if(id){
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/payment/getPaymentsByClient/${id}`);
                (response.data.payments as unknown as paymentsClient[]).map((e: any, index:number) => {
                    e.paymentDate = moment(e.paymentDate).format('L');
                    e.value = DeleteZerosHelper(cop?.format(e.value))
                })

                setPayments(response.data);
            }
        }
        loadPayments()
    },[walletUIProps.selectedIdWallet])

    return {
        payments
    }
}

export default useLoadPayments
