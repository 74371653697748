import React, { SelectHTMLAttributes } from "react";
// import {useField} from "formik";
import {FieldFeedbackLabel} from "./FieldFeedbackLabel";
// import { useIntl } from "react-intl";

const getFieldCSSClasses = (touched:any, errors:any) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  field?: any
  form?: any
  label?: string
  withFeedbackLabel?: boolean
  type?: string
  customFeedbackLabel?: any
}
export const Select:React.FC<SelectProps> = ({
  label,
  withFeedbackLabel = true,
  type = "text",
  customFeedbackLabel,
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  children,
  ...props
}) => {

  return (
    <>
      {label && <><label> <b>{label}</b></label><br /></>} 
      <select
        className={getFieldCSSClasses(form?.touched?.[field.name] || false, form?.errors?.[field.name] || false )}
        {...field}
        {...props}
      >
        {children}
      </select>
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={form?.errors?.[field.name] || false}
          touched={form?.touched?.[field.name] || false}
          label={label}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
