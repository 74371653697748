import jsPDF from 'jspdf'
import moment from 'moment'
import 'moment/locale/es'
import autoTable, { CellInput, RowInput } from 'jspdf-autotable'
// import { toAbsoluteUrl } from '../../_metronic/helpers/AssetHelpers';
import DeleteZerosHelper from '../functions/DeleteZerosHelper'
import { OrderModel } from '../../app/modules/processes/pages/orders/models'
import { SupplyModel } from '../../app/modules/admin/pages/supplies/models'
import { AssignmentTurnedInSharp } from '@mui/icons-material'
moment.locale('es')
const NumberFormat = Intl.NumberFormat('es-CO', { maximumFractionDigits: 2 })
const fix2 = (num: number) => NumberFormat.format(num)

export interface totalsTable {
	totalSupplies: number
	totalToPay: number
}

const mapToTable = (supplies: SupplyModel[]) => {
	let data: RowInput[] = []
	let aux: CellInput[] = []

	supplies.forEach((supply, i: number) => {
		aux[0] = i + 1
		aux[1] = supply.name
		aux[2] = supply.reference
		aux[3] = fix2(supply.productSupply.quantity)
		aux[4] = supply.unit?.name ?? ''
		data.push(aux)
		aux = []
	})
	return data
}

// const calcTotals = (supplies:SupplyModel[]) => {
//   let result: totalsTable= {totalSupplies:0,totalToPay:0};
//   let totalSupplies=0;
//   let totalToPay=0;
//   supplies.forEach(supply => {
//     totalSupplies += Number(supply.quantity);
//     totalToPay += Number(supply.price) * Number(supply.quantity);
//   })
//   result.totalSupplies = totalSupplies;
//   result.totalToPay = totalToPay;
//   return result;
// }

export async function generateOrder(
	order: Partial<OrderModel>,
	supplies: SupplyModel[]
): Promise<void> {
	const head = [['#', 'Nombre del insumo', 'Referencia', 'Cantidad', 'Unidad']]
	const dataFixed = mapToTable(supplies)
	// const totales = calcTotals(supplies)

	// dataFixed.push( ["","","","Total",`${totales.totalSupplies}`])

	const doc = new jsPDF()
	//*Titulo
	doc.setFont('helvetica', 'bold');
	doc.setFontSize(24)
	doc.text('Orden de producción', 10, 20)

	//*Subtitulo
	doc.setFont('helvetica', 'normal');
	doc.setFontSize(12)
	//Número de corte
	doc.setFont('helvetica', 'bold');
	doc.text(`Nro. De Corte : ${order.fabricCutNumber}`, 10, 30)
	// doc.text(order.fabricCutNumber?.toString() ?? "", 39, 30);
	doc.setFont('helvetica', 'normal');

	//fecha
	doc.text(`Fecha de Orden : ${moment(order.orderDate).format('ll')}`, 10, 35)

	//Referencia
	doc.text(`Referencia : ${order.reference}`, 10, 40)

	//Notas
	const notas = doc.splitTextToSize(`Notas : ${order.description}`, 180)
	doc.text(notas, 10, 45)
	const notasY = (notas.length - 1) * 5

	// cantidad Total
	doc.setFont('helvetica', 'bold');
	doc.text(`Cantidad Total : ${order.productQuantity}`, 10, 50 + notasY)
	// doc.text(order.productQuantity?.toString() ?? '', 41, 50 + notasY)

	//Lista de productos
	doc.setFontSize(14)
	doc.text('Lista de insumos', 10, 60 + notasY)
	doc.setFont('helvetica', 'normal');

	//*Tabla
	autoTable(doc, {
		head: head,
		body: dataFixed,
		startY: 65 + notasY,
		margin: {
			left: 10,
		},
	})

	let finalY = (doc as any).lastAutoTable.finalY
	//*Nombre Fabrica
	doc.setFontSize(18)
	doc.text(` ${order.factoryName}`, 15, finalY + 20)

	doc.save(`${order.reference}.pdf`)
}
