import React from 'react'
//@ts-ignore
import ReactExport from 'react-data-export';
import { Download } from "@mui/icons-material";
import { resultTableWallet } from '../../app/modules/reports/pages/wallet/WalletUIContext';
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelFile = ReactExport.ExcelFile;

export interface ButtoExcelProps{
    data: resultTableWallet
}

const ButtonExcel:React.FC<ButtoExcelProps> = ({
    data
}) => {

    const aux = data.data.entities.map((d)=>{
        return [
            {value: d.name},
            {value: d.totalDebts},
            {value: d.totalPayments},
            {value: d.balance},
        ]
    })
   
    const dara = [
        {
            columns:  [
                {title: "CLIENTE/FÁBRICA", style: {font: {sz: "12", bold: true},fill: {patternType: "solid", fgColor: {rgb: "DDDDDD"}}}},//pixels width 
                {title: "TOTAL DEUDAS", style: {font: {sz: "12", bold: true},fill: {patternType: "solid", fgColor: {rgb: "DDDDDD"}}}},//char width 
                {title: "TOTAL ABONOS", style: {font: {sz: "12", bold: true},fill: {patternType: "solid", fgColor: {rgb: "DDDDDD"}}}},
                {title: "SALDO PENDIENTE", style: {font: {sz: "12", bold: true},fill: {patternType: "solid", fgColor: {rgb: "DDDDDD"}}}},
            ],
            data: aux
        }
    ]

    return(
        <ExcelFile 
            element={
                <button
                    type="button"
                    className="btn btn-success btn-sm mx-1"
                >
                    Descargar Excel <Download />
                </button>
            }
            filename="ReporteGeneral"
        >
            <ExcelSheet dataSet={dara} name="CarteraExcel"/>
        </ExcelFile>
    )
}


export default ButtonExcel;