import React from "react";

export interface CheckboxProps {
  isSelected: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  disabled: boolean
}
export const Checkbox: React.FC<CheckboxProps> = ({ isSelected, onChange, children,disabled }) => {
  return (
    <>
      <input type="checkbox" style={{display: "none"}} />
      <label className="checkbox checkbox-lg checkbox-single">
        <input type="checkbox" checked={isSelected} onChange={onChange} disabled={disabled}/>
        {children}
        <span />
      </label>
    </>
  );
}
