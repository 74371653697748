import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/partials/control";
import { ProductStocksFilter } from "./productStocks-filter/ProductStocksFilter";
import { ProductStocksTable } from "./productStock-table/ProductStocksTable";
// import { ProductStocksGrouping } from "./productStocks-grouping/ProductStocksGrouping";
import { useProductStocksUIContext, ProductStocksUIContextModel } from './ProductStocksUIContext';
import { useIntl } from "react-intl";
import { PreloadingPage } from "../../../../jsxcomponents/PreloadingPage";
import { useHistory } from "react-router-dom";

export function ProductStocksCard() {
  const intl = useIntl();
  const history = useHistory();
  const productStocksUIContext = (useProductStocksUIContext() as ProductStocksUIContextModel);
  const productStocksUIProps = useMemo(() => {
    return {
      ids: productStocksUIContext.ids,
      newProductStockButtonClick: productStocksUIContext.newProductStockButtonClick,
      productStockButtonClick: productStocksUIContext.productStockButtonClick,
      isLoading: productStocksUIContext.isLoading
    };
  }, [productStocksUIContext]);

  return (
    <Card>
      <PreloadingPage
        isVisible={productStocksUIProps.isLoading}
      />
      <CardHeader className="d-flex justify-content-start align-items-center" title={intl.formatMessage({id: "ORDER.ORDERS"})}>
        <CardHeaderToolbar>
          <button className="btn btn-primary" onClick={()=> history.goBack() }>
            <i className="fa fa-arrow-left"></i>
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ProductStocksFilter />
        {/* {productStocksUIProps.ids.length > 0 && <ProductStocksGrouping />} */}
        <ProductStocksTable />
      </CardBody>
    </Card>
  );
}



