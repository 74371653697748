// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useRef, useMemo, useEffect} from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, FormikProps } from "formik";
import * as Yup from "yup";
import {
  Input, Select,
} from "../../../../../../_metronic/partials/control";
import axios from 'axios';
import { useIntl } from "react-intl";
import { shallowEqual, useSelector } from 'react-redux';
import { ProductStockModel } from "../models";
import { UserComplete } from "../../../../auth/models/UserModel";
import { RootState } from '../../../../../../setup/redux/RootReducer';
import { ProductStocksLoadingDialog } from '../productStocks-loading-dialog/ProductStocksLoadingDialog';
import TextAreaInput from '../../../../../../_metronic/partials/control/forms/TextAreaInput';
import { InputNumber } from '../../../../../../_metronic/partials/control/forms/InputNumber';
import useLoadForms from '../components/hooks/useLoadForms';
import { ProductOrderInterfaceForm } from '../../../../admin/pages/products/models/index';
import useIntlCurrencyFormat from '../../../../../../utils/customHooks/useIntlCurrencyFormat';
import DeleteZerosHelper from '../../../../../../utils/functions/DeleteZerosHelper';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers/AssetHelpers';
import Swal from 'sweetalert2';
import ClientInfo from '../components/ClientInfo';
// import { getDifference } from '../../../../../../utils/differenceObjArr';
import { generateSalesOrder } from '../../../../../../utils/PDF/SalesOrder';
import { useProductStocksUIContext, ProductStocksUIContextModel} from '../ProductStocksUIContext';
import SupplyTable from '../components/SupplyTable';

export interface ProductStockEditFormProps {
  saveProductStock: (productStock: ProductStockModel) => void;
  productStock?: Partial<ProductStockModel>
  actionsLoading?: boolean;
  onHide?: () => void;
}

export const ProductStockEditForm: React.FC<ProductStockEditFormProps> = ({
  saveProductStock,
  productStock,
  actionsLoading,
  onHide,
}) => {
  const userComplete: UserComplete = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserComplete
  const [addProduct, setAddProduct] = useState<ProductOrderInterfaceForm[]>([]);
  const [subTotal, setSubTotal] = useState(0);
  const [discount,setDiscount] = useState(0);
  const [totalPurchase,setTotalPurchase] = useState(0);
  const [auxFactory,setAuxFactory] = useState(0);
  const [auxProductId,setAuxProductId] = useState(0);
  const [avaliableSupplies,setAvaliableSupplies] = useState([]); //Inventario de insumo por fabrica
  const [auxQuantity,setAuxQuantity] = useState(0);

  const formRef = useRef<FormikProps<ProductStockModel>>(null);
  const {
    clients,
    factories,
    products,
    allProducts,
    setProducts,
    selectedProducts,
    setSelectedProduct,
  } = useLoadForms({
    productStock,
    setAddProduct,
    formRef,
  });
  const {cop} = useIntlCurrencyFormat();
  const intl = useIntl();
  const idProductStock = productStock?.id;
  // Validation schema
  const ProductStockEditSchema = Yup.object().shape({

  });

  //Contexto
  const productStocksUIContext = (useProductStocksUIContext() as ProductStocksUIContextModel);
  const productStocksUIProps = useMemo(() => {
    return {
     factoryId: productStocksUIContext.factoryId,
     setFactoryId: productStocksUIContext.setFactoryId
    };
  }, [productStocksUIContext]);

  //Validar si existen suficientes insumos para agregar el producto
  const handleQuantitySupply = (quantity:number): boolean[] => {
    const validator:boolean[] = []
    const suppliesAux = products.find(p => p.id === auxProductId)
    //@ts-ignore
    suppliesAux.supplies.map(s => {
      //@ts-ignore
      const aux = avaliableSupplies.find(a => Number(a.supplyId) === Number(s.supplyId))
      if(aux){
        //@ts-ignore
        if(aux.currentQuantity < (s.quantity * quantity)){
          validator.push(false)
        }else{
          validator.push(true)
          //@ts-ignore
          const aux2 = avaliableSupplies.find(a => Number(a.supplyId) === Number(s.supplyId));
          //@ts-ignore
          aux2.currentQuantity = aux2.currentQuantity - (s.quantity * quantity);
          // setAvaliableSupplies(aux2 ? [...aux2] : [])
        }
      }else{
        validator.push(false)
      }
    })
    return validator
  }


  /**
   * @param setFieldValue
   * @param values
   * @description Funcion encargada de agregar Productos a la tabla
   */
  const handleAddSupply = (setFieldValue:(field: string, value: any, shouldValidate?: boolean | undefined) => void, values:ProductStockModel) => {
    const aux = addProduct;

    //@ts-ignore
    if(!values?.factory){
      return Swal.fire({
        title: 'Por favor seleccione una fábrica',
        icon: 'warning',
      })
    }

    if(values?.quantity === 0 || !selectedProducts){
      return Swal.fire({
        title: 'Por favor ingrese todos los valores',
        icon: 'warning',
      })
    }

    //*Validar si alcanzan los insumos
    const validator = handleQuantitySupply(values?.quantity);
    if(!(validator.reduce((a,b) => a && b))){
      return Swal.fire({
        title: 'No hay suficientes insumos para este producto',
        icon: 'warning',
      })
    }
    aux.push({
      quantity: (values?.quantity as number),
      // price: (values?.price as number),
      id: (selectedProducts as unknown as ProductOrderInterfaceForm).id,
      name: (selectedProducts as unknown as ProductOrderInterfaceForm).name,
      // cost: (selectedProducts as unknown as ProductOrderInterfaceForm).cost,
      // mge: (selectedProducts as unknown as ProductOrderInterfaceForm).mge,
      // suggestedPrice: (selectedProducts as unknown as ProductOrderInterfaceForm).suggestedPrice,
      //@ts-ignore
      supplies: selectedProducts.supplies
    });

    setFieldValue('values', JSON.stringify(aux));
    setAddProduct(aux);
    setFieldValue('quantity',0)
    setFieldValue('price',0)
    //setFieldValue('discount',0)
    setFieldValue('suggestedPrice',0)
    setFieldValue('productId',0)
    const aux2 = products.filter(product => product.id !== (selectedProducts as unknown as ProductOrderInterfaceForm).id);
    setProducts(aux2);
    setSelectedProduct(null);
    setAuxQuantity(0);
    setAuxProductId(0)//!Ojo con esto
  }



  /**
   * @param index
   * @param supplyTypeId
   * @param setFieldValue
   * @description Funcion encargada de eliminar Productos de la tabla
   */
  const deleteProduct = (index: number, supplyTypeId: number, setFieldValue:(field: string, value: any, shouldValidate?: boolean | undefined) => void) => {
    const addedProduct = addProduct.find(a => a.id === supplyTypeId)
    // const suppliesAux = addProduct.find(p => p.id === supplyTypeId)

    //@ts-ignore
    addedProduct?.supplies.map(s => {
      //@ts-ignore
      const aux2 = avaliableSupplies.find(a => Number(a.supplyId) === Number(s.supplyId));
      //@ts-ignore
      aux2.currentQuantity = aux2.currentQuantity + (Number(s.quantity) * Number(addedProduct?.quantity));
    })

    const aux = addProduct.filter((s, i)=> i !== index);
    if(aux.length === 0){
      setFieldValue('values', "");
    } else {
      setFieldValue('values', JSON.stringify(aux));
    }
    const productAux = products;
    productAux.push(allProducts.find((s) => Number(s.id) === Number(supplyTypeId)) as ProductOrderInterfaceForm)
    setProducts(productAux);
    setAddProduct(aux);
    setAuxQuantity(0);
    setAuxProductId(0); //!OJO ACA
  }



  //Si se cambia la empresa se borran los productos seleccionados
  useEffect(() => {
    setAddProduct([])
  },[productStocksUIProps.factoryId])


  const handleCostProducts = () => {
    if(addProduct){
      const totalTemp = addProduct.reduce((a,b:ProductOrderInterfaceForm) => a + ((b?.price ? b.price : 0) * (b?.quantity ? b.quantity : 1)) ,0)
      setSubTotal(totalTemp)
    }
  }

  useEffect(() => {
    if(productStock && productStock.id){
      setTotalPurchase(productStock.totalPurchase ? productStock.totalPurchase : 0);
      setSubTotal(productStock.subTotal ? productStock.subTotal : 0);
      setDiscount(productStock.discount ? productStock.discount : 0);
    }
  },[])

  useEffect(() => {
    handleCostProducts();
  },[addProduct.length])

  useEffect(() => {
    setTotalPurchase(subTotal - discount)
  },[discount,subTotal])


  //Traer inventario disponible de la fabrica
  useEffect(() => {
    const loadAvaliableSupplies = async () => {
      try{
        if(auxFactory !== 0){
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/factory/getSupplyInventory/${auxFactory}`);
          setAvaliableSupplies(response.data);
        }
      }catch(error){
        console.log('error getting inventory',error)
      }
    }

    loadAvaliableSupplies();
  },[auxFactory])



  return (
    <>
      {
        actionsLoading ? <ProductStocksLoadingDialog color={"primary"}/> :
          <Formik
            enableReinitialize={true}
            initialValues={(productStock as ProductStockModel)}
            validationSchema={ProductStockEditSchema}
            onSubmit={(values) => {
              const vall = {
                ...values,
                companyId : userComplete.company.id,
              }
              if(addProduct.length === 0) return Swal.fire({
                title: 'Por favor ingrese al menos un producto',
                icon: 'warning',
              })
              saveProductStock(vall);
            }}
          >
            {({ handleSubmit, values, setFieldValue }) => (
              <>
                <Modal.Body className="cursor-default overlay overlay-block ">
                  {actionsLoading && (
                    <div className="overlay-layer bg-transparent">
                      <div className="spinner spinner-lg spinner-success" />
                    </div>
                  )}
                  <Form className="form form-label-right" onSubmit={handleSubmit}>

                    {/* Cliente y Fábrica */}
                    <div className="form-group row mb-7">
                      <div className="col-lg-6">
                        <Field
                          name="reference"
                          component={Input}
                          placeholder={"Referencia"}
                          type="text"
                          disabled={productStock?.id ? true : false}
                          label={intl.formatMessage({id: "ORDER.REFERENCE"})}
                        />
                      </div>
                      <div className="col-lg-6">
                        <Field name="factory" label="Fabrica" disabled={productStock?.id ? true : false} onChange={(e:any) =>{
                            productStocksUIProps.setFactoryId(Number(e.target.value))
                            setFieldValue("factory",e.target.value)
                            setAuxFactory(Number(e.target.value))
                            setAuxProductId(0)
                          }}
                          component={Select} 
                        >
                          <option value={0}>{"Escoja una fabrica"}</option>
                          {factories.map((f,index) => (
                            <option value={f.id} key={index}>{f.name}</option>
                          ))}
                        </Field>
                      </div>
                    </div>
                    {
                      <ClientInfo
                        //@ts-ignore
                        client={clients.find(c=> Number(c.id) === Number(values.client))}
                      />
                    }

                    {/* Descripción del pedido */}
                    <div className="form-group row my-7">
                      {/* Descripcion */}
                      <div className="col-lg-12">
                        <Field
                          name="description"
                          component={TextAreaInput}
                          placeholder={intl.formatMessage({ id: "GENERAL.DESCRIPTION"})}
                          label={intl.formatMessage({ id: "GENERAL.DESCRIPTION"})}
                          disabled={productStock?.id ? true : false}
                        />
                      </div>
                    </div>

                    {/* Producto y Precio sugerido*/}
                    { !productStock?.id &&
                      (<div className="form-group row my-7">
                        {/* Producto */}
                        <div className="col-lg-6 mt-7 mt-lg-0">
                          {/* PRODUCTO */}
                          {/* @ts-ignore */}
                          <Field name="productId" label={intl.formatMessage({id:"ORDER.PRODUCT"})}
                            onChange={(e:any)=>{
                              setFieldValue('productId', e.target.value);
                              setAuxProductId(Number(e.target.value))
                              if(e.target.value > 0){
                                const aux = allProducts.find(s => Number(s.id) === Number(e.target.value))
                                setSelectedProduct(aux as ProductOrderInterfaceForm)
                                setFieldValue('suggestedPrice', DeleteZerosHelper(cop.format((aux as ProductOrderInterfaceForm).suggestedPrice)))
                              } else {
                                setSelectedProduct(null)
                                setFieldValue('suggestedPrice', DeleteZerosHelper(cop.format(0)))
                              }
                            }}
                            component={Select}
                          >
                            <option value={0}>{intl.formatMessage({id: "ORDER.CHOOSE_PRODUCT"})}</option>
                            {products.map((item: any, index:number) => (
                              // @ts-ignore
                              item.deletedAt === null &&(<option key={index} value={item.id}>{item.name}</option>)
                            ))}
                          </Field>
                        </div>
                        {/* Cantidad */}
                        <div className="col-lg-6 mt-7 mt-lg-0">
                          <Field
                            type="number"
                            name="quantity"
                            component={InputNumber}
                            placeholder={intl.formatMessage({ id: "ORDER.QUANTITY"})}
                            label={intl.formatMessage({ id: "ORDER.QUANTITY"})}
                            onChange={(e:any) => {
                              setFieldValue('quantity',Number(e.target.value))
                              setAuxQuantity(Number(e.target.value))
                            }}
                          />
                        </div>
                      </div>)
                    }

                    {/* Precio, cantidad y descuento */}

                    {
                      auxProductId !== 0 &&
                      <SupplyTable
                        avaliableSupplies={avaliableSupplies}
                        productId={auxProductId}
                        products={products}
                        quantityProduct={auxQuantity}
                      />
                    }


                    {/*  Boton agregar */}
                    { !productStock?.id  &&
                      (<div className="form-group row my-0 d-flex justify-content-end gap-2">
                        <div className="col-lg-3">
                              <button className="btn btn-primary btn-elevate btn-block" onClick={()=> handleAddSupply(setFieldValue, values)}
                                type="button"
                              >
                                <i className="fas fa-plus"></i>{"Agregar"}
                              </button>
                        </div>
                      </div>)
                    }


                    {
                      addProduct.length > 0 &&
                        <div className="form-group row my-7">
                          <div className="table-responsive">
                              <table className="styled-table-success">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>{"Producto"}</th>
                                    <th>{"Cantidad"}</th>
                                    {!productStock?.id && (<th>{"Acción"}</th>)}
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    addProduct.map((item, index)=>(
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{allProducts.find(s => s.id === item.id)?.name}</td>
                                        <td>{item.quantity}</td>
                                        {!productStock?.id &&(<td>
                                            <a
                                              className="btn btn-icon btn-danger btn-hover-danger btn-sm mx-1 "
                                              onClick={() => {
                                                setAuxProductId(item.id)
                                                deleteProduct(index, item.id,setFieldValue)
                                              }}
                                            >
                                              <span className="svg-icon svg-icon-md svg-icon-danger">
                                                <SVG
                                                  src={toAbsoluteUrl("/media/svg/icons/Home/Trash.svg")}
                                                />
                                              </span>
                                            </a>
                                        </td>)}
                                      </tr>
                                    ))
                                  }
                                </tbody>
                              </table>
                          </div>
                        </div>
                    }

                  { addProduct.length > 0 && <div></div>
                    // <div className="form-group row my-7">
                    //    {/* Descuento */}
                    //    <div className="col-lg-4 mt-7 mt-lg-0">
                    //     <Field
                    //       type="number"
                    //       name="discount"
                    //       disabled={productStock?.id ? true : false}
                    //       component={InputNumber}
                    //       placeholder={intl.formatMessage({ id: "ORDER.DISCOUNT"})}
                    //       label={intl.formatMessage({ id: "ORDER.DISCOUNT"})}
                    //       onChange={(e:any) => {
                    //         setFieldValue('discount',Number(e.target.value))
                    //         setDiscount(Number(e.target.value))
                    //       }}
                    //     />
                    // </div>

                    //   {/* SUBTOTAL */}
                    //   <div className="col-lg-4 mt-7 mt-lg-0">
                    //     <Field
                    //       name="subTotal"
                    //       disabled
                    //       component={Input}
                    //       type="text"
                    //       label={"Subtotal"}
                    //       value={DeleteZerosHelper(cop?.format(subTotal))}
                    //     />
                    //   </div>

                    //   {/* TOTAL  */}
                    //   <div className="col-lg-4 mt-7 mt-lg-0">
                    //     <Field
                    //       name="totalPurchase"
                    //       disabled
                    //       component={Input}
                    //       type="text"
                    //       label={"Total"}
                    //       value={DeleteZerosHelper(cop?.format(totalPurchase))}
                    //     />
                    //   </div>

                    // </div>
                  }

                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    onClick={onHide}
                    className="btn btn-light btn-elevate"
                  >
                    {intl.formatMessage({ id: "BUTTON.CANCEL"})}
                  </button>
                  <> </>
                  <button
                    type="submit"
                    onClick={() => handleSubmit()}
                    className="btn btn-primary btn-elevate"
                  >
                    {intl.formatMessage({ id: "BUTTON.SAVE"})}
                  </button>
                  <></>
                  {idProductStock &&(<button
                    type="button"
                    onClick={() =>
                      // @ts-ignore
                      generateSalesOrder(productStock,cop.format)
                    }
                    className = "btn btn-success"
                  >
                    {intl.formatMessage({id:"ORDER.GENERATE.ORDER"})}
                  </button>)}
                </Modal.Footer>
              </>
            )}
          </Formik>
      }
    </>
  );
}



