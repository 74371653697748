import type {
  BillingProfits,
  QueryParams,
} from '../ProfitsInvestorsUIContext'
import axios from 'axios'

const useBillingProfits = (queryParams: QueryParams) => {
  
  const getBillingProfits = async () => {
    try {
      const response = await axios.get<BillingProfits[]>(
        `${process.env.REACT_APP_API_URL}/billing/getBillingsProfitsInv`,
        {
          params: {
            clientId: queryParams.clientId ?? undefined,
            factoryId: queryParams.factoryId ?? undefined,
            product: queryParams.product,
            startDate: queryParams.startDate ?? undefined,
            endDate: queryParams.endDate ?? undefined,
          },
        }
      )
      return response.data
    } catch (error) {
      console.log(error)
      return []
    }
  }

  return getBillingProfits
}

export default useBillingProfits
