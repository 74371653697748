import React, { useState, useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import {ModalProgressBar} from "../../../../../../_metronic/partials/control";
import { useIntl } from "react-intl";
import { usePurchasesUIContext, PurchasesUIContextModel } from '../PurchasesUIContext';

export interface PurchaseEditDialogHeaderProps {
  id?: number
}
export const  PurchaseEditDialogHeader: React.FC<PurchaseEditDialogHeaderProps> = ({ id }) => {

  const purchasesUIContext = (usePurchasesUIContext() as PurchasesUIContextModel);
  const purchasesUIProps = useMemo(() => {
    return {
      selectedPurchase: purchasesUIContext.selectedPurchase,
      loadPurchase: purchasesUIContext.loadPurchase,
    };
  }, [purchasesUIContext]);
  const intl = useIntl();
  const [title, setTitle] = useState(intl.formatMessage({ id: "PURCHASE.PURCHASE"}));
  // Title couting
  useEffect(() => {
    let _title = id ? "" : intl.formatMessage({ id: "PURCHASE.PURCHASE"});
    if (!purchasesUIProps.loadPurchase && id) {
      _title = `Editar compra ${purchasesUIProps.selectedPurchase?.reference}`;
    } 
    if (purchasesUIProps.loadPurchase) {
      _title = "Cargando "
    }

    setTitle(_title);

    // eslint-disable-next-line
  }, [purchasesUIProps.loadPurchase, purchasesUIProps.selectedPurchase]);

  return (
    <>
      {false && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <div className="d-flex">{title} { purchasesUIProps.loadPurchase && <div className = "dot-shuttle"/> }</div>
        </Modal.Title>
      </Modal.Header>
    </>
  );
}


