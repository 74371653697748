import React, {useMemo, useState} from 'react'
import {Formik} from 'formik'
import {isEqual} from 'lodash'
import {usePurchasesUIContext, PurchasesUIContextModel} from '../PurchasesUIContext'
import {useIntl} from 'react-intl'
import useDebounceFnc from '../../../../../../utils/customHooks/useDebounceFnc'
import DatePicker from 'react-datepicker'
import moment from 'moment'

interface filterInterface {
  factoryId: number | undefined
  providerId: number | undefined
  type: number | undefined
  reference: string
  supplies: string
	end?: string;
	start?: string;
}
interface filterValues {
  factoryId: string
  providerId: string
  type: string
  searchText: string
  supplies: string
	end: string;
	start: string;
}
const prepareFilter = (queryParams: any, values: filterValues) => {
  const {factoryId, providerId, searchText, supplies, end, start} = values

  const newQueryParams = {...queryParams}
  const filter: filterInterface = {
    factoryId: undefined,
    providerId: undefined,
    type: undefined,
    reference: '',
    supplies: '',
		end: undefined,
		start: undefined,
  }

  // Filter by factoryId
  filter.factoryId = factoryId !== '' ? +factoryId : undefined

  //Filter by providerId
  filter.providerId = providerId !== '' ? +providerId : undefined

  //Filter by reference
  if (searchText) {
    filter.reference = searchText
  }
  if (supplies) {
    filter.supplies = supplies
  }

	if (end) {
		filter.end = end;
	}

	if (start) {
		filter.start = start;
	}

  newQueryParams.filter = filter
  return newQueryParams
}

// export interface PurchasesFilterProp {
//   listLoading: boolean
// }
export const PurchasesFilter: React.FC<{}> = () => {
  // Purchases UI Context
  const intl = useIntl()
  const purchasesUIContext = usePurchasesUIContext() as PurchasesUIContextModel
  const purchasesUIProps = useMemo(() => {
    return {
      queryParams: purchasesUIContext.queryParams,
      setQueryParams: purchasesUIContext.setQueryParams,
      factories: purchasesUIContext.factories,
      providers: purchasesUIContext.providers,
    }
  }, [purchasesUIContext])
  const [start, setStart] = useState<Date | null>(moment().add(-1,'M').toDate())
  const [end, setEnd] = useState<Date | null>(moment().toDate())

  // queryParams, setQueryParams,
  const applyFilter = useDebounceFnc((values: filterValues) => {
    const newQueryParams = prepareFilter(purchasesUIProps.queryParams, values)
    if (!isEqual(newQueryParams, purchasesUIProps.queryParams)) {
      newQueryParams.pageNumber = 1
      // update list by queryParams
      purchasesUIProps.setQueryParams(newQueryParams)
    }
  }, 400)

  return (
    <>
      <Formik
        initialValues={{
          factoryId: '',
          providerId: '',
          type: '', // values => All=""/Business=0/Individual=1
          supplies: '',
          searchText: '',
					start: moment().add(-1,'M').format("YYYY-MM-DD"),
					end: moment().format("YYYY-MM-DD"),
        }}
        onSubmit={(values) => {
          applyFilter(values)
        }}
      >
        {({values, handleSubmit, handleBlur, handleChange, setFieldValue}) => (
          <form onSubmit={handleSubmit} className='form form-label-right'>
            <div className='form-group row'>
              {/* FILTRAR POR FABRICA */}
              <div className='col-lg-3'>
                <select
                  className='form-control'
                  name='factoryId'
                  placeholder={intl.formatMessage({id: 'LABELS.FILTER.SEARCH_BY_FACTORY'})}
                  onChange={(e) => {
                    setFieldValue('factoryId', e.target.value)
                    handleSubmit()
                  }}
                  onBlur={handleBlur}
                  value={values.factoryId}
                >
                  <option value={''}>
                    {intl.formatMessage({id: 'LABELS.FILTER.CHOOSE_FACTORY'})}
                  </option>
                  {purchasesUIProps.factories.map((f: any, index: number) => (
                    <option value={f.id} key={index}>
                      {f.name}
                    </option>
                  ))}
                </select>
                <small className='form-text text-muted'>
                  {intl.formatMessage({id: 'LABELS.FILTER.SEARCH_BY_FACTORY'})}
                </small>
              </div>

              {/* FILTRAR POR PROVEEDOR */}
              <div className='col-lg-3'>
                <select
                  className='form-control'
                  name='providerId'
                  placeholder={intl.formatMessage({id: 'LABELS.FILTER.SEARCH_BY_PROVIDER'})}
                  onChange={(e) => {
                    setFieldValue('providerId', e.target.value)
                    handleSubmit()
                  }}
                  onBlur={handleBlur}
                  value={values.providerId}
                >
                  <option value={''}>
                    {intl.formatMessage({id: 'LABELS.FILTER.CHOOSE_PROVIDER'})}
                  </option>
                  {purchasesUIProps.providers.map((p: any, index: number) => (
                    <option value={p.id} key={index}>
                      {p.name}
                    </option>
                  ))}
                </select>
                <small className='form-text text-muted'>
                  {intl.formatMessage({id: 'LABELS.FILTER.SEARCH_BY_PROVIDER'})}
                </small>
              </div>

              <div className='col-lg-3'>
                <input
                  type='text'
                  className='form-control'
                  name='searchText'
                  placeholder={intl.formatMessage({id: 'LABELS.FILTER.SEARCH'})}
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue('searchText', e.target.value)
                    handleSubmit()
                  }}
                />
                <small className='form-text text-muted'>
                  {intl.formatMessage({id: 'LABELS.FILTER.SEARCH_BY_REFERENCE'})}
                </small>
              </div>
              <div className='col-lg-3'>
                <input
                  type='text'
                  className='form-control'
                  name='supplies'
                  placeholder={intl.formatMessage({id: 'LABELS.FILTER.SEARCH'})}
                  onBlur={handleBlur}
                  value={values.supplies}
                  onChange={(e) => {
                    setFieldValue('supplies', e.target.value)
                    handleSubmit()
                  }}
                />
                <small className='form-text text-muted'>Filtrar por insumos</small>
              </div>

              {/* FILTRAR POR START_DATE */}
              <div className='col-lg-3 mb-2'>
                <DatePicker
                  className='form-control'
                  selected={start}
                  isClearable
                  onChange={(date) => {
                    setStart(date)
                    if (date === null) {
                      setFieldValue('start', '')
                      handleSubmit()
                      return
                    }
                    setFieldValue(
                      'start',
                      moment(date).format('YYYY-MM-DD')
                    )
                    handleSubmit()
                  }}
                />
                <small className='form-text text-muted'>Filtrar por fecha de inicio</small>
              </div>

              {/* FILTRAR POR END_DATE */}
              <div className='col-lg-3 mb-2'>
                <DatePicker
                  className='form-control'
                  selected={end}
                  isClearable
                  onChange={(date) => {
                    setEnd(date)
                    if (date === null) {
                      setFieldValue('end', '');
                      handleSubmit()
                      return
                    }
                    setFieldValue(
                      'end',
                      moment(date).format('YYYY-MM-DD')
                    )
                    handleSubmit()
                  }}
                />
                <small className='form-text text-muted'>Filtrar por fecha final</small>
              </div>

            </div>
          </form>
        )}
      </Formik>
    </>
  )
}
