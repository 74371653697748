// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import {IntlShape} from 'react-intl'
import ReactTooltip from 'react-tooltip'
import {Visibility} from '@mui/icons-material'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers/AssetHelpers'
import SVG from 'react-inlinesvg'
import {OverlayTrigger, Tooltip} from 'react-bootstrap-v5'

interface ActionActionProps {
  openEditLoanDialog: any
  openDeleteLoanDialog: any
  intl: IntlShape
}

export const ActionsColumnFormatter = (
  cellContent: any,
  row: any,
  rowIndex: any,
  {openEditLoanDialog, openDeleteLoanDialog, intl}: ActionActionProps
) => {
  return (
    <>
      {/* {row.status ? 'error' : 'success'} */}
      <OverlayTrigger
        placement='top'
        delay={{
          show: 500,
          hide: 200,
        }}
        overlay={<Tooltip id='tooltip'>Ver información</Tooltip>}
      >
        <button
          data-tip
          data-for='editTip'
          className='btn btn-icon btn-primary btn-sm mx-1'
          onClick={() => openEditLoanDialog(row.id)}
        >
          <span className='svg-icon svg-icon-md svg-icon-primary'>
            <Visibility />
          </span>
        </button>
      </OverlayTrigger>
      <OverlayTrigger
        placement='top'
        delay={{
          show: 500,
          hide: 200,
        }}
        overlay={<Tooltip id='tooltip'>{intl.formatMessage({id: 'GENERAL.DELETE_LABEL'})}</Tooltip>}
      >
        <button
          data-tip
          data-for={`fullDelete`}
          className={`btn btn-danger btn-icon btn-sm mx-1`}
          // ? delete = 2
          onClick={() => openDeleteLoanDialog(row.id, 2)}
        >
          <span className={`svg-icon svg-icon-md svg-icon-danger`}>
            <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
          </span>
        </button>
      </OverlayTrigger>
    </>
  )
}
