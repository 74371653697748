import React, { useState, useEffect } from 'react'
// import { Link } from 'react-router-dom';
import {useFormik} from 'formik'
import * as Yup from 'yup';
import clsx from 'clsx';
import { decrypt } from '../../../../utils/AES';
import { useHistory } from 'react-router-dom';
// import { toAbsoluteUrl } from '../../../../_metronic/helpers/AssetHelpers';

const loginSchema = Yup.object().shape({
    code: Yup.string()
      .min(1, 'Minimum 1 symbols')
      .max(6, 'Maximum 6 symbols')
      .required('Code is required'),
  })

const initialValues = {
    code: ''
}

type User = {
  email: string,
  name: string,
  secret: string,
}

const TwoFactorAuthentication = () => {
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState<User | null>(null);
    const history = useHistory();
    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            setTimeout(() => {
              try {
              } catch (error) {
                
              } finally {
                setLoading(false);
              }
            }, 1000)
        },
    });
    useEffect(()=>{
      if(formik.values.code.length === 6){
        formik.handleSubmit();
      }
    }, [formik.values.code])
    useEffect(()=>{
      const getTfa = () => {
        try {
          const dataEncrypted = (localStorage.getItem('tfa') as string);
          const dataDecrypted: User = decrypt(dataEncrypted);
          if(!dataDecrypted.secret) return history.push('/auth/login');
          setUser(dataDecrypted);
        } catch (error) {
          history.push('/auth/login');
        }
      }
      getTfa();
    },[])
  return (
    <form>
        <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>{user?.name}</h1>
        {/* Begin Alert Error */}
        <div className='text-gray-400'>
            {"Por favor abra su aplicacion de doble autenticacion (TOTP) en su dispositivo móvil e ingrese el codigo generado."}
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Code</label>
        <input
          placeholder='6 digit code'
          {...formik.getFieldProps('code')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.code && formik.errors.code},
            {
              'is-valid': formik.touched.code && !formik.errors.code,
            }
          )}
          type='text'
          name='code'
          autoComplete='off'
        />
        {formik.touched.code && formik.errors.code && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.code}</span>
          </div>
        )}
      </div>

      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Verificar</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  )
}

export {TwoFactorAuthentication}