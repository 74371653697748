import { ProductModel } from '../../../../admin/pages/products/models/index';
import { BillingModel, AvailableProduct } from '../models/index';
import { Moment } from 'moment';
import Swal from 'sweetalert2';
import { ClientModel } from '../../../../admin/pages/client/models/index';
// ? interfaces
interface SubmitValidationFormProps {
    values: BillingModel;
    selectedClient?: ClientModel;
    availableProduct: AvailableProduct[];
    billingDate: Moment | null;
    lastBilled: string;
}
// ? Functions
export const validateBillingForm = async (selectedProduct: ProductModel | null, values:BillingModel):Promise<Boolean> => {
    if(!selectedProduct){
        Swal.fire({
            title: "Por favor seleccione un producto",
            icon: "warning",
        })
        return false
    }
    if (!values.quantity){
        Swal.fire({
            title: "Por favor ingrese una cantidad valida del producto",
            icon: "warning"
        })
        return false
    } else if (isNaN(values.quantity)){
        Swal.fire({
            title: "Por favor ingrese una valor valido",
            icon: "warning"
        })
        return false
    }
    if(!values.price){
        Swal.fire({
            title: "Por favor ingrese un valor de precio correcto",
            icon: "warning"
        })
        return false
    } else if (isNaN(values.price)) {
        Swal.fire({
            title: "Por favor ingrese una valor válido",
            icon: "warning"
        })
        return false
    }
    if(Number(values.quantity) > Number(selectedProduct?.quantity)){
        Swal.fire({
            title: "No se puede escoger cantidad mayor a la disponible",
            icon: "warning",
        })
        return false;
    }
    if(+values.price < (+selectedProduct.suggestedPrice as number) ){
        const res = await Swal.fire({
            title: "El precio digitado es menor al precio sugerido. \n ¿Estás seguro que desea continuar?",
            icon: "warning",
            showCancelButton: true,
        })
        if (!res.isConfirmed){
            return false
        }
    }
    return true;
}


export const submitValidationForm = async (data:SubmitValidationFormProps) => {
    const {values, selectedClient, availableProduct, billingDate, lastBilled} = data;
    if(!values.reference && !lastBilled) return Swal.fire({
        title: "Por favor digite una referencia",
        icon: "warning",
    }).then(()=> false)
    if(!selectedClient?.id) return Swal.fire({
        title: "Por favor seleccione un cliente",
        icon: "warning",
    }).then(()=> false)
    if(!values.factoryId) return Swal.fire({
        title: "Por favor seleccione un Fabrica",
        icon: "warning",
    }).then(()=> false)
    if(availableProduct.length === 0) return Swal.fire({
        title: "Por favor seleccione al menos un producto",
        icon: "warning"
    }).then(()=> false)
    if(!billingDate) return Swal.fire({
        title: "Por favor ingrese una fecha valida",
        icon: "warning"
    }).then(()=> false)
    return true
}
