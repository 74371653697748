import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/partials/control";
import { BillingsFilter } from "./billings-filter/BillingsFilter";
import { BillingsTable } from "./billings-table/BillingsTable";
// import { BillingsGrouping } from "./billings-grouping/BillingsGrouping";
import { useBillingsUIContext, BillingsUIContextModel } from './BillingsUIContext';
import { useIntl } from "react-intl";
import { PreloadingPage } from "../../../../jsxcomponents/PreloadingPage";
import { DatePicker } from "antd";

export function BillingsCard() {
  const intl = useIntl();
  const billingsUIContext = (useBillingsUIContext() as BillingsUIContextModel);
  const billingsUIProps = useMemo(() => {
    return {
      ids: billingsUIContext.ids,
      newBillingButtonClick: billingsUIContext.newBillingButtonClick,
      isLoading: billingsUIContext.isLoading
    };
  }, [billingsUIContext]);

  return (
    <Card>
      <PreloadingPage
        isVisible={billingsUIProps.isLoading}
      />
      <CardHeader title={intl.formatMessage({id: "BILLING.BILLINGS"})}>
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={billingsUIProps.newBillingButtonClick}
          >
            {intl.formatMessage({id: "BILLING.NEW_BILLING"})}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <BillingsFilter />
        {/* {billingsUIProps.ids.length > 0 && <BillingsGrouping />} */}
        <BillingsTable />
      </CardBody>
    </Card>
  );
}



