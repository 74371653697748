import {useMemo} from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from '../../../../../_metronic/partials/control'

import {useProfitsUIContext} from './ProfitsUIContext'
import {ProfitsFilter} from './profits-filter/ProfitsFilter'
import useIntlCurrencyFormat from '../../../../../utils/customHooks/useIntlCurrencyFormat'
import useDeleteZeros from '../../../../../utils/functions/DeleteZerosHelper'
import {Col, Row} from 'react-bootstrap'
import {initialFilter} from './ProfitsUIHelpers'
import useBillingProfitsSumary from './hooks/useBillingProfitsSumary'
import useBillingProfits from './hooks/useBillingProfits'
import {Download} from '@mui/icons-material'
import useGenerateExel from './hooks/useGenerateExel'

export function ProfitsCard() {
  const profitsUIContext = useProfitsUIContext()
  const {cop, thousand} = useIntlCurrencyFormat()
  const profitsUIProps = useMemo(() => {
    return {
      queryParams: profitsUIContext?.queryParams ?? initialFilter,
    }
  }, [profitsUIContext])
  const sumary = useBillingProfitsSumary(profitsUIProps.queryParams)
  const getBillingProfits = useBillingProfits(profitsUIProps.queryParams)
  const download = useGenerateExel(getBillingProfits)

  return (
    <Card>
      <CardHeader title='Ganancias'>
        <CardHeaderToolbar>
          <button
            type='button'
            className='btn btn-success btn-sm mx-1'
            onClick={() => {
              download()
            }}
          >
            Descargar Excel <Download />
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ProfitsFilter />
        <br />
        <h3>Resumen:</h3>
        <Row>
          <Col md='12'>
            <strong>Cantidad de productos: </strong>
            {thousand?.format(sumary?.quantity ?? 0)}
          </Col>
          <Col md='12'>
            <strong>Total Facturado: </strong>
            {useDeleteZeros(cop?.format(Number(sumary?.price ?? 0)))}
          </Col>
          <Col md='12'>
            <strong>Ganancias Totales: </strong>
            {useDeleteZeros(cop?.format(Number(sumary?.profit ?? 0)))}
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
