import React from "react";
import { Route, useHistory, Switch } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
// import { ProductStocksLoadingDialog } from "./productStocks-loading-dialog/ProductStocksLoadingDialog";
import { ProductStockEditDialog } from "./productStock-edit-dialog/ProductStockEditDialog";
import { ProductStockDeleteDialog } from "./productStock-delete-dialog/ProductStockDeleteDialog";
// import { ProductStocksDeleteDialog } from "./productStocks-delete-dialog/ProductStocksDeleteDialog";
// import { ProductStocksFetchDialog } from "./productStocks-fetch-dialog/ProductStocksFetchDialog";
// import { ProductStocksUpdateStateDialog } from "./productStocks-update-status-dialog/ProductStocksUpdateStateDialog";
import { ProductStocksUIProvider } from "./ProductStocksUIContext";
import { ProductStocksCard } from "./ProductStocksCard";


export const ProductStocksPage = () =>  {
  const history = useHistory();
  const productStocksUIEvents = {
    newProductStockButtonClick: () => {
      history.push("/processes/product-stock/new");
    },
    productStockButtonClick: () => {
      history.push("/processes/product-stock/product-stock")
    },
    openEditProductStockDialog: (id:number) => {
      history.push(`/processes/product-stock/${id}/edit`);
    },
    openDeleteProductStockDialog: (id:number, fullDelete: number) => {
      history.push(`/processes/product-stock/${id}/${fullDelete}/delete`);
    },
  }

  return (
    <ProductStocksUIProvider productStocksUIEvents={productStocksUIEvents}>
      {/* <ProductStocksLoadingDialog /> */}
      <Switch>
        <Route path="/processes/product-stock/product-stock">
          {({history, match}) => (
              <h1>Stock de producto</h1>
          )}
        </Route>
        <Route path="/processes/product-stock/new">
          {({ history, match }) => (
            <ProductStockEditDialog
              show={match !== null}
              onHide={() => {
                history.push("/processes/product-stock");
              }}
            />
          )}
        </Route>
        <Route path="/processes/product-stock/:id/edit">
          {({ history, match }) => {
            return (
              <ProductStockEditDialog
                show={match !== null}
                id={match && (Number(match.params.id) as number)}
                onHide={() => {
                  history.push("/processes/product-stock");
                }}
              />
            )
          }}
        </Route>
        <Route path="/processes/product-stock/:id/:fullDelete/delete">
          {({ history, match }) => (
            <ProductStockDeleteDialog
              show={match !== null}
              id={match && Number(match.params.id)}
              fullDelete={match && Number(match.params.fullDelete)}
              onHide={() => {
                history.push("/processes/product-stock");
              }}
            />
          )}
        </Route>
        <Route exact path="/processes/product-stock">
          <ProductStocksCard />
        </Route>
      </Switch>
    </ProductStocksUIProvider>
  );
}




