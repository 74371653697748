import cryptoJs from 'crypto-js';

export const encryptText = (text: string): string => {
    return cryptoJs.AES.encrypt(text, (process.env.REACT_APP_MY_SECRET_KEY as string)).toString();
}

export const decrypt = (encryptedText: string): any =>{
    const bytes = cryptoJs.AES.decrypt(encryptedText, (process.env.REACT_APP_MY_SECRET_KEY as string));
    return JSON.parse(bytes.toString(cryptoJs.enc.Utf8));
}
