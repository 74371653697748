// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, {useEffect, useState} from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
 Select, Input
} from "../../../../../../_metronic/partials/control";
import { useIntl } from "react-intl";
import { shallowEqual, useSelector } from 'react-redux';
import axios from 'axios';
import { LoanModel } from "../models";
import { UserComplete } from "../../../../auth/models/UserModel";
import { RootState } from '../../../../../../setup/redux/RootReducer';
import { LoansLoadingDialog } from '../loans-loading-dialog/LoansLoadingDialog';
import useLoadForm from "../customs/hooks/useLoadForm";
import { AutoCompleteInput } from '../../../../../../_metronic/partials/control/forms/AutoComplete';
import { ClientModel } from '../../../../admin/pages/client/models/index';
import { FactoryModel } from '../../../../admin/pages/factories/models/index';
import DebtorDetail from "../customs/DebtorDetail";
import Swal from 'sweetalert2';
import useIntlCurrencyFormat from '../../../../../../utils/customHooks/useIntlCurrencyFormat';
import DeleteZerosHelper from '../../../../../../utils/functions/DeleteZerosHelper';
export interface LoanEditFormProps {
  saveLoan: (loan: LoanModel) => void;
  loan?: Partial<LoanModel>
  actionsLoading?: boolean;
  onHide?: () => void;
}
export const LoanEditForm: React.FC<LoanEditFormProps> = ({
  saveLoan,
  loan,
  actionsLoading,
  onHide,
}) => {

  const userComplete: UserComplete = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserComplete
  const [selectedClient, setSelectedClient] = useState<ClientModel | null>(null);
  const [selectedFactory, setSelectedFactory] = useState<FactoryModel | null>(null);
  const [selectedLenderClient, setLenderClient] = useState<ClientModel | null>(null);
  const [selectedLenderFactory, setLenderFactory] = useState<FactoryModel | null>(null);
  const [lenderOption,setLenderOption] = useState<number>(3);
  const [debtorOption,setDebtorOption] = useState<number>(0);

  const {cop} = useIntlCurrencyFormat();

  const {
    clients,
    factories,
  } = useLoadForm()
  const intl = useIntl();



  // Validation schema
  const LoanEditSchema = Yup.object().shape({
    value: Yup.number().required("Por favor ingresa un valor").moreThan(0, "Por favor ingrese un número mayor a 0"),

  });
  useEffect(()=>{
    if(loan?.id){

      if(Number(loan.debtor) === 1){
        const client = clients?.find( c => Number(c.id) ===  Number(loan.debtorId));
        setSelectedClient(client as ClientModel)
      }else {
        const factory = factories?.find( f => Number(f.id) === Number(loan.debtorId) );
        setSelectedFactory(factory as FactoryModel)
      }
    }
  },[clients, factories])

  return (
    <>
      {
        actionsLoading ? <LoansLoadingDialog color={"primary"}/> :
          <Formik
            enableReinitialize={true}
            initialValues={(loan as LoanModel)}
            validationSchema={LoanEditSchema}
            onSubmit={(values) => {
              if(values.debtor === 0) return Swal.fire({
                icon: 'error',
                title: 'Por favor escoja un deudor',
              })
              const vall = {
                ...values,
                clientId: selectedClient?.id,
                factoryId: selectedFactory?.id,
                companyId : userComplete.company.id,
                lenderClient: selectedLenderClient?.id,
                lenderFactory: selectedLenderFactory?.id
              }
              console.log('values.debtor', values.debtor)
              Swal.fire({
                icon: "warning",
                showCloseButton: true,
                showCancelButton: true,
                title:`¿Está seguro que desea prestar ${DeleteZerosHelper(cop.format(values.value))}
                  ${Number(values.debtor) === 1 ?
                      `al cliente: ${selectedClient?.name}`:
                      Number(values.debtor) === 2 ?
                      `a la fábrica: ${selectedFactory?.name}`:
                      `a Alí`}?`,
              }).then((result)=>{
                if(result.value){
                  saveLoan(vall);
                }
              })
            }}
          >
            {({ handleSubmit, values, setFieldValue }) => (
              <>
                <Modal.Body className="cursor-default overlay overlay-block ">
                  {actionsLoading && (
                    <div className="overlay-layer bg-transparent">
                      <div className="spinner spinner-lg spinner-success" />
                    </div>
                  )}

                  <Form className="form form-label-right" onSubmit={handleSubmit}>

                    {/* PRESTAMISTA Y TIPO DE PRESTAMISTA */}
                    <div className="form-group row mb-7">
                        {/* Tipo de prestamista */}
                        {/* <div className={`col-lg-${loan?.id ? ((loan?.lender === 3) ? "12":"6") : (lenderOption === 0 || lenderOption === 3) ? "12":"6"} mt-7 mt-lg-0`}>
                          <Field name="lender" label={"Tipo de prestamista"} onChange={(e)=>{
                            setFieldValue("lender", e.target.value)
                            setLenderOption(Number(e.target.value))
                            setLenderClient(null);
                            setLenderFactory(null);
                          }}
                            disabled={loan?.id ? true:false}
                            component={Select}
                          >
                            <option value={0}>Escoja</option>
                            <option value={1}>Cliente</option>
                            <option value={2}>Fabrica</option>
                            {debtorOption !==3 && <option value={3}>Propietario</option>}
                          </Field>
                        </div> */}
                        {
                          values.lender !== 0 && !loan?.id &&
                            <div className="col-lg-6">
                              {
                                lenderOption === 1 &&
                                <Field
                                  name="lenderId"
                                  component={AutoCompleteInput}
                                  className="mt-5"
                                  label={"Cliente"}
                                  options={clients || []}
                                  onChange={(e:any, value: ClientModel | null)=> {
                                    setLenderClient(value);
                                  }}
                                  getOptionLabel={(option: ClientModel) => `${option.name}`}
                                  getOptionDisabled={(option:ClientModel) =>
                                    option === selectedClient
                                  }
                                />
                              }
                              {
                                lenderOption === 2 &&
                                <Field
                                  name="lenderId"
                                  component={AutoCompleteInput}
                                  className="mt-5"
                                  label={"Fabrica"}
                                  options={factories || []}
                                  onChange={(e:any, value: FactoryModel | null)=> {
                                    setLenderFactory(value);
                                  }}
                                  getOptionLabel={(option: FactoryModel) => `${option.name}`}
                                  getOptionDisabled={(option:ClientModel) =>
                                    option === selectedFactory
                                  }
                                />
                              }
                              {/* {
                                Number(values.lender) === 1 ?
                                  <Field
                                      name="lenderId"
                                      component={AutoCompleteInput}
                                      className="mt-5"
                                      // placeholder={intl.formatMessage({ id: "GENERAL.DOCUMENT"})}
                                      label={"Cliente"}
                                      options={clients || []}
                                      onChange={(e:any, value: ClientModel | null)=> {
                                        setLenderClient(value)
                                      }}
                                      getOptionLabel={(option: ClientModel) => `${option.name}`}
                                  />
                                  :
                                  <Field
                                      name="lenderId"
                                      component={AutoCompleteInput}
                                      className="mt-5"
                                      // placeholder={intl.formatMessage({ id: "GENERAL.DOCUMENT"})}
                                      label={"Fabrica"}
                                      options={factories || []}
                                      onChange={(e:any, value: FactoryModel | null)=> {
                                        setLenderFactory(value);
                                      }}
                                      getOptionLabel={(option: FactoryModel) => `${option.name}`}
                                  />
                              } */}
                            </div>
                        }
                      {
                        (loan?.id && loan?.lender !==3) &&
                        <div className="col-lg-6">
                          <Field
                            type="text"
                            name="lenderName"
                            component={Input}
                            disabled={true}
                            //value={cop.format(totalBilled)}
                            label={"Préstamista"}
                          />
                        </div>
                      }
                    </div>


                    {/* DEUDOR Y TIPO DE DEUDOR */}
                    {lenderOption !== 0 &&
                      <div className="form-group row mb-7">
                      {/* Tipo de deudor */}
                      <div className={`col-lg-${loan?.id ? "12":(debtorOption === 0 || debtorOption === 3 )? "12":"6"} mt-7 mt-lg-0`}>
                        <Field name="debtor" label={"Deudor"} onChange={(e: any)=>{
                          setFieldValue("debtor", e.target.value)
                          setDebtorOption(Number(e.target.value))
                          setSelectedClient(null);
                          setSelectedFactory(null);
                        }}
                          disabled={loan?.id ? true:false}
                          component={Select}
                        >
                          <option value={0}>Escoja</option>
                          <option value={1}>Cliente</option>
                          <option value={2}>Fabrica</option>
                          {lenderOption !== 3 && <option value={3}>Propietario</option>}
                        </Field>
                      </div>

                      {
                        values.debtor !== 0 && !loan?.id &&
                          <div className="col-lg-6">
                            {
                              debtorOption === 1 &&
                              <Field
                                name="debtorId"
                                component={AutoCompleteInput}
                                className="mt-5"
                                // placeholder={intl.formatMessage({ id: "GENERAL.DOCUMENT"})}
                                label={"Cliente"}
                                options={clients || []}
                                onChange={(e:any, value: ClientModel | null)=> {
                                  setSelectedClient(value)
                                }}
                                getOptionLabel={(option: ClientModel) => `${option.name}`}
                                getOptionDisabled={(option:ClientModel) =>
                                  option === selectedLenderClient
                                }
                              />
                            }

                            {
                              debtorOption === 2 &&
                              <Field
                                name="clientId"
                                component={AutoCompleteInput}
                                className="mt-5"
                                // placeholder={intl.formatMessage({ id: "GENERAL.DOCUMENT"})}
                                label={"Fabrica"}
                                options={factories || []}
                                onChange={(e:any, value: FactoryModel | null)=> {
                                  setSelectedFactory(value);
                                }}
                                getOptionLabel={(option: FactoryModel) => `${option.name}`}
                                getOptionDisabled={(option:FactoryModel) =>
                                  option === selectedLenderFactory
                                }
                              />

                            }


                            {/* {
                              Number(values.debtor) === 1 ?
                              <Field
                                  name="debtorId"
                                  component={AutoCompleteInput}
                                  className="mt-5"
                                  // placeholder={intl.formatMessage({ id: "GENERAL.DOCUMENT"})}
                                  label={"Cliente"}
                                  options={clients || []}
                                  onChange={(e:any, value: ClientModel | null)=> {
                                    setSelectedClient(value)
                                  }}
                                  getOptionLabel={(option: ClientModel) => `${option.name}`}
                              /> :
                              <Field
                                  name="clientId"
                                  component={AutoCompleteInput}
                                  className="mt-5"
                                  // placeholder={intl.formatMessage({ id: "GENERAL.DOCUMENT"})}
                                  label={"Fabrica"}
                                  options={factories || []}
                                  onChange={(e:any, value: FactoryModel | null)=> {
                                    setSelectedFactory(value);
                                  }}
                                  getOptionLabel={(option: FactoryModel) => `${option.name}`}
                              />
                            } */}
                          </div>
                      }
                    </div>}

                    {
                      selectedClient?.id && <DebtorDetail
                        loan={loan}
                        debtor={selectedClient}
                        value={values}
                        setFieldValue={setFieldValue}
                      />
                    }
                    {
                      selectedFactory?.id && <DebtorDetail
                        debtor={selectedFactory}
                        loan={loan}
                        value={values}
                        setFieldValue={setFieldValue}
                      />
                    }
                    {
                      (debtorOption === 3 || loan?.debtor === 3) && <DebtorDetail
                        debtor={null}
                        loan={loan}
                        value={values}
                        setFieldValue={setFieldValue}
                      />
                    }
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    onClick={onHide}
                    className="btn btn-light btn-elevate"
                  >
                    {intl.formatMessage({ id: "BUTTON.CANCEL"})}
                  </button>
                  <> </>
                  {
                    !loan?.id &&
                    <button
                      type="submit"
                      onClick={() => handleSubmit()}
                      className="btn btn-primary btn-elevate"
                    >
                      {intl.formatMessage({ id: "BUTTON.SAVE"})}
                    </button>
                  }
                </Modal.Footer>
              </>
            )}
          </Formik>
      }
    </>
  );
}



