import axios from "axios"
import { AxiosRequestConfig } from 'axios';
import * as authRedux from '../app/modules/auth/redux/AuthRedux';
export const getNewAccessToken = async (refreshToken: string, dispatch: any) => {
    const data: string = JSON.stringify({
        "refreshToken": refreshToken,
    });
    const config: AxiosRequestConfig = {
        url: `${process.env.REACT_APP_API_URL}/auth/getAccessToken`,
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
          },
        data
    }
    const result = await axios(config)
    dispatch(authRedux.actions.updateAccessToken(result.data.accessToken));
    return result.data.accessToken;
}