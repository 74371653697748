import React, { useState, useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import {ModalProgressBar} from "../../../../../../_metronic/partials/control";
import { useIntl } from "react-intl";
import { useLoansUIContext, LoansUIContextModel } from '../LoansUIContext';

export interface LoanEditDialogHeaderProps {
  id?: number
}
export const  LoanEditDialogHeader: React.FC<LoanEditDialogHeaderProps> = ({ id }) => {

  const loansUIContext = (useLoansUIContext() as LoansUIContextModel);
  const loansUIProps = useMemo(() => {
    return {
      selectedLoan: loansUIContext.selectedLoan,
      loadLoan: loansUIContext.loadLoan,
    };
  }, [loansUIContext]);
  const intl = useIntl();
  const [title, setTitle] = useState(intl.formatMessage({ id: "LOAN.LOANS"}));
  // Title couting
  useEffect(() => {
    let _title = id ? "" : intl.formatMessage({ id: "LOAN.LOANS"});
    if (!loansUIProps.loadLoan && id) {
      _title = `Editar prestamo ${loansUIProps.selectedLoan?.reference}`;
    } 
    if (loansUIProps.loadLoan) {
      _title = "Cargando "
    }

    setTitle(_title);

    // eslint-disable-next-line
  }, [loansUIProps.loadLoan, loansUIProps.selectedLoan]);

  return (
    <>
      {false && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          <div className="d-flex">{title} { loansUIProps.loadLoan && <div className = "dot-shuttle"/> }</div>
        </Modal.Title>
      </Modal.Header>
    </>
  );
}


