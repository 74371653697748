import {BootstrapTableProps} from 'react-bootstrap-table-next'
import moment from "moment";

export const OrderstatusCssClasses = ['danger', 'success', '', '']
export const OrderstatusTitles = ['Inactivo', 'Activo', '', '']
export const OrderTypeCssClasses = ['success', 'primary', '']
export const OrderTypeTitles = ['Business', 'Individual', '']
export const defaultSorted: BootstrapTableProps['defaultSorted'] = [{dataField: 'orderDate', order: 'desc'}]

export const sizePerPageList = [
  {text: '10', value: 10},
  {text: '50', value: 50},
  {text: '100', value: 100},
  {text: '250', value: 250},
  {text: '500', value: 500},
  {text: '1000', value: 1000},
]

export const initialFilter = {
  filter: {
    nom_area: '',
    tel_area: '',
		start: moment().add(-1,'M').format("YYYY-MM-DD"),
		end: moment().format("YYYY-MM-DD"),
  },
  sortOrder: 'desc', // asc||desc
  sortField: 'orderDate',
  pageNumber: 1,
  pageSize: 50,
}

export const initialValues = {
  data: {
    entities: [],
    totalCount: 0,
    errorMessage: '',
  },
  totalCount: 0,
}

// export const initialFilter = {
//   filter: {
//     lastName: "",
//     firstName: "",
//     email: "",
//     ipAddress: ""
//   },
//   sortOrder: "asc", // asc||desc
//   sortField: "id",
//   pageNumber: 1,
//   pageSize: 10
// };
