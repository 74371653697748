import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/partials/control";
import { LoansFilter } from "./loans-filter/LoansFilter";
import { LoansTable } from "./loans-table/LoansTable";
// import { LoansGrouping } from "./loans-grouping/LoansGrouping";
import { useLoansUIContext, LoansUIContextModel } from './LoansUIContext';
import { useIntl } from "react-intl";
import { PreloadingPage } from "../../../../jsxcomponents/PreloadingPage";

export function LoansCard() {
  const intl = useIntl();
  const loansUIContext = (useLoansUIContext() as LoansUIContextModel);
  const loansUIProps = useMemo(() => {
    return {
      ids: loansUIContext.ids,
      newLoanButtonClick: loansUIContext.newLoanButtonClick,
      isLoading: loansUIContext.isLoading
    };
  }, [loansUIContext]);

  return (
    <Card>
      <PreloadingPage
        isVisible={loansUIProps.isLoading}
      />
      <CardHeader title={intl.formatMessage({id: "LOAN.LOANS"})}>
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={loansUIProps.newLoanButtonClick}
          >
            {intl.formatMessage({id: "LOAN.NEW_LOAN"})}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <LoansFilter />
        {/* {loansUIProps.ids.length > 0 && <LoansGrouping />} */}
        <LoansTable />
      </CardBody>
    </Card>
  );
}



