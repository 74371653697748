// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
// import * as actions from "../../../_redux/productStocks/productStocksActions";
import {
  // getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/helpers";
import * as uiHelpers from "../ProductStocksUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/partials/control";
import { useProductStocksUIContext, ProductStocksUIContextModel } from '../ProductStocksUIContext';
import { useIntl } from "react-intl";
import ReactTooltip from 'react-tooltip';
import useIntlCurrencyFormat from '../../../../../../utils/customHooks/useIntlCurrencyFormat';
import { Col, Row } from "react-bootstrap";
import DeleteZeros from "../../../../../../utils/functions/DeleteZerosHelper";

export function ProductStocksTable() {
  // const { company } = useSelector((state) => state.auth);
  // ProductStocks UI Context
  const productStocksUIContext = (useProductStocksUIContext() as ProductStocksUIContextModel);
  const productStocksUIProps = useMemo(() => {
    return {
      ids: productStocksUIContext.ids,
      setIds: productStocksUIContext.setIds,
      queryParams: productStocksUIContext.queryParams,
      setQueryParams: productStocksUIContext.setQueryParams,
      openEditProductStockDialog: productStocksUIContext.openEditProductStockDialog,
      openDeleteProductStockDialog: productStocksUIContext.openDeleteProductStockDialog,
      tableLoading: productStocksUIContext.tableLoading,
      valuesTable: productStocksUIContext.valuesTable,
    };
  }, [productStocksUIContext]);


  const { totalCount, data:{entities} } = productStocksUIProps.valuesTable;
  const {cop, thousand} = useIntlCurrencyFormat();
  // ProductStocks Redux state
  // Table columns
  const intl = useIntl();

  const columns = [
    {
      dataField: "name",
      text: intl.formatMessage({ id: "PRODUCT-STOCK.NAME"}),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "quantity",
      text: intl.formatMessage({ id: "PURCHASE.SUPPLY_QUANTITY"}),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter:(cellContent: any, row: any, rowIndex: any,)=> thousand.format(Number(row.quantity))
    },
    {
      dataField: "factoryName",
      text: intl.formatMessage({ id: "FACTORY.FACTORY"}),
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    // {
    //   dataField: "action",
    //   // text: intl.formatMessage({ id: "FORMULARIES.ACTIONS"}),
    //   text: intl.formatMessage({ id: "GENERAL.ACTIONS"}),
    //   formatter: columnFormatters.ActionsColumnFormatter,
    //   formatExtraData: {
    //     openEditProductStockDialog: productStocksUIProps.openEditProductStockDialog,
    //     openDeleteProductStockDialog: productStocksUIProps.openDeleteProductStockDialog,
    //     intl,
    //   },
    //   classes: "text-right pr-0",
    //   headerClasses: "text-right pr-3",
    //   style: {
    //     minWidth: "100px",
    //   },
    // },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: [...uiHelpers.sizePerPageList, {text: `${totalCount}`, value: totalCount}],
    sizePerPage: productStocksUIProps.queryParams.pageSize,
    page: productStocksUIProps.queryParams.pageNumber,
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  })

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={productStocksUIProps.tableLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                {...(paginationTableProps)}
                wrapperClasses="table-responsive"
                // bproductStocked={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                data={entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  productStocksUIProps.setQueryParams
                  )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: productStocksUIProps.ids,
                //   setIds: productStocksUIProps.setIds,
                // })}
                
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
      <hr style={{background: '#bcc3d7', marginInline: '-2.25rem', height: '1px', marginBottom: '2.25rem'}} />
      <h3>Sub Totales:</h3>
      <Row>
        <Col md='6'>
          <strong>Cantidad: </strong>
          {entities.reduce((p, c) => p + c.quantity, 0)}
        </Col>
      </Row>
    </>
  );
}



