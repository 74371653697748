import React, { useState } from 'react'
import { useFormik } from 'formik';
import axios from 'axios';
import Swal from 'sweetalert2';

type Props = {
    userId: number,
    twoFactorAuthId: number,
}

const DeleteTFA: React.FC<Props> = ({userId,twoFactorAuthId}) => {
    const [loadingButton, setLoadingButton] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [error, setError] = useState('');
    const formik = useFormik({
        initialValues: { disableTFA: true },
        onSubmit: async (values) => {
            setLoadingButton(true);
            try {
                await axios.delete(`${process.env.REACT_APP_API_URL}/twoFactorAuth/${twoFactorAuthId}`,{
                    data:{
                        userId: userId
                    }
                })
                Swal.fire({
                    icon: 'success',
                    title: 'Segundo Factor de Autenticación deshabilitado correctamente',
                }).then(()=> {
                    window.location.reload();
                })
            } catch (error) {
                setError('Error al deshabilitar segundo factor de autenticación')
                setShowAlert(true);          
            } finally {
                setLoadingButton(false);
            }
        }
    })
    return (
        <div className='p-5'>
            <form onSubmit={formik.handleSubmit}>
                {/* Begin title */}
                <div className='fw-bolder h3 mb-8'>
                    {"Advertencia"}
                </div>
                {/* End title */}
                {/* Begin Alert Error */}
                {
                showAlert && 
                    <div className={`alert alert-danger alert-dismissible fade ${showAlert ? 'show' : 'hide'}`}>
                        <button type="button" className="btn-close" onClick={()=> setShowAlert(false)}></button>
                        <strong>Error: </strong> {error}
                    </div>
                }
                {/* End Alert Error */}
                {/* Begin Body */}
                <div className='fs-16 fw-bold text-gray-600 pe-7 mb-8'>
                    {"Se elminará el segundo factor de autenticación, perdiendo seguridad en el inicio de sesión,"} <strong>{"¿Está seguro que desea elimiar el segundo factor de autenticación?"}</strong>.
                </div>
                {/* End Body */}
                {/* Begin Button footer*/}
                <div className='text-center'>
                <button 
                    type='button'
                    className='btn btn-lg btn-secondary mb-5 mx-2' 
                    disabled={loadingButton}
                    data-bs-dismiss='modal'  
                >
                    Cancelar
                </button>
                <button 
                    type='submit'
                    className='btn btn-lg btn-danger mb-5 mx-2'
                    disabled={loadingButton}
                    >
                    {loadingButton ? 'Loading...' : 'Eliminar'}
                </button>
                </div>
                {/* End Button footer*/}
            </form>
        </div>
    )
}

export default DeleteTFA