import React from 'react'
import { KTSVG } from '../../../../../../_metronic/helpers'

type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>,
}
const ModalSelectConfig: React.FC<Props> = ({
  setStep,
}) => {
  return (
    <div className='p-5'>
        {/* BEGIN TEXT */}
        <div className='fs-12 fw-bold text-gray-600 pe-7 mb-8'>
          In addition to your username and password, you’ll have to enter a code (delivered via app or SMS) to log into your account.
        </div>
        {/* END TEXT */}
        {/* BEGIN BUTTONS CHOICE */}
        <div role="button" className={`notice d-flex bg-light-primary rounded border-primary border border-dashed p-6 mb-8 overflow-auto`}>
            <KTSVG
              path='/media/icons/duotune/coding/cod001.svg'
              className={`svg-icon-2tx svg-icon-primary me-4`}
            />
            <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
              <div className='mb-3 mb-md-0 fw-bold'>
                <h4 className='text-gray-800 fw-bolder'>Authenticator Apps</h4>
                <div className='fs-6 text-gray-600 pe-7'>
                  Get codes from apps like Google Authenticator, Microsoft Authenticator, Authy or 1Password.
                </div>
              </div>
            </div>
        </div>
        {/* END BUTTONS CHOICE*/}
        {/* BEGIN CONTINUE BUTTON */}
        <div className='text-center'>
          <button className='btn btn-lg btn-primary w-100 mb-5' onClick={()=> setStep(2)}>
            Continuar
          </button>
        </div>
        {/* END CONTINUE BUTTON */}
    </div>
  )
}

export default ModalSelectConfig