import React, {createContext, useContext, useState, useCallback, useEffect} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter, initialValues} from "./PurchasesUIHelpers";
import axios from "axios";
import { PurchaseModel } from "./models";
import { UserComplete } from '../../../../../../../base_project_backend/src/api/interfaces/usuarios.interface';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../../../setup/redux/RootReducer';
import useIntlCurrencyFormat from '../../../../../utils/customHooks/useIntlCurrencyFormat';
import DeleteZerosHelper from '../../../../../utils/functions/DeleteZerosHelper';


//Modelos
import { FactoryModel } from '../../../admin/pages/factories/models';
import { ProviderModel } from '../../../admin/pages/providers/models/index';

// * BEGIN INTERFACES/TYPES
interface dataDataResultTablePurchases {
  entities: any[],
  totalCount: number,
  errorMessage: string,
}
export interface resultTablePurchases {
  data: dataDataResultTablePurchases
  totalCount: number,
}
export interface PurchasesUIContextModel {
  queryParams:any,
  setQueryParamsBase: (queryParams:any) => void,
  ids: number[],
  setIds: (ids: number[]) => void,
  setQueryParams: (queryParams:any) => void,
  initPurchase:Partial<PurchaseModel>,
  newPurchaseButtonClick: any,
  openEditPurchaseDialog: any,
  openDeletePurchaseDialog: any,
  openDeletePurchasesDialog: any,
  openFetchPurchasesDialog: any,
  openUpdatePurchasesStatusDialog: any,
  openUpdateStatusIsPaid: any,
  openPurchasePaymentDialog: any,
  valuesTable: resultTablePurchases,
  tableLoading: boolean,
  // setTableLoading: (tableLoading: boolean) => void,
  fetchPurchase: (id: number) => Promise<void>
  loadPurchases: () => Promise<void>
  loadPurchase: boolean,
  selectedPurchase:PurchaseModel | undefined
  setSelectedPurchase:  React.Dispatch<React.SetStateAction<PurchaseModel | undefined>>,
  factories: FactoryModel[],
  providers: ProviderModel[]
  isLoading: boolean;
  setIsLoading: (isLoading:boolean) => void;
  paymentProviders: PaymentProviders[],
  loadPaymentP: boolean,
  setLoadPaymentP: React.Dispatch<React.SetStateAction<boolean>>,
  loadPaymentProviderByPurchaseId: (id:number) => Promise<void>,
}

export interface PaymentProviders {
  id: number;
  value: string;
  description: string;
  purchaseId: string;
  paymentDate: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: any;
  balance?: number;
}

export type PurchasesUIProviderProps = {
  purchasesUIEvents: any,
}
// * END INTERFACES/TYPES
const PurchasesUIContext = createContext<PurchasesUIContextModel | null>(null);

export function usePurchasesUIContext() {
  return useContext(PurchasesUIContext);
}

export const PurchasesUIConsumer = PurchasesUIContext.Consumer;


export const PurchasesUIProvider:React.FC<PurchasesUIProviderProps> =  ({purchasesUIEvents, children}) => {
  const user: UserComplete = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserComplete
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [valuesTable, setValuesTable] = useState<resultTablePurchases>(initialValues);
  const [tableLoading, setTableLoading] = useState(true);
  const [selectedPurchase, setSelectedPurchase] = useState<PurchaseModel>();
  const [loadPurchase, setLoadPurchase] = useState(false);
  // * payments
  const [paymentProviders, setPaymentProviders] = useState<PaymentProviders[]>([]);
  const [loadPaymentP, setLoadPaymentP] = useState<boolean>(false)
  const [ids, setIds] = useState<number[]>([]);
  const {cop} = useIntlCurrencyFormat();

  const [factories,setFactories] = useState<FactoryModel[]>([]);
  const [providers,setProviders] = useState<ProviderModel[]>([]);
  const [isLoading,setIsLoading] = useState(false);


  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {

      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initPurchase: Partial<PurchaseModel> = {
    id: undefined,
    purchaseReference: "",
    provider: 0,
    factory:0,
    name: "",
    supplyTypeId: 0,
    price: 0,
    quantity:0,
    lastPurchasePrice: 0,
    values:"",
		isSelected: false,
  };

  const loadPurchases = async () => {
    setSelectedPurchase(undefined);
    setTableLoading(true);
    try {
      const purchaseResult = await axios.post(`${process.env.REACT_APP_API_URL}/purchase/findAll`,{
        queryParams,
        companyId:user.company.id,
      });
      (purchaseResult.data as unknown as resultTablePurchases).data.entities.map((e:any) => {
        e.subTotal = e.totalPurchase;
        e.totalPurchase = e.totalPurchase  - e.discount;
        e.discount = e.discount;
      })
      setValuesTable(purchaseResult.data);
    } catch (error) {
      console.log('error', error)
    } finally {
      setTableLoading(false);
    }
  }


  const loadProvider = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/provider?status=1`);
      setProviders(response.data);
    } catch (error) {
      console.log('error loading provider', error)
    } finally {}
  }

  const loadFactories = async ()=>{
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/factory`);
      setFactories(response.data);
    } catch (error) {
    } finally {

    }
  }


  useEffect(() => {
    loadPurchases();
    loadProvider();
    loadFactories();
  },[queryParams])


  const loadPaymentProviderByPurchaseId = useCallback(async (id:number) => {
    try {
      setLoadPaymentP(true)
      const result = await axios.get<PaymentProviders[]>(`${process.env.REACT_APP_API_URL}/paymentProvider/findByPurchaseId/${id}`);
      setPaymentProviders(result.data)
      console.log('result.data', result.data);
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoadPaymentP(false)
    }
  }, [])
  const fetchPurchase = useCallback(async (id:number)=> {
    try {
      setLoadPurchase(true)
      const result = await axios.get(`${process.env.REACT_APP_API_URL}/purchase/${id}`);
      setSelectedPurchase({...result.data, quantity: 0, price: 0, lastPurchasePrice: 0, values:""});
    } catch (error) {
      console.log('error loading purchase', error)
    } finally {
      setLoadPurchase(false);
    }
  },[])

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initPurchase,
    newPurchaseButtonClick: ()=>{
      setSelectedPurchase(undefined);
      purchasesUIEvents.newPurchaseButtonClick();
    },
    openEditPurchaseDialog: purchasesUIEvents.openEditPurchaseDialog,
    openDeletePurchaseDialog: purchasesUIEvents.openDeletePurchaseDialog,
    openDeletePurchasesDialog: purchasesUIEvents.openDeletePurchasesDialog,
    openFetchPurchasesDialog: purchasesUIEvents.openFetchPurchasesDialog,
    openUpdatePurchasesStatusDialog: purchasesUIEvents.openUpdatePurchasesStatusDialog,
    openUpdateStatusIsPaid: purchasesUIEvents.openUpdateStatusIsPaid,
    openPurchasePaymentDialog: purchasesUIEvents.openPurchasePaymentDialog,
    valuesTable,
    tableLoading,
    loadPurchases,
    fetchPurchase,
    selectedPurchase,
    setSelectedPurchase,
    loadPurchase,
    factories,
    providers,
    isLoading,
    setIsLoading,
    // * payment
    paymentProviders,
    loadPaymentP,
    setLoadPaymentP,
    loadPaymentProviderByPurchaseId,
  };

  return <PurchasesUIContext.Provider value={value}>{children}</PurchasesUIContext.Provider>;
}


