import React from "react";
import { Route, useHistory } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
// import { BillingsLoadingDialog } from "./billings-loading-dialog/BillingsLoadingDialog";
import { BillingEditDialog } from "./billing-edit-dialog/BillingEditDialog";
import { BillingDeleteDialog } from "./billing-delete-dialog/BillingDeleteDialog";
// import { BillingsDeleteDialog } from "./billings-delete-dialog/BillingsDeleteDialog";
// import { BillingsFetchDialog } from "./billings-fetch-dialog/BillingsFetchDialog";
// import { BillingsUpdateStateDialog } from "./billings-update-status-dialog/BillingsUpdateStateDialog";
import { BillingsUIProvider } from "./BillingsUIContext";
import { BillingsCard } from "./BillingsCard";
import Swal from 'sweetalert2';
import axios from 'axios';
import { BillingRefundDialog } from "./billing-refund-dialog/BillingRefundDialog";


export const BillingsPage = () =>  {
  const history = useHistory();
  const billingsUIEvents = {
    newBillingButtonClick: () => {
      history.push("/processes/billing/new");
    },
    openEditBillingDialog: (id:number) => {
      history.push(`/processes/billing/${id}/edit`);
    },
    openRefundBillingDialog: (id:number) => {
      history.push(`/processes/billing/${id}/refund`);
    },
    openDeleteBillingDialog: (id:number, fullDelete: number) => {
      history.push(`/processes/billing/${id}/${fullDelete}/delete`);
    },
    openUpdateStatusIsPaid: (id:number, isPaid: false, setIsLoading: any, loadBillings:any) => {
      Swal.fire({
        icon: 'warning',
        title: `¿Está seguro que desea Marcar como ${!isPaid ? 'No' : ''} Paga esta factura?`,
        showCancelButton: true
      }).then( async (v) => {
        if(v.isConfirmed){
          try {
            setIsLoading(true);
            await axios.put(`${process.env.REACT_APP_API_URL}/billing/updateStatus/${id}`,{
              isPaid
            })
            await loadBillings()
          } catch (error) {
            console.log('error', error);
          } finally {
            setIsLoading(false);
          }
          // TODO Load update status.
        }
      })
    }
  }

  return (
    <BillingsUIProvider billingsUIEvents={billingsUIEvents}>
      {/* <BillingsLoadingDialog /> */}
      <Route path="/processes/billing/new">
        {({ history, match }) => (
          <BillingEditDialog
            show={match !== null}
            onHide={() => {
              history.push("/processes/billing");
            }}
          />
        )}
      </Route>
      <Route path="/processes/billing/:id/edit">
        {({ history, match }) => {
          return (
            <BillingEditDialog
              show={match !== null}
              id={match && (Number(match.params.id) as number)}
              onHide={() => {
                history.push("/processes/billing");
              }}
            />
          )
        }}
      </Route>
      <Route path="/processes/billing/:id/refund">
        {({ history, match }) => {
          return (
            <BillingRefundDialog
              show={match !== null}
              id={match && (Number(match.params.id) as number)}
              onHide={() => {
                history.push("/processes/billing");
              }}
            />
          )
        }}
      </Route>
      <Route path="/processes/billing/:id/:fullDelete/delete">
        {({ history, match }) => (
          <BillingDeleteDialog
            show={match !== null}
            id={match && Number(match.params.id)}
            fullDelete={match && Number(match.params.fullDelete)}
            onHide={() => {
              history.push("/processes/billing");
            }}
          />
        )}
      </Route>
      <BillingsCard />
    </BillingsUIProvider>
  );
}




