import React from 'react'
import {Input} from 'antd';
import { FieldFeedbackLabel } from './FieldFeedbackLabel';
const {TextArea} = Input;

const getFieldCSSClasses = (touched:any, errors:any) => {
    const classes = ["form-control"];
    if (touched && errors) {
      classes.push("is-invalid");
    }
  
    if (touched && !errors) {
      classes.push("is-valid");
    }
  
    return classes.join(" ");
  };

export interface TextAreaProps {
    field: any
    form: any
    label?: string
    withFeedbackLabel: boolean
    customFeedbackLabel:any
    type: string
    rows:number
}
const TextAreaInput:React.FC<TextAreaProps> = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    label,
    withFeedbackLabel = true,
    customFeedbackLabel,
    type = "text",
    rows = 4,
    ...props
}) => {
  return (
    <>
    {label && <label> <b>{label}</b> </label>}
    <TextArea
      type={type}
      rows={rows}
      className={getFieldCSSClasses(touched[field.name], errors[field.name])}
      {...field}
      {...props}
    />
    {withFeedbackLabel && (
      <FieldFeedbackLabel
        error={errors[field.name]}
        touched={touched[field.name]}
        label={label}
        type={type}
        customFeedbackLabel={customFeedbackLabel}
      />
    )}
  </>
  )
}

export default TextAreaInput