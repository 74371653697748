import React, { useState, useEffect } from 'react';
import { ProductOrderInterfaceForm } from '../../../../admin/pages/products/models';

export interface avaliableSupplies{
    currentQuantity: number;
    factoryId: string;
    key: number;
    purchasedSupp: string;
    supplyId: string;
    supplyName: string;
    unidad: string;
    usedSupp: number;
}

export interface productSupplies{
    productId: number;
    productName: string;
    quantity: number;
    supplyId: string;
    supplyName: string;
    units: string;
    quantityAvaliable?: number;
    remainingQuantity?: number;
    requiredQuantity?: number;

}

export interface SupplyTableProps {
    avaliableSupplies: avaliableSupplies[]
    productId: number;
    products: ProductOrderInterfaceForm[];
    quantityProduct: number;
}




const SupplyTable: React.FC<SupplyTableProps> = ({
    avaliableSupplies,
    productId,
    products,
    quantityProduct
}) => {
    const [suppliesProducts, setSuppliesProduct] = useState<productSupplies[]>([]);

    useEffect(() => {
        const suppliesAux = products.find(p => p.id === productId);
        let aux: productSupplies[] = [];

        if(suppliesAux){
            //@ts-ignore
            suppliesAux.supplies.map(s=> {
                let auxSupply = avaliableSupplies.find(a => Number(a.supplyId) === Number(s.supplyId))
                s.quantityAvaliable = auxSupply ? auxSupply.currentQuantity : 0;
                s.requiredQuantity = quantityProduct*s.quantity
                s.remainingQuantity = s.quantityAvaliable - (s.quantity*quantityProduct)  
                aux.push(s)
                
            })
            setSuppliesProduct(aux)
        }else{
            setSuppliesProduct([])
        }
    },[productId,quantityProduct,avaliableSupplies])
    

    return(
        <>  
            <h1>Lista de insumos</h1>
            <div className='table-responsive'>
                <table className="styled-table-info">
                    <thead>
                        <tr>
                            <th className="text-center">Insumo</th>
                            <th className="text-center">Unidad</th>
                            <th className="text-center">Cantidad promedio</th>
                            <th className="text-center">Cantidad disponible</th>
                            <th className="text-center">Cantidad requerida</th>
                            <th className="text-center">Cantidad restante</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            suppliesProducts.map((item,index) => (
                                <tr key={index}>
                                    <td align='center'>{item.supplyName}</td>
                                    <td align='center'>{item.units}</td>
                                    <td align='center'>{item.quantity}</td>
                                    <td align='center'>{item.quantityAvaliable}</td>
                                    <td align='center'>{item.requiredQuantity}</td>
                                    <td align='center'>{item.remainingQuantity}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default SupplyTable