// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import {Input, Select} from '../../../../../../_metronic/partials/control'
import {useIntl} from 'react-intl'
import {shallowEqual, useSelector} from 'react-redux'
import axios from 'axios'
import {BillingModel} from '../models'
import {UserComplete} from '../../../../auth/models/UserModel'
import {RootState} from '../../../../../../setup/redux/RootReducer'
import {BillingsLoadingDialog} from '../billings-loading-dialog/BillingsLoadingDialog'
import useLoadForm from '../components/hooks/useLoadForm'
import {AutoCompleteInput} from '../../../../../../_metronic/partials/control/forms/AutoComplete'
import {OrderModel} from '../../orders/models/index'
import useIntlCurrencyFormat from '../../../../../../utils/customHooks/useIntlCurrencyFormat'
import DeleteZerosHelper from '../../../../../../utils/functions/DeleteZerosHelper'
import {ClientModel} from '../../../../admin/pages/client/models/index'
import {FactoryModel} from '../../../../admin/pages/factories/models/index'
import Swal from 'sweetalert2'
import {DatePicker} from 'antd'
import moment from 'moment'
import {ProductModel} from '../../../../admin/pages/products/models/index'
import ProductTable from '../components/ProductTable'
import {AvailableProduct} from '../models/index'
import {validateBillingForm, submitValidationForm} from '../components/validationForm'
import lodash from 'lodash'
import {generateSalesOrder} from '../../../../../../utils/PDF/SalesOrder'
import TextAreaInput from '../../../../../../_metronic/partials/control/forms/TextAreaInput'
import ProductRefundTable from '../components/ProductRefundTable'
import useDebounceFnc from '../../../../../../utils/customHooks/useDebounceFnc'
export interface BillingEditFormProps {
  saveBilling: (billing: any) => void
  billing?: Partial<BillingModel>
  actionsLoading?: boolean
  onHide?: () => void
  id?: number
}

interface BillingValueProps {
  value: number
  price: number
  name: string
  quantityBilled: number
  id: number
}

interface Product extends AvailableProduct {
  quantity: string
}

export const BillingRefundForm: React.FC<BillingEditFormProps> = ({
  saveBilling,
  billing,
  actionsLoading,
  onHide,
  id,
}) => {
  const saveBillingDebounced = useDebounceFnc(saveBilling, 1000)
  const {cop} = useIntlCurrencyFormat()
  const intl = useIntl()

  const [products, setProducts] = useState<Product[]>(JSON.parse(billing?.options ?? '[]'))
  const [loadingStock, setLoadingStock] = useState(true);
  const [selectedClient, setSelectedClient] = useState<ClientModel>();

  const {
    clients,
  } = useLoadForm(({tipo, data}) => {
    if (tipo === 'clients') {
      //@ts-ignore
      const client = data.find(c => c.id == billing?.clientId)
      console.log(client)
      setSelectedClient(client)
    }
  })

  // Validation schema
  const BillingEditSchema = Yup.object().shape({})

  const updateProducts = async () => {
    const {data: stock} = await axios.post<
      {
        id: number
        name: string
        quantity: number
      }[]
    >(`${process.env.REACT_APP_API_URL}/product/findAllByIds`, {
      ids: products.map((p) => p.id),
    })
    setProducts((prev) =>
      prev.map((p) => {
        const stockQ = stock.find((s) => s.id === p.id)!.quantity

        return {
          ...p,
          availableQuantity: stockQ + p.requestedQuantity,
          restQuantity: stockQ,
          quantity: `${p.requestedQuantity}`,
        }
      })
    )
    setLoadingStock(false)
  }

  useEffect(() => {
    updateProducts()
    // setProducts((prev) => prev.map((p) => ({...p, quantity: `${p.requestedQuantity}`})))
    return setLoadingStock(true)
  }, [])

  // ? functions handle events

  const setProductProperty = (id: number, property: keyof Product, value?: string | number) => {
    setProducts((prev) => {
      const requestedProduct = prev.find((p) => p.id === id)
      const index = prev.findIndex((p) => p.id === id)
      const newProducts = [...prev]
      let product: Product
      if (requestedProduct === undefined) return prev

      product = {...requestedProduct}

      newProducts[index] = {
        ...product,
        [property]: value,
      }

      return newProducts
    })
  }

  const onRestoreAll = (id: number) => {
    const prods = JSON.parse(billing?.options ?? '[]') as AvailableProduct[]

    const prod = prods.find((p) => p.id === id)

    const reqQ = prod?.requestedQuantity ?? 0

    setProductProperty(id, 'requestedQuantity', reqQ)
    setProductProperty(id, 'quantity', `${reqQ}`)
  }

  const onRemoveAll = (id: number) => {
    setProductProperty(id, 'requestedQuantity', 0)
    setProductProperty(id, 'quantity', 0)
  }

  const onSubmit = async (values: BillingModel) => {
    const mappedOptions = products.map((p) => ({
      id: p.id,
      availableQuantity: p.availableQuantity,
      price: p.price,
      productName: p.productName,
      requestedQuantity: p.requestedQuantity,
      restQuantity: p.availableQuantity - p.requestedQuantity,
      suggestedPrice: p.suggestedPrice,
    }))

    const stockAdjustment = products.map((p) => {
      const oldRest = p.restQuantity
      const newRest = p.availableQuantity - p.requestedQuantity
      return {
        id: p.id,
        amount: newRest - oldRest,
      }
    })

    const totalBilled =
      products.reduce((sum, p) => sum + p.requestedQuantity * p.price, 0) - (values.discount ?? 0)

    const vall = {
      id,
      options: mappedOptions,
      totalBilled,
      discount: values.discount,
      stockAdjustment,
      description: values.description,
      client: selectedClient!.id
    }

    console.log(vall)
    Swal.fire({
      icon: 'warning',
      showCancelButton: true,
      title: `¿Estás seguro de que deseas facturar al cliente ${
        values.clientName
      } por un total de ${DeleteZerosHelper(cop.format(totalBilled))}?`,
    }).then((result) => {
      if (result.value) saveBillingDebounced(vall)
    })
  }
  return (
    <>
      {actionsLoading || loadingStock ? (
        <BillingsLoadingDialog color={'primary'} />
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={billing as BillingModel}
          validationSchema={BillingEditSchema}
          onSubmit={(values) => onSubmit(values)}
        >
          {({handleSubmit, values, setFieldValue}) => (
            <>
              <Modal.Body className='cursor-default overlay overlay-block '>
                {actionsLoading && (
                  <div className='overlay-layer bg-transparent'>
                    <div className='spinner spinner-lg spinner-success' />
                  </div>
                )}
                <Form className='form form-label-right' onSubmit={handleSubmit}>
                  {id && (
                    <>
                      <div className='form-group row mb-7'>
                        <div className='col-lg-4'>
                          <Field name='reference' component={Input} disabled label={'Referencia'} />
                        </div>
                        <div className='col-lg-4'>
                          <Field
                            name='clientId'
                            component={AutoCompleteInput}
                            className='mt-5'
                            value={selectedClient}
                            disableClearable
                            // placeholder={intl.formatMessage({ id: "GENERAL.DOCUMENT"})}
                            label={'Clientes'}
                            options={clients || []}
                            onChange={(e: any, value: ClientModel | null) => {
                              setSelectedClient(value as ClientModel)
                              setFieldValue('clientId', value?.id)
                            }}
                            getOptionLabel={(option: FactoryModel) => `${option.name}`}
                          />
                        </div>
                        <div className='col-lg-4'>
                          <Field name='factoryName' component={Input} disabled label={'Fabrica'} />
                        </div>
                      </div>
                      <div className='row mb-7'>
                        <ProductRefundTable
                          id={billing?.id as number}
                          products={products}
                          setProductProperty={setProductProperty}
                          onRemoveAll={onRemoveAll}
                          onRestoreAll={onRestoreAll}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                      <hr />
                      {/* Descripción del pedido */}
                      <div className='form-group row my-7'>
                        {/* Descripcion */}
                        <div className='col-lg-12'>
                          <Field
                            name='description'
                            component={TextAreaInput}
                            placeholder={intl.formatMessage({id: 'GENERAL.DESCRIPTION'})}
                            label={intl.formatMessage({id: 'GENERAL.DESCRIPTION'})}
                          />
                        </div>
                      </div>
                      <div className='form-group row mb-7'>
                        {/* Descuento */}
                        <div className='col-lg-4 mt-6'>
                          <Field
                            type='text'
                            name='discount'
                            component={Input}
                            // label={"Total facturación"}
                            value={values.discount}
                            onChange={(e: any) => {
                              // setTempValue(Number(e.target.value))
                              setFieldValue('discount', e.target.value)
                            }}
                            label={'Descuento'}
                          />
                        </div>
                        {/* Total a facturar */}
                        <div className='col-lg-4 mt-6'>
                          <Field
                            type='text'
                            name='total'
                            component={Input}
                            disabled
                            // @ts-ignore
                            value={DeleteZerosHelper(
                              cop.format(
                                products.reduce(
                                  (sum, p) => sum + p.requestedQuantity * p.price,
                                  0
                                ) - (values?.discount ?? 0)
                              )
                            )}
                            label={'Total'}
                          />
                        </div>
                        <div className='col-lg-4 mt-6'>
                          <label>
                            <b>{intl.formatMessage({id: 'BILLING.DATE'})}</b>
                          </label>
                          <DatePicker
                            name='billingDate'
                            disabled={true}
                            size={'large'}
                            // @ts-ignore
                            value={moment(billing.billingDate)}
                            placeholder={intl.formatMessage({id: 'GENERAL.CHOOSE_DATE'})}
                            style={{width: '100%'}}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button type='button' onClick={onHide} className='btn btn-light btn-elevate'>
                  {intl.formatMessage({id: 'BUTTON.CANCEL'})}
                </button>
                <button
                  type='submit'
                  onClick={() => handleSubmit()}
                  className='btn btn-primary btn-elevate'
                >
                  {intl.formatMessage({id: 'BUTTON.SAVE'})}
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      )}
    </>
  )
}
