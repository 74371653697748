import React, {useEffect, useState} from 'react'
import { KTSVG } from '../../../../../../_metronic/helpers'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { ITFACode, TwoFactorAuthModel } from '../../settings/SettingsModel';
//@ts-ignore
import {verify} from '2fa-util';
import axios from 'axios';
import Swal from 'sweetalert2';
const initialValues: ITFACode = {
  code: '',
}


type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>,
  qr: string,
  secret: string,
  otpauth: string,
  userId: number
}
const errorValue = 'El codigo de autenticacion es incorrecto'
const ConfirmTFA: React.FC<Props> = ({setStep,qr, secret, userId, otpauth}) => {
  const [loadingButton, setLoadingButton] = useState(false);
  const [error, setError] = useState(errorValue)
  const [showAlert, setShowAlert] = useState(false);
  const codeFormValidationSchema = Yup.object().shape({
    code: Yup.string()
      .min(1, 'Minimum 1 symbols')
      .max(6, 'Maximum 6 symbols')
      .required('Code is required'),
  })
  const formik = useFormik<ITFACode>({
    initialValues,
    validationSchema: codeFormValidationSchema,
    onSubmit: async (values) => {
        setLoadingButton(true);
        const result = await verify(values.code, secret);
        if(!result) {
          setError(errorValue)
          setTimeout(()=> {
            setLoadingButton(false)
            setShowAlert(true);
          },1000)
          return
        };
        setTimeout(async()=>{
          try {
            const twoFactorModel: Partial<TwoFactorAuthModel> = {
              secret,
              qrcode: qr,
              isRead: true,
              userId,
              otpauth,
            }
            await axios.post(`${process.env.REACT_APP_API_URL}/twoFactorAuth`, twoFactorModel);
            Swal.fire({
              icon: 'success',
              title: 'Authentication successful',
            }).then(()=> {
              window.location.reload();
            })  
          } catch (error) {
            console.log(error);
            setError('Error al crear segundo factor de autenticación')
          } finally{
            setLoadingButton(false);
          }
        },1000)
        
    }
  })
  useEffect(()=> {
    if(formik.values.code.length === 6){
      formik.handleSubmit()
    }
  },[formik.values.code])// eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className='p-5'>
      <form onSubmit={formik.handleSubmit}>
        {/* Begin title */}
        <div className='fw-bolder h3 mb-8'>
          Authenticators Apps
        </div>
        {/* End title */}
        {/* Begin Body */}
        <div className='fs-16 fw-bold text-gray-600 pe-7 mb-8'>
          Using an authenticator app like <a rel="noreferrer" target='_blank' href='https://support.google.com/accounts/answer/1066447?hl=en'>Google Authenticator</a>, <a rel="noreferrer" href='https://www.microsoft.com/en-us/security/mobile-authenticator-app' target='_blank'>Microsoft Authenticator</a>, <a rel="noreferrer" href="https://authy.com/downl" target='_blank'>Authy</a> , or <a rel="noreferrer" href='https://support.1password.com/one-time-passwords/' target='_blank'>1Password</a>, scan the QR code. It will generate a 6 digit code for you to enter below.
        </div>
        {/* End Body */}
        {/* Begin Alert Error */}
        {
          showAlert && 
            <div className={`alert alert-danger alert-dismissible fade ${showAlert ? 'show' : 'hide'}`}>
              <button type="button" className="btn-close" onClick={()=> setShowAlert(false)}></button>
              <strong>Error: </strong> {error}
            </div>
        }
        {/* End Alert Error */}
        {/* Begin QR code */}
        <div className='text-center mb-8'>
          <img src={qr} alt="QRCode_tfa" />
        </div>
        {/* End QR code */}
        {/* Begin Warnign */}
        <div className={`notice d-flex bg-light-warning rounded border-warning border border-dashed p-6 mb-8 overflow-auto`}>
              <KTSVG
                path='/media/icons/duotune/coding/cod001.svg'
                className={`svg-icon-2tx svg-icon-warning me-4`}
              />
              <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
                <div className='mb-3 mb-md-0 fw-bold'>
                  <div className='fs-6 text-gray-600 pe-7'>
                  If you having trouble using the QR code, select manual entry on your app, and enter your username and the code:
                  </div>
                  <h4 className='text-gray-800 fw-bolder'>{secret}</h4>
                </div>
              </div>
        </div>
        {/* End Warning */}
        {/* Begin Input code */}
        <div className='mb-8'>
          <div className='fv-row mb-0'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              id='code'
              placeholder='Enter authentication code'
              disabled={loadingButton}
              {...formik.getFieldProps('code')}
            />
            {formik.touched.code && formik.errors.code && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.code}</div>
              </div>
            )}
          </div>
        </div>
        {/* End Input code  */}
        {/* Begin Button footer*/}
        <div className='text-center'>
          <button 
            className='btn btn-lg btn-secondary mb-5 mx-2' 
            disabled={loadingButton}
            onClick={()=> setStep(1)}>
            Cancelar
          </button>
          <button 
            type='submit'
            className='btn btn-lg btn-primary mb-5 mx-2'
            disabled={loadingButton}
            >
            {loadingButton ? 'Loading...' : 'Enviar'}
          </button>
        </div>
        {/* End Button footer*/}
      </form>
    </div>
  )
}

export default ConfirmTFA